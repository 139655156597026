import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(private httpClient: HttpClient) { }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo que retorna los primera solicitudes registrados en el sistema.  
  */
    getRequests(paginator: any, filter: string): Observable<any> {
      return this.httpClient.get<any>(`${SPEECH_URL}request?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&filter=${filter}`).pipe(
        catchError(this.handleError)
      );
    }
  
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo que retorna el primera solicitud seleccionado por ID.  
  */
  showRequest(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}request/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo que crea o actualiza el primera solicitud seleccionado. 
    * @param request Informacion proveniente del primera solicitud. 
  */
  UpdateRequest(request: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}request/${request.id}`, request).pipe(
      catchError(this.handleError)
    );
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo que crea o actualiza el primera solicitud seleccionado. 
    * @param request Informacion proveniente del primera solicitud. 
  */
    newRequest(request: any): Observable<any> {
      return this.httpClient.post<any>(`${SPEECH_URL}request/`, request).pipe(
        catchError(this.handleError)
      );
    }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo que elimina un primera solicitud del sistema. 
    * @param id Id del primera solicitud seleccionado. 
  */
  deleteRequest(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${SPEECH_URL}request/${id}`).pipe(
      catchError(this.handleError)
    );
  }


      /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo consulta el estado de la solicitud
    * @param request Informacion proveniente del primera solicitud. 
  */
      state(id: number): Observable<any> {
        return this.httpClient.get<any>(`${SPEECH_URL}state/${id}`).pipe(
          catchError(this.handleError)
        );
      }

        /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * @param recordingId Id de la grabacion
  */
  getAudioUrl(recordingId: number): string {
    return `${SPEECH_URL}audio/${recordingId}`;
  }

}