import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { DynamicFormComponent } from './dynamic-forms/dynamic-form/dynamic-form.component';
import { FormQuestionsComponent } from './dynamic-forms/form-questions/form-questions.component';
import { DynamicFormV2Component } from './dynamic-forms-v2/dynamic-form/dynamic-form.component';
import { FormQuestionsV2Component } from './dynamic-forms-v2/form-questions/form-questions.component';


import { NumberDirective } from '../core/directives/number.directive';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ReportViewComponent } from './report-view/report-view.component';
import { ModalLandingComponent } from './landing-page/modal-landing/modal-landing.component';
import { CvViewComponent } from './cv-view/cv-view.component';
// import { PipesModule } from '../modules/rrhh/pipes/pipes.module';
import { VicidialVozComponent } from './vicidial-voz/vicidial-voz.component';
import { PipesModule } from './pipes/pipes.module';
import { FilesViewComponent } from './files-view/files-view.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ChatComponent } from './chat/chat.component';
import { VicidialDashboardComponent } from './vicidial-dashboard/vicidial-dashboard.component';
import { SpeedDialFabComponent } from './speed-dial-fab/speed-dial-fab.component';
import { ChronometerComponent } from './chronometer/chronometer.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { BookingReserveComponent } from './booking-reserve/booking-reserve.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { OnBoardingComponent } from './on-boarding/on-boarding.component';
import { UserScheduleComponent } from './landing-page/user-schedule/user-schedule.component';
// import { NgxVerticalTimelineModule } from 'ngx-vertical-timeline';
import { PopoverModule } from "ngx-smart-popover";
import { VideollamadaComponent } from './videollamada/videollamada.component';
import { MeetingsComponent } from './meetings/meetings.component';
import { BasicSnackbarComponent } from './basic-snackbar/basic-snackbar.component';
import { LimitTextPipe } from 'src/app/modules/juridica/pipes/limit-text.pipe';
import { FilterTypesPipe } from '../modules/juridica/pipes/filter-type-function.pipe';
import { ScheduleModalComponent } from './landing-page/schedule-modal/schedule-modal.component';
import { UserScheduleV2Component } from './user-schedule-v2/user-schedule-v2.component';
import { RpaModalComponent } from './rpa-modal/rpa-modal.component';
import { BasicCrudPageComponent } from './basic-crud-page/basic-crud-page.component';
import { ExchangeListsComponent } from './exchange-lists/exchange-lists.component';
import { PrintFormSurveyComponent } from './print-form-survey/print-form-survey.component';
import { DynamicFormV3Component } from './dynamic-forms-v3/dynamic-form/dynamic-form.component';
import { DynamicFormV4Component } from './dynamic-forms-4/dynamic-form/dynamic-form.component';
import { FormQuestionsV4Component } from './dynamic-forms-4/form-questions/form-questions.component';
import { BasicTableComponent } from './basic-table/basic-table.component';
import { ChatSoulModule } from './chat-soul/chat-soul.module';

@NgModule({
  declarations: [
    DynamicFormComponent,
    FormQuestionsComponent,
    NumberDirective,
    LandingPageComponent,
    ReportViewComponent,
    ModalLandingComponent,
    CvViewComponent,
    VicidialVozComponent,
    FilesViewComponent,
    ChatComponent,
    VicidialDashboardComponent,
    SpeedDialFabComponent,
    ChronometerComponent,
    NotificationsComponent,
    BookingReserveComponent,
    OnBoardingComponent,
    UserScheduleComponent,
    VideollamadaComponent,
    MeetingsComponent,
    BasicSnackbarComponent,
    LimitTextPipe,
    FilterTypesPipe,
    ScheduleModalComponent,
    UserScheduleV2Component,
    RpaModalComponent,
    BasicCrudPageComponent,
    DynamicFormV2Component,
    FormQuestionsV2Component,
    ExchangeListsComponent,
    PrintFormSurveyComponent,
    DynamicFormV3Component,
    BasicTableComponent,
    DynamicFormV4Component,
    FormQuestionsV4Component,
    BasicTableComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    PdfViewerModule,
    FullCalendarModule,
    PopoverModule,
    ChatSoulModule
  ],
  exports: [
    DynamicFormComponent,
    FormQuestionsComponent,
    MaterialModule,
    LandingPageComponent,
    ReportViewComponent,
    NumberDirective,
    CvViewComponent,
    VicidialVozComponent,
    FilesViewComponent,
    ChatComponent,
    VicidialDashboardComponent,
    SpeedDialFabComponent,
    BookingReserveComponent,
    ChronometerComponent,
    NotificationsComponent,
    LimitTextPipe,
    FilterTypesPipe,
    BasicCrudPageComponent,
    RpaModalComponent,
    DynamicFormV2Component,
    FormQuestionsV2Component,
    ExchangeListsComponent,
    PrintFormSurveyComponent,
    DynamicFormV3Component,
    BasicTableComponent,
    DynamicFormV4Component,
    FormQuestionsV4Component,
    BasicTableComponent
  ],

  providers: [CurrencyPipe, DecimalPipe],
})

export class SharedModule { }
