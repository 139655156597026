<mat-dialog-content class="padding-mat-dialog-content" style="padding-bottom: 10px;">
    <i class="fi-rr-cross-small" style="float: right; font-size: 32px; color: #353535;" mat-icon-button mat-dialog-close></i>
    <div id="containerViewReport" class="avoid-page-break" #containerViewReport>
        <h3 mat-dialog-title>Resultados de adherencia.</h3>
        <mat-divider class="w-100"></mat-divider>
    
        <!-- Mostrar porcentajes de adherencia y no adherencia -->
        <div class="row col-12 justify-content-center" style="padding-bottom: 20px;">
                <div fxFlex="80%" fxLayout="row" fxLayoutAlign="end end" class="pt-5">
                    <mat-card class="puntos">
                        <p class="puntaje">{{ adherenciaResponse ? adherenciaResponse.adherencePercentage : 'N/A' }}</p>
                        <p class="titulo-puntaje">Adherencia</p>
                    </mat-card>
                    <mat-card class="puntos">
                        <p class="puntaje-no">{{ adherenciaResponse ? adherenciaResponse.notAdherencePercentage : 'N/A' }}</p>
                        <p class="titulo-puntaje-no">No adherencia</p>
                    </mat-card>
                </div>
        </div>

        <!-- Mostrar transcripción -->
        <mat-expansion-panel #transcripcionPanel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p>Transcripción</p>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row" #scrollContainer>
                <div class="col-md-12" *ngFor="let item of transcripcion">
                    <p style="font-size: 14px;">                             
                        <strong>{{ item.speaker }}: </strong>
                        <span [innerHTML]="item.text"></span>
                    </p>
                </div>
            </div>
        </mat-expansion-panel>
    
        <!-- Mostrar análisis de la IA -->
        <mat-expansion-panel #adherencia closed>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p>Análisis de adherencia</p>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col col-md-12" *ngIf="adherenciaIA !== null">
                    <div style="font-size: 14px;">
                        <p> {{ adherenciaIA }}</p>
                    </div>
                </div>
                <div class="col col-md-12" *ngIf="adherenciaIA === null">
                    <div style="font-size: 14px;">
                        <p> Análisis en proceso</p>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</mat-dialog-content>
<div class="col-md-12 pt-0">
    <div class="row py-3 d-flex justify-content-end">
        <div class="col-md-4">
            <button type="button" mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600;" (click)="onClickPrintToEmailPdf()">Descargar informe</button>
        </div>
    </div>
</div>
