<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button type="button">
      <span class="mat-button-wrapper"><i class="fi-rr-cross"></i></span>
      <span matripple class="mat-ripple mat-button-ripple-round"></span>
      <span class="mat-button-focus-overlay"></span>
    </button>
  </div>
</div>

<form [formGroup]="editConnectionForm">
  <div class="col-md-12 p-5">
      <div class="mb-4" style="font-weight: 700; font-size: 28px;">Crear conexión</div>
      <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal"></mat-divider>
      
      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Nombre</mat-label>
          <input matInput placeholder="Nombre" formControlName="name">
          <mat-error *ngIf="editConnectionForm.get('name').hasError('required')">Nombre es requerido</mat-error>
          <mat-error *ngIf="editConnectionForm.get('name').hasError('minlength')">Mínimo 5 caracteres</mat-error>
          <mat-error *ngIf="editConnectionForm.get('name').hasError('maxlength')">Máximo 20 caracteres</mat-error>
        </mat-form-field>
      </div>
      
      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Servidor</mat-label>
          <input matInput placeholder="Servidor" formControlName="server_host">
          <mat-error *ngIf="editConnectionForm.get('server_host').hasError('required')">Servidor es requerido</mat-error>
          <mat-error *ngIf="editConnectionForm.get('server_host').hasError('minlength')">Mínimo 5 caracteres</mat-error>
          <mat-error *ngIf="editConnectionForm.get('server_host').hasError('maxlength')">Máximo 16 caracteres</mat-error>
        </mat-form-field>
      </div>
      
      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Puerto</mat-label>
          <input matInput type="number" placeholder="Puerto" formControlName="port" maxlength="5">
          <mat-error *ngIf="editConnectionForm.get('port').hasError('required')">Puerto es requerido</mat-error>
          <mat-error *ngIf="editConnectionForm.get('port').hasError('pattern')">Debe ser un número</mat-error>
        </mat-form-field>
      </div>

      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Puerto</mat-label>
          <input matInput placeholder="Puerto" formControlName="port" maxlength="5">
          <mat-error *ngIf="editConnectionForm.get('port').hasError('required')">Puerto es requerido</mat-error>
          <mat-error *ngIf="editConnectionForm.get('port').hasError('pattern')">Debe ser tipo numérico</mat-error>
          <mat-error *ngIf="editConnectionForm.get('port').hasError('minLength')">Debe contener mínimo un caracteres</mat-error>
          <mat-error *ngIf="editConnectionForm.get('port').hasError('maxLength')">Debe contener mínimo 5 caracteres</mat-error>
        </mat-form-field>
      </div>
      
      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Base de datos</mat-label>
          <input matInput type="text" placeholder="Base de datos" formControlName="database_name">
          <mat-error *ngIf="editConnectionForm.get('database_name').hasError('required')">Base de datos es requerida</mat-error>
          <mat-error *ngIf="editConnectionForm.get('database_name').hasError('minlength')">Mínimo 5 caracteres</mat-error>
          <mat-error *ngIf="editConnectionForm.get('database_name').hasError('maxlength')">Máximo 50 caracteres</mat-error>
        </mat-form-field>
      </div>

      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Nombre de la vista</mat-label>
          <input matInput type="text" placeholder="Vista" formControlName="view">
          <mat-error *ngIf="editConnectionForm.get('view').hasError('required')">Base de datos es requerida</mat-error>
          <mat-error *ngIf="editConnectionForm.get('view').hasError('minlength')">Mínimo 5 caracteres</mat-error>
          <mat-error *ngIf="editConnectionForm.get('view').hasError('maxlength')">Máximo 50 caracteres</mat-error>
        </mat-form-field>
      </div>

      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Conexión web IP</mat-label>
          <input matInput type="text" placeholder="Conexión web IP" formControlName="web_server">
          <mat-error *ngIf="editConnectionForm.get('web_server').hasError('required')">Usuario es requerido</mat-error>
          <mat-error *ngIf="editConnectionForm.get('web_server').hasError('minlength')">Mínimo 5 caracteres</mat-error>
        </mat-form-field>
      </div>

      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Tipo de la llamada</mat-label>
            <mat-select formControlName="type_call" required>
                <ng-container *ngFor="let type of type_call">
                    <mat-option [value]="type.type_call">{{ type.type_call }}</mat-option>
                </ng-container>
            </mat-select>
            <mat-error *ngIf="editConnectionForm.get('type_call').hasError('required')">Tipo de llamada es requerido</mat-error>
        </mat-form-field>
    </div>
      
      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Usuario</mat-label>
          <input matInput type="text" placeholder="Usuario" formControlName="user">
          <mat-error *ngIf="editConnectionForm.get('user').hasError('required')">Usuario es requerido</mat-error>
          <mat-error *ngIf="editConnectionForm.get('user').hasError('minlength')">Mínimo 5 caracteres</mat-error>
          <mat-error *ngIf="editConnectionForm.get('user').hasError('maxlength')">Máximo 50 caracteres</mat-error>
        </mat-form-field>
      </div>
      
      <div class="mt-4">
        <mat-form-field class="col-4 w-100" appearance="fill">
          <mat-label>Contraseña</mat-label>
          <input appBlockCopyPaste matInput (keypress)="omitSpecialChar($event)" [type]="hide ? 'password' : 'text'" matInput formControlName="password">
          <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
          </a>
          <mat-error *ngIf="editConnectionForm.get('password').hasError('required')">Contraseña es requerida</mat-error>
          <mat-error *ngIf="editConnectionForm.get('password').hasError('minlength')">Mínimo 5 caracteres</mat-error>
          <mat-error *ngIf="editConnectionForm.get('password').hasError('maxlength')">Máximo 50 caracteres</mat-error>
        </mat-form-field>
      </div>
    </div>
      
</form>

<div class="col-md-12 p-5 pt-0">
  <div class="row py-3 d-flex justify-content-end">
    <div class="col-md-3">
      <button (click)="cancelSave()" type="button" mat-raised-button class="w-100" style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
    </div>
    <div class="col-md-3">
      <button (click)="saveServe()" type="button" mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600;" [disabled]="!editConnectionForm.valid">Guardar</button>
    </div>
  </div>
</div>
  