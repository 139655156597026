<i class="fi-rr-cross-small" style="float: right; font-size: 32px; color: #353535;" mat-icon-button mat-dialog-close></i>
<mat-dialog-content>
    <div class="main-page-container">
        <div class="row box-container">
            <h4>Aplicar estrategia</h4>
        </div>

        <div class="row">

            <div class="col-12 col-md-12">
                <!-- Agrega algún control aquí, como un input -->
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Selecciona una opción</mat-label>
                    <mat-select [(ngModel)]="seleccion">
                      <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                        {{ opcion }}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col col-md-12">
                <mat-checkbox>Palabras claves</mat-checkbox>
                <mat-checkbox>Tiempos muertos</mat-checkbox>
                <mat-checkbox>Resumen</mat-checkbox>
            </div>
        </div> 
        <div class="row">
            <div class="col col-md-12" style="text-align: center;">
                <button class="buttons m-3" mat-raised-button color="primary" style="float: right;" (click) ="analyzeCases()"
                    >Aceptar</button>
                <button class="buttons m-3" mat-stroked-button style="float: right;"
                    >Cancelar</button>
            </div>
        </div>
    </div>
</mat-dialog-content>
