<div>

    <mat-sidenav-container class="sidenav-container">

        <mat-sidenav class="mat-sidenav" mode="side" opened [disableClose]="true">
            <app-left-nav scheduleEnd="scheduleEnd" activateMenu="activateMenu"></app-left-nav>

            <div class="arrow" fxLayout="row" fxLayoutAlign="end center">
                <button mat-button color="white" (click)="onSinenavToggle()">
          <span class="fi-rr-angle-left" *ngIf="sideNavState"></span>
          <span class="fi-rr-angle-right" *ngIf="!sideNavState"></span>
        </button>
            </div>

            <mat-nav-list>

                <!-- <mat-slide-toggle style="padding:15px 8px 25px 10px;" fxLayout="column" [(ngModel)]="darkMode" (click)="changeTheme(darkMode)"></mat-slide-toggle>
 
                <a mat-list-item style="padding-left: 5px;" (click)="notificaciones()">
                    <i class="fi-rr-bell"></i>
                    <span [@animateText]="linkText ? 'show' : 'hide'" style="padding-left: 5px;">
                        {{ locale == 'es' ? 'Notificaciones' : 'Notifications' }}
                    </span>
                </a>


                <a mat-list-item [matMenuTriggerFor]="schedule"  style="padding-left: 5px;" >
                    <i class="fi-rr-clock"></i>
                    <span [@animateText]="linkText ? 'show' : 'hide'" style="padding-left:5px;">    {{ locale == 'es' ? 'Gestor de turno' : 'Shift manager' }}
                    </span>
                </a>
-->
                <a mat-list-item [matMenuTriggerFor]="app" style="padding-left: 5px;">
                    <i class="fi-sr-grid"></i>
                    <span [@animateText]="linkText ? 'show' : 'hide'" style="padding-left:5px;">    {{ locale == 'es' ? 'Menú' : 'Menu' }}
                    </span>
                </a>
                <!-- 

                
                <a mat-list-item [matMenuTriggerFor]="lang" style="padding-left: 5px;">
                    <i class="fi fi-rr-world"></i>
                    <span [@animateText]="linkText ? 'show' : 'hide'" style="padding-left:5px;">    {{ locale == 'es' ? 'Español' : 'English' }}
                    </span>
                </a>
-->
                <!-- <a mat-list-item style="padding-left: 5px;">
          <i class="fi-rr-search"></i>
          <span [@animateText]="linkText ? 'show' : 'hide'" style="padding-left:5px;">Buscar</span>
        </a> -->
                <a mat-list-item style="padding-left: 5px;" [routerLink]="'/mios/ciu/perfil'">
                    <i class="fi-rr-user"></i>
                    <span [@animateText]="linkText ? 'show' : 'hide'" style="padding-left:5px;">    {{ locale == 'es' ? 'Perfil' : 'Profile' }}
                    </span>
                </a>
                <!-- 

                
                <a (click)='closeSchedule()' mat-list-item style="padding-left: 5px;" [disabled]="this.validatorTurn">
                    <i  class="fi-rr-time-delete"></i>
                    <span [@animateText]="linkText ? 'show' : 'hide'" style="padding-left:5px;" >   {{ locale == 'es' ? 'Finalizar turno' : 'End shift' }}
                    </span>
                </a>
                -->
                <a (click)='closeSession()'  mat-list-item style="padding-left: 5px;">
                    <i class="fi-rr-sign-out"></i>
                    <span [@animateText]="linkText ? 'show' : 'hide'" style="padding-left:5px;">
                        {{ locale == 'es' ? 'Cerrar sesión' : 'Sign off' }}
                    </span>
                </a>

            </mat-nav-list>

        </mat-sidenav>

        <!-- MENU HORARIOS -->
        <mat-menu yPosition="above" xPosition="before" class="menuSideH" #schedule="matMenu">
            <div *ngIf="scheduleTypesMenu.length === 0 && !loadedSchedule" style="padding: 15px;">Cargando<br>horarios<br>por favor<br>espera...</div>
            <div *ngIf="scheduleTypesMenu.length === 0 && loadedSchedule" style="padding: 15px;">¡No tienes<br>horarios!</div>
            <div [ngClass]="{'scrollH': scheduleTypesMenu.length > 6}">
              <div *ngFor="let item of scheduleTypesMenu">
                <button mat-menu-item (click)="actionSchedule(item)" [disabled]="(item.schedule_type.key !== 'workday' && this.validatorTurn) || finishTurn === true || item.status == 'stopped' || pauseToTypify">
                  <span style="font-size: xx-large" [ngStyle]="{'color':getColor(item.status)}">•</span> <span style="text-transform: capitalize">{{item.schedule_type.name}}</span>
                </button>
              </div>
            </div>
        </mat-menu>

        <!-- MENU DE APLICACIONES -->
        <mat-menu yPosition="above" xPosition="before" class="menuSide" #app="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="modulos">
                {{ locale == 'es' ? 'Módulos' : 'Modules' }}
            </button>
            <button mat-menu-item [matMenuTriggerFor]="aplicaciones">
                {{ locale == 'es' ? 'Aplicaciones' : 'Apps' }}
            </button>
        </mat-menu>

        <mat-menu #modulos="matMenu">
            <div *ngFor="let item of appStorage">
                <button mat-menu-item (click)="idMenuLeft(item.key)" [routerLink]="[item.url]" *ngIf="item.enforce_schedule === 0 && item.show_menu !== 0">
          {{item.name}}
        </button>
            </div>
        </mat-menu>

        <mat-menu #aplicaciones="matMenu">
            <div *ngFor="let item of appStorage">
                <button mat-menu-item (click)="idMenuLeft(item.key)" [routerLink]="[item.url]" *ngIf="item.enforce_schedule === 1 && item.show_menu !== 0">
          {{item.name}}
        </button>
            </div>
        </mat-menu>

        <!-- MENU DE IDIOMA -->
        <mat-menu yPosition="above" xPosition="before" class="menuSide" #lang="matMenu">
            <div >
                <button mat-menu-item (click)="setLocale('es')">
                {{ locale === 'es' ? 'Español' : 'Spanish' }}
                </button>
            </div>
            <div>
                <button mat-menu-item (click)="setLocale('en')">
                {{ locale === 'es' ? 'Inglés' : 'English' }}
                </button>
            </div>
        </mat-menu>
       <!-- MENU DE IDIOMA -->

        <mat-sidenav-content [@onMainContentChange]="onSideNavChange ? 'open': 'close'" class="sidenav-content">
            <div class="main_content">
                <router-outlet></router-outlet>
                <!--

                
                <app-speed-dial-fab></app-speed-dial-fab>
                <app-notifications [historial]="verNotificacion" [alert]="verAlert" (cerrarEmit)="verNotificacion=$event"></app-notifications>
                -->
            </div>

        </mat-sidenav-content>

    </mat-sidenav-container>

</div>
