<i class="fi-rr-cross-small" style="float: right; font-size: 32px; color: #353535;" mat-icon-button mat-dialog-close></i>
<h3 mat-dialog-title>{{ modo === 'crear' ? 'Crear' : modo === 'editar' ? 'Editar' : 'Visualizar' }} estrategia.</h3>

<mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal"></mat-divider>

<mat-dialog-content>

    <form [formGroup]="strategyForm">
        <div class="col-md-12 p-5">
            <div class="row mt-3">
                <div class="col col-md-12">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Nombre de la estrategia</mat-label>
                        <input matInput formControlName="name" placeholder="Nombre de la estrategia" [readonly]="modo === 'visualizar'" [disabled]="modo === 'visualizar'">
                    </mat-form-field>
                </div>
            </div>

            <div class="w-100 mt-4" formArrayName="sections">
                <div class="row mt-2 mb-2" *ngFor="let section of sections.controls; let i = index" [formGroupName]="i">
                    <div class="col col-md-12 col-lg-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Nombre de la sección</mat-label>
                            <input matInput formControlName="section_name" placeholder="Nombre de la sección" required [readonly]="modo === 'visualizar'" [disabled]="modo === 'visualizar'">
                        </mat-form-field>
                    </div>
                    <div class="col col-md-12 col-lg-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <textarea matInput #textArea formControlName="guion" (input)="createChips(textArea.value, i)" placeholder="Contenido" required [readonly]="modo === 'visualizar'" [disabled]="modo === 'visualizar'"></textarea>
                        </mat-form-field>
                    </div>
                    
                    <div class="col col-md-12 col-lg-12">
                        <p style="font-size: 14px;">"Haz clic en las etiquetas que deseas agregar en esta sección."</p>
                        <div class="chip-container">
                            <div *ngFor="let chip of chips[i]" (click)="modo !== 'visualizar' && chipSelection(chip, i)" [ngClass]="{'selected': chip.selected}" class="chip">{{chip.text}}</div>
                        </div>
                    </div>
                
                    <div class="col col-md-12 col-lg-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-chip-list #chipList aria-label="Fruit selection">
                                <mat-chip *ngFor="let word of selectedChips[i]" [selectable]="false" [removable]="modo !== 'visualizar'" (removed)="removeWordSelected(word, i)">
                                    {{word.text}}
                                    <mat-icon matChipRemove *ngIf="modo !== 'visualizar'">cancel</mat-icon>
                                </mat-chip>
                                <input formControlName="etiquetas" placeholder="Etiquetas seleccionadas" [matChipInputFor]="chipList" [matChipInputAddOnBlur]="false" [readonly]="modo === 'visualizar'" [disabled]="modo === 'visualizar'">
                            </mat-chip-list>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 col-lg-12 d-flex justify-content-center" *ngIf="modo !== 'visualizar'">
                        <button mat-raised-button color="warn" type="button" (click)="eliminarSeccion(i)">
                            Eliminar sección <i class="fi-rr-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
        
            <div class="row mt-3 d-flex justify-content-center" *ngIf="modo !== 'visualizar'">
                <div class="col col-lg-4 col-md-4 d-flex justify-content-center">
                    <button mat-raised-button color="primary" type="button" (click)="agregarSeccion()">
                        Nueva sección <i class="fi-rr-add"></i>
                    </button>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>

<div class="col-md-12 p-5 pt-0" *ngIf="modo !== 'visualizar'">
    <div class="row py-3 d-flex justify-content-end">
        <div class="col-md-3">
            <button type="button" mat-raised-button class="w-100" style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;" mat-dialog-close>Cancelar</button>
        </div>
        <div class="col-md-3" *ngIf="modo === 'crear'">
            <button type="button" mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600;" (click)="saveStrategy()">Guardar</button>
        </div>
        <div class="col-md-3" *ngIf="modo === 'editar'">
            <button type="button" mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600;" (click)="updateStrategy()">Guardar</button>
        </div>
    </div>
</div>
