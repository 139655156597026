import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

const CIU_URL = environment.CIU_URL;
const { SPEECH_URL } = environment;
@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(
    private httpClient: HttpClient, 
    private authService: AuthService
  ) { }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Yeison Sepulveda
   * @createdate 2021-02-03
   * Servicio que lista todas las campañas paginadas
  */
  getCampaignsCIU(): Observable<any> {
    return this.httpClient.get<any>(`${CIU_URL}campaigns/?paginate=0`)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
   * @author Yeison Sepulveda
   * @createdate 2021-02-03
   * Servicio que lista todas las campañas
  */
  getCampaignsSelect(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}getCampaignsNomina`)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que retorna los servidores registrados en el sistema.  
  */
  getCampaign(paginator: any, filter: string): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}campaign?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&filter=${filter}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que retorna el servidor seleccionado por ID.  
  */
  showCampaign(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}campaign/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que crea o actualiza el servidor seleccionado. 
    * @param campaign Informacion proveniente del servidor. 
  */
  UpdateCampaign(campaign: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}campaign/${campaign.id}`, campaign).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que crea o actualiza el servidor seleccionado. 
    * @param campaign Informacion proveniente del servidor. 
  */
  CreaCampaign(campaign: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}campaign/`, campaign).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que elimina un servidor del sistema. 
    * @param id Id del servidor seleccionado. 
  */
  deleteCampaign(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${SPEECH_URL}campaign/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-04-23
    * Metodo que lista los usuarios que tienen el rol de speech.
  */
  getUsersToRolSpeech(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}campaigns/getUsers`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-04-23
    * Metodo que lista las campañas registradas en el sistema.
    * @param userId Usuario seleccionado en la lista. 
    * @modifiedBy Yeison Sepulveda
    * @modifyDate 20-05-2024
  */
  getAvailableCampaigns(userIds: number[]): Observable<any> {
    const body = { rrhh_ids: userIds }
    return this.httpClient.post<any>(`${SPEECH_URL}campaigns/availableCampaigns`, body).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-04-23
    * Metodo que lista las campañas asociadas a un usuario seleccionado.
    * @param userId Usuario seleccionado en la lista. 
    * @modifiedBy Yeison Sepulveda
    * @modifyDate 20-05-2024
  */
  getUserAssociatedCampaigns(userIds: number[]): Observable<any> {
    const body = { rrhh_ids: userIds }
    return this.httpClient.post<any>(`${SPEECH_URL}campaigns/associatedCampaigns`, body).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-23
    * Metodo que guarda la asociacion entre campañas y usuarios.
    * @param userId Usuario seleccionado de la lista.
    * @param campaignsId Campañas seleccionadas de la segunda lista. 
  */
  associatedCampaigns(userId: number[], campaignsId: number[]): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}campaigns/saveCampaignsUser`, { campaign_ids: campaignsId, rrhh_ids: userId }).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Desactivar/inhabilitar campaña por id
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
   * @param campaignId Campaña id
  */

  stateCampaign(campaignId: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}campaigns/inactivateCampaigns`, campaignId).pipe(
      catchError(this.handleError)
    );
  }
}
