import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Button, ConfigTable, Paginator } from './type';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-basic-crud-page',
  templateUrl: './basic-crud-page.component.html',
  styleUrls: ['./basic-crud-page.component.css']
})
export class BasicCrudPageComponent implements OnInit {
  keysColumnsTable: string[] = [];
  buttonsOptions: Button[] = [{ class: 'fi-rr-edit', action: 'edit' }];
  @Input() titlePage: string = '';
  @Input() descriptionPage: string = '';
  @Input() textInputFilter: string = '';
  @Input() textButtonPage: string = '';
  @Input() dataTable: any[] = [];
  @Input() configColumnsTable: ConfigTable[] = [];
  @Input() configPaginator: Paginator = null;
  @Input() textSelectFilter: string = '';
  @Input() selectList: any[] = [];
  @Input() showAditionalButtonTable: boolean = false;
  @Input() textOptionalSelectFilter: string = '';
  @Input() optionalSelectList: any[] = [];
  @Output() changeFilter: EventEmitter<string> = new EventEmitter<string>();
  @Output() showDialogFormCreate: EventEmitter<any> = new EventEmitter<any>();
  @Output() showDialogFormUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeState: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatePaginator: EventEmitter<Paginator> = new EventEmitter<Paginator>();
  @Output() changeFilterSelect: EventEmitter<number> = new EventEmitter<number>();
  @Output() sendAditionalInfo: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeFilterOptionalSelect: EventEmitter<number> = new EventEmitter<number>();

  constructor(private location: Location) { }

  ngOnInit(): void {
    this.keysColumnsTable = this.configColumnsTable.map(item => item.key);
    if (this.showAditionalButtonTable) this.buttonsOptions.push({ class: 'fi-rr-layers', action: 'other-action' });
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-15
    * Metodo que redirecciona a la pagina anterior visitada. 
  */
  backToPreviousPage(): void {
    this.location.back();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-17
    * Metodo que envia el valor del filtro al componente padre.
    * @param $event Evento emitido por el campo de texto.   
  */
  onChangeFilter($event: any): void {
    this.changeFilter.emit($event.target.value);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que emite el click del boton para crear un registro.
  */
  onClickButtonAdd(): void {
    this.showDialogFormCreate.emit();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que emite el click del boton para actualizar un registro.
    * @param item Elemento seleccionado de la tabla. 
  */
  onClickButtonUpdate(item: any): void {
    this.showDialogFormUpdate.emit(item);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que emite el cambio sobre la configuracion de la paginacion.
    * @param $event Evento emitido por el paginador. 
  */
  onChangePage($event: PageEvent): void {
    this.updatePaginator.emit($event);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que emite el cambio del filtro sobre el select.
    * @param $event Evento emitido por el select. 
  */
  onChangeSelectFilter($event: MatSelectChange): void {
    this.changeFilterSelect.emit($event.value);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-01
    * Metodo que emite el click del boton adicional de la tabla para el envio de informacion.
    * @param item Item seleccionado en la tabla. 
  */
  onClickAditionalButtonTable(item: any): void {
    this.sendAditionalInfo.emit(item);
  }
  /**
    * @createdate 2023-01-23
    * Metodo que emite el cambio del filtro opcional sobre el select.
    * @param $event Evento emitido por el select. 
  */
  onChangeOptionalSelectFilter($event: MatSelectChange): void {
    this.changeFilterOptionalSelect.emit($event.value);
  }
  /**
    * @createdate 2024-03-06
    * Metodo que recibe el elemento seleccionado de la tabla.
    * @param $event Evento emitido por el componente hijo. 
  */
  onClickButtonTable($event: any): void {
    if ($event.action === 'edit') this.onClickButtonUpdate($event.element);
    if ($event.action === 'other-action') this.onClickAditionalButtonTable($event.element);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-06
    * Metodo que emite el cambio de estado de un registro.
    * @param $event Valor emitido por el componente hijo. 
  */
  onClickChangeState($event: any): void {
    this.changeState.emit({ state: $event.state, item: $event.element });
  }
}