import { AngularEditorConfig } from '@kolkov/angular-editor';

export const EDITOR_CONFIG: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: '5%',
  minHeight: '0',
  maxHeight: '20%',
  minWidth: '0',
  enableToolbar: true,
  showToolbar: true,
  placeholder: 'Ingresa tu cuerpo de correo...',
  defaultParagraphSeparator: '',
  defaultFontName: '',
  defaultFontSize: '',
  sanitize: false,
  fonts: [],
  customClasses: [
    {
      name: 'Arial',
      class: 'font-arial',
    },
    {
      name: 'Time-New-Roman',
      class: 'font-time-new-roman'
    },
    {
      name: 'Verdana',
      class: 'font-verdana'
    },
    {
      name: 'Calibri',
      class: 'font-calibri'
    },
  ],
  toolbarPosition: 'top',
  toolbarHiddenButtons: [['bold', 'italic', 'fontName'], ['fontSize', 'insertVideo', 'insertImage']],
};
