import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { PageEvent } from '@angular/material/paginator';
import { RequestService } from 'src/app/modules/speech-analytics/services/request.service';
import { ViewService } from 'src/app/modules/speech-analytics/services/view.service';
import * as moment from 'moment';
import { AudioPlayerComponent } from '../../../audio-player/audio-player.component';
import { SpeechToTextComponent } from '../../../speech-to-text/speech-to-text.component';
import { Observable } from 'rxjs';
import { FormsRequestService } from 'src/app/modules/speech-analytics/services/forms-request.service';
import { SelectionModel } from '@angular/cdk/collections';
import { StrategyServersService } from 'src/app/modules/speech-analytics/services/strategy.service';
import { StrategyModalComponent } from '../../../strategy/strategy-modal/strategy-modal.component';
import { CampaignService } from 'src/app/modules/speech-analytics/services/campaign.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { AdherenciaModalComponent } from '../../../adherencia-modal/adherencia-modal.component';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.sass']
})
export class ModuleComponent implements OnInit {

  /**
   * formulario
   */
  formFilters:FormGroup;
  isFiltersAdvancedActive:boolean = false;
  
  /**
   * Variables de paginacion
   */
  configPaginator: any = { length: 0, pageSize: 5, pageIndex: 1 };
  configPaginatorRecording:any={ pageSize : 5, length:0,  pageIndex:1 }
  configPaginatorStrategy:any={ pageSize : 5, length:0,  pageIndex:1 }
  pageSizeOptions:number[]= [5, 10, 20, 50, 100];


  /**
   * Selectores de filtros
   */
  formularios: any[] = [];
  seccion: any[] = [];
  fields: any[] = [];
  opciones: any[] = [];
  campaigns_cartera:any[]=[];
  carterasLists:any[] = [];
  listStrategys:any[] = [];
  campaigns_user: any[] = [];

  groups: any[] = [];
  skills: any[] = [];
  tipifications: any[] = [];
  type_call: any[] = [ {type_call:'Inbound'}, {type_call:'Outbound'} ];

  //fecha rango 
  dateFilter = (date: Date | null): boolean => {
    if (!date) {
      return false;
    }
    const today = new Date();
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(today.getMonth() - 2);
    return date >= twoMonthsAgo && date <= today;
  };

  /**
   * Columnas tablas
   */
  displayedColumns:string[] = ['checbox','recording_id', 'start_time', 'user', 'full_name', 'phone_number', 'user_group','time_difference'];
  displayedColumnsRecordings:string[] = ['checbox','recording_id', 'start_time', 'user', 'full_name', 'phone_number', 'user_group','time_difference', 'audio'];
  displayedColumnsStrategy: string[] = ['checbox','recording_id', 'start_time', 'user', 'full_name', 'phone_number', 'user_group', 'time_difference','audio','analisis'];


  /**
   * Cabeceras de tablas
   */
  tHeadersRecordings:any={
    checbox: "", recording_id:"ID Caller",
    start_time:'Fecha grabación', user: "No. documento", 
    full_name: 'Nombre agente', phone_number:'Teléfono', user_group: 'Skill', 
    time_difference: "Tiempo", audio:'Audio', analisis:'Análisis' , adherencia:'Adherencia'
  }
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<any>(); 
  dataSourceRecordings = new MatTableDataSource<any>(); 
  dataSourceStrategy = new MatTableDataSource<any>(); 

  //Variables de control con Array original de datos para tablas que requiren Paginacion estatica
  listrecordingsAnalising:any[] = []
  listRecordingsStrategys:any[] = []


  /**
   * Varibales de seleccions
   */
  selection = new SelectionModel<any>(true, []);
  selectionRecordings = new SelectionModel<any>(true, []);
  selectionStrategy = new SelectionModel<any>(true, []);

  //Tipo de modulo a consultar CRM1-CRM2-Carteras
  selectedCRM: any;
  //Control para seleccion de estrategias
  strategyControl:FormControl = new FormControl(null)

  //
  rrhh_id_user: any;

  isAllComplete: boolean;
  startegyComplete: boolean;

  ngOnInit(): void {
    this.initFormFilters();
    this.filterUserCampaign();
    this.cargarSelects();
  }

  constructor(
      private dialog:MatDialog,
      private requestService: RequestService,
      private viewservice: ViewService,
      private formsRequestService: FormsRequestService,
      private strategyService: StrategyServersService,
      private campaignService: CampaignService,
      private authService: AuthService,
  ) {

  }


  /**
   * Metodo que se encarga de inicializar los controles apra los filtros
   * @author Yeison sepulveda
   * @createdate    14/03/2023
   * @returns void {void} 
   */

  initFormFilters():void{
      this.formFilters = new FormGroup({
        campaign: new FormControl(Validators.required),
        module: new FormControl(null),
        form: new FormControl(null),
        section: new FormControl(null),
        field: new FormControl(null),
        option: new FormControl(null),
        start_date: new FormControl(null),
        end_date: new FormControl(null),
        phone_number: new FormControl(null),
        usergroup: new FormControl(null),
        skill_id: new FormControl(null),
        status: new FormControl(null),
        user: new FormControl(null), 
        type_call: new FormControl(null),
        duration: new FormControl(null) 
      });
  }

  /**
   * Metodo que determinao si los checbox estan seleccionados o no
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Metodo para para seleccionar todos los elementos en la página actual
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
  */
  selectAll(): void {
    this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * Método que determinao si los checbox estan seleccionados o no en la tabal de analisis
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
   */
  isAllSelectedRecordings(){
    const numSelected = this.selectionRecordings.selected.length;
    const numRows = this.dataSourceRecordings.data.length;
    return numSelected === numRows; 
  }

  /**
   * Método para seleccionar todos los elementos en la tabla de analsis
   * @modificado Yeison Sepulveda
   * @author Juan David Guerrero Vargas
   */
  selectAllRecordings(): void {
    this.isAllSelectedRecordings() ?
      this.selectionRecordings.clear() :
      this.dataSourceRecordings.data.forEach(row => {
        if (row.analisis !== 'ERROR' && row.analisis !== 'PENDIENTE') {
          this.selectionRecordings.select(row);
        }
      });
  }

  /**
   * Metodo que se encarga de determinar si ya estan seleccionadas todas las grbaciones apra aplicar estrategias
   * @author Juan David Guerrero Vaegas
   */
  isAllSelectedStrategy(){
    const numSelected = this.selectionStrategy.selected.length;
    const numRows = this.dataSourceStrategy.data.length;
    return numSelected === numRows;
  }

  /**
   * Método para seleccionar todos los elementos en la página actual
   * @author Juan David Guerrero Vargas
   */
  selectAllStrategy(): void {
    this.isAllSelectedStrategy() ?
          this.selectionStrategy.clear() :
          this.dataSourceStrategy.data.forEach(row => this.selectionStrategy.select(row));
  }
  
  /**
   * MEtodo que se encarga de retonar la cantidad de items seleccioandos
   * @author Juan David Guerrero Vargas
   * @returns {number} Cantidad de items seleccionados
   */
  get getTotalItemSelected():number{
    if(this.selection && this.selection?.selected){
      return this.selection?.selected.length
    }
    return  0
  }

  /**
   * Método que se encarga de retonar la cantidad de items seleccioandos en la tabal de Analsis
   * @author Juan David Guerrero Vargas
   * @returns {number} Cantidad de items seleccionados
   */
  get getTotalItemSelectedRecordings():number{
    if(this.selectionRecordings && this.selectionRecordings?.selected){
      return this.selectionRecordings?.selected.length
    }
    return  0
  }

  /**
   * Metodo que se encarga de obtener la cantidad de items que ya fueron analizados
   * @author Juan David Guerrero Vargas
   * @returns {number} number cantidad de items que ya estan analizados
   */
  get getTotalItemsAnalized():number{
    let totalRecordingsAnalized = this.selectionRecordings?.selected.filter( ffll =>  ffll?.analisis !== undefined &&  (ffll?.analisis == 'COMPLETADO' ||  ffll?.analisis == 'ERROR') ).length
    if( totalRecordingsAnalized > 0 ){
      return totalRecordingsAnalized;
    }
    return 0
  }
  
  /**
   * Metdodo que se encarga de obtener la cantidad de registros en la tabla 
   */
  get getTotalDataSource():number{
    return this.dataSource.data.length
  }

  /**
   * Metodo trae las campañas asociadas al usuario
   * @author Yeison sepulveda
   * @createdate    06/05/2023
   * @returns void {void} 
   */

  filterUserCampaign():void{
    const user = this.authService.getUser();
    this.rrhh_id_user = [user.rrhh_id];

    this.campaignService.getUserAssociatedCampaigns(this.rrhh_id_user).subscribe(res => {
      this.campaigns_user = res.data;
    },
    (error)=>{
      this.campaigns_user = []
      console.error('Error al encontrar campaña asociada al usuario', error.error.error.error);
      Swal.fire({
        icon: 'error',
        title: 'Error al encontrar campaña asociada al usuario',
        text: error.message,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        confirmButtonText: 'Aceptar',
      });
    });
  }

  /**
   * Metodo para obtener la data de lso selctores segun el tipo de llamada
   */
  typeCallchage():void{
    if(this.formFilters.get("type_call").value !== null && this.formFilters.get("type_call").value !== "" ){
      this.getOtherSelectors();
    }
    this.resetFilters(['usergroup', 'skill_id', 'status']);
  }


  /**
   * MEtodo que se encarga de resetear los controles indicados
   * @param controls:string[] array de name_controls a resetear e inhbilitar o habilitar
   */
    resetFilters(controls:string[]):void{
      controls.forEach( (control_name:string) => {
          this.formFilters.get(control_name).reset();
      });
    }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-04
    * Método cambiar los estados de los filtros avanzados select carteras
  */

  changeStateFiltersAdvanced(event): void {
    this.evenPrevenDefault(event);
    if (this.isFiltersAdvancedActive) {
      let listFiltersClean = ["usergroup", "status", "user" ];
      listFiltersClean.forEach((nameControl: string) => {
        const control = this.formFilters.get(nameControl);
        if (control) {
          control.setValue(null);
        }
      });
      this.isFiltersAdvancedActive = false;
    } else {
      this.isFiltersAdvancedActive = true;
    }
  }

  /**
   * Metodo que se encarga de valdiar si se debe mostrar o no el selctor de skills select carteras
   */
    get isAviableSkills():boolean{
      let type_call = this.formFilters.get("type_call").value
      if( (type_call !== null && type_call !== "" && type_call == "Inbound") ){
        return true
      }
      return false
    }

  /**
   * Metodo que se encarga de determinar si es posible o no realziar la consulta de de grabaciones
   * @returns {void} void
   */
  get isSerachAviable():boolean{
    const campaign = this.formFilters.get('campaign').value;
    const selectedCRM = this.formFilters.get('module').value;
    if(selectedCRM == 'crm1' ||  selectedCRM =='crm2'){
    let form_id   =  this.formFilters.get('form').value;
    let object_id =  this.formFilters.get('field').value;
    let value     =  this.formFilters.get('option').value;
    if((campaign !== null && campaign !== "") && (form_id !== null && form_id !== "") && ( object_id !== null && object_id !== "" ) && ( value !== null && value !== "" )  ){
      return true
    }

    }else if (selectedCRM == 'carteras' ){
      let type_call = this.formFilters.get('type_call').value;
      let start_date = this.formFilters.get('start_date').value
      let end_date = this.formFilters.get('end_date').value
      if( (campaign !== null && campaign !== "") && (type_call !== null && type_call !== "") && (start_date !== null && start_date !== "")  && (end_date !== null && end_date !== "") ){
        return true;
      }
      return false
    }
    return false;
  }

  /**
   * Metodo que se encarga de obtener el total de recordings que se seleccionaron pra aplciar la estrategia
   * @returns {number} number
   */
  get getTotalRecordingsForStrategy():number{
    if(this.selectionStrategy && this.selectionStrategy.selected.length > 0 ){
      return this.selectionStrategy.selected.length;
    }
    return 0
  }

  /**
   * Metodo que se encarga de determinar la cantidad de itms que ya han sido analisados
   */
  get getTotalcompletedAnalisis():number{
    return this.dataSourceRecordings.data.filter( ffll =>  ffll?.analisis !== undefined &&  ffll?.analisis == 'COMPLETADO' ).length
  }

  /**
   * Metodo que se encarga de redirigir al metodo de aplicar estrategias
   * @param stepperReference:any {any} Referencia del steper para realizar la accion de continuar el paso siguiente
   */
  applyStrategy(stepperReference?:any):void{
    this.deleteColumnAdherencia();
    this.dataSourceStrategy.data = this.selectionRecordings.selected;
    this.configPaginatorStrategy.length = this.selectionRecordings.selected.length
    this.listRecordingsStrategys = this.selectionRecordings.selected
    this.selectionStrategy.clear();
    if(stepperReference !== undefined){
      stepperReference.next();
    }
    this.startegyComplete = false;
  }


  /**
  * @author Yeison Sepulveda
  * @createdate 2024-02-04
  * Metodo que la data segun el modulo de CRM
  */
  cargarSelects(): void {
    ///CONSULTA FORMULARIOS
    this.formFilters.get('module').valueChanges.subscribe((selectedModule) => {
      switch (selectedModule) {
        //en caso de sr CRM1 - CRM2 se ahce uso del mismo endpoint
        case "crm1" : 
        case "crm2" :
          this.getForms(selectedModule);
        break;
        case "carteras":
        /// ----------------------------------------> QUITAR   this.getCampaignsCarteras();
        default:
        break;
      }
      this.resetFormFilters(['form','section','field','option']);
    });
    ///CONSULTA FORMULARIOS

    //CONSULTA SECCIIONS
    this.formFilters.get('form').valueChanges.subscribe((selectedFormId) => {
      if(selectedFormId){
        this.getSections(selectedFormId);
      }
        
      this.resetFormFilters(['section','field','option']);
    });
    //CONSULTA SECCIIONS

    //CONSULTA FIELDS POR SECCION
    this.formFilters.get('section').valueChanges.subscribe((selectedSectionIndex) => {
      const selectedSection = this.seccion[selectedSectionIndex];
      if(selectedSection){
        this.getFields(selectedSection);
      }
      this.resetFormFilters(['field','option']);
    });
    //CONSULTA FIELDS POR SECCION
    //CONSULTA OPTIONS POR FIELD
    this.formFilters.get('field').valueChanges.subscribe((selectedFieldId) => {
      const selectedField = this.fields.find((field: any) => field.id === selectedFieldId);
      if(selectedField){
        this.getOptions(selectedField.options);
      }
      this.resetFormFilters(['option']);
    });
    //CONSULTA OPTIONS POR FIELD
  }

  /**
   * Metodo que se encarga de obtner las cmapañs de carteras
   * @author Juan David Guerrero Vargas
   */
  getCampaignsCarteras():void{
    this.viewservice.getCampaignsCarteras().subscribe(
      (restReponse)=>{
        this.campaigns_cartera = restReponse.data
      }
    );

  }


    /**
   * Metodo que se encarg de obtener los selectrores por tipo de llamada
   * @author juan David Guerrero Vargas
   */
    getOtherSelectors():void{
      const typeCall = this.formFilters.get("type_call").value
      this.viewservice.getGroups().subscribe(res => {
        this.groups = res.data;
      },
      (error)=>{
        this.groups = []
      });
  
      this.viewservice.getSkills(typeCall).subscribe(res => {
        this.skills = res.data;
      },
      (error)=>{
        this.skills = []
      });
  
      this.viewservice.getTipifications().subscribe(res => {
        this.tipifications = res.data;
      },
      (error)=>{
        this.tipifications = []
      });
  
      this.getStrategys();
    }

  /**
   * Metodo que se encarga de rinicioar los filtros segun seleccion
   * @author Juan David Guerrero Vargas
   * @param controls:String[] {string[]} array de string con los nombrtes de los controles a reiniciar
   */
  resetFormFilters(controls:string[]) {
    controls.forEach( 
        (nameControl:string)=>{
          this.formFilters.get(nameControl).reset();
        }
    )

  }

  /**
   * Metodo que se encarga de onbtener los formularios segun el modulo seleccionado
   * @author Juan David Guerrero Vargas
   */
  getForms(selectedModule:string):void{
    const formRequestServiceMethod = selectedModule === 'crm1' ? 'getForm' : 'getFormCrm2';
    this.formsRequestService[formRequestServiceMethod]('formslist', 100, 1).subscribe((rest) => {
      this.formularios = rest.data.map(form => ({ id: form.id, name: form.name_form }));
    });
  }

  /**
   * Metodo que se encarga de obtener las secciones de los formularios
   * @param idFormulario:any {string|number} id del formulario al que se le consultaran las secciones
   * @author Juan David Guerrero Vargas
   */
  getSections(idFormulario:any):void{
    const searchFormMethod = this.formFilters.get('module').value === 'crm1' ? 'getsearchform' : 'getsearchformCrm2';
  
    this.formsRequestService[searchFormMethod]('searchform', idFormulario).subscribe((rest) => {
      const sections = this.formFilters.get('module').value === 'crm1' ? rest.section : rest.sections;
      this.seccion = sections.map((section: any, index: number) => ({ id: index, name: section.name_section, fields: section.fields }));

    });
  }

  /**
   * Metodo que se encarga de obtener los fields de una secccion
   * @param selectedSection:any {any} seccion seleccioanda
   */
  getFields(selectedSection:any):void{
    if(selectedSection?.fields !== undefined && Array.isArray(selectedSection?.fields) == true){
      const viewFields = selectedSection.fields.filter((field: any) => field.type === 'options');
      this.fields = viewFields.map((field: any) => ({ id: field.id, name: field.label, options: field.options }));

    }
  }

  /**
   * Metodo que se encarga de obtener el listado de opciones de un campo del formulario
   * @param option:any {any}
   */ 
  getOptions(options:any):void{
    this.opciones = options
  }

  /**
   * Metodo que se encarga de abirir el modal apra poder abrir el modal de creacion de estrategias
   * @author Juan David Guerrero Vargas
   */
  openModalCreateStrategy():void{
    const dialogRef = this.dialog.open(StrategyModalComponent, {
      width: '50%',
      maxHeight: '95vh',
      data: { modo: 'crear' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.getStrategys();
    });
  }

  /**
   * Metodo que se encarga de obtenre el listado de estrategias
   */
  getStrategys():void{
    this.strategyService.listStrategies().subscribe(res => {
        this.listStrategys = res.data;
    }); 
  }

  /**
   * Metodo accesor que permite determinar si se debe o nomostrar los filtros para formularos
   * @author Juan David Guerrero Varga
   * @returns {boolean} boolean
   */
  get showFilterByForms():boolean{
    const module = this.formFilters.get('module').value
    if( module == "crm1" || module == "crm2"){
      return true
    }
    return false;
  }

  /**
   * Metodo accesor que permite determinar si se debe o nomostrar los filtros para carteras
   * @author Juan David Guerrero Varga
   * @returns {boolean} boolean
   */
  get showFilterByCarteras():boolean{
    const module = this.formFilters.get('module').value
    if( module == "carteras" ){
      return true
    }
    return false
  }

  /**
   * Metodo para el consumo y busqueda por los selectores
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
   */

  search() {
    this.selectedCRM = this.formFilters.get('module').value;
    switch (this.selectedCRM) {
      case "crm1":
      case "crm2":
        if (!this.validateRangeDates()){
          this.deleteColumnAnalytics();
          const filters = {
            campaign_id: this.formFilters.get('campaign').value,
            form_id: this.formFilters.get('form').value.toString(),
            object_id: this.formFilters.get('field').value.toString(),
            value: this.formFilters.get('option').value.toString(),
            start_date: this.formFilters.get('start_date').value,
            end_date: this.formFilters.get('end_date').value
          };
          const $searchObservable:Observable<any> = this.selectedCRM == "crm1" ? this.viewservice.getFilter(this.configPaginator, filters) : this.viewservice.getFilter2(this.configPaginator, filters)
          $searchObservable.subscribe(
            (response) => {

              //calcular tiempo
              response.data.data.forEach((registro: any) => {
                const startTime = new Date(registro.start_time);
                const endTime = new Date(registro.end_time);
                registro.time_difference = this.convertTimeToHHMMSS(startTime, endTime);
              });

              this.dataSource.data = response.data.data;
              this.dataSource.sort = this.sort;
              this.selection.clear();
              this.selection.deselect()
              this.selection = new SelectionModel<any>(true, []);
              this.configPaginator.length = response.data.total;
  
            },
            (error) => {
              console.error('Error en la solicitud:', error.error.error.error);
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.error.error.error,
                showConfirmButton: true,
                confirmButtonColor: '#2CABBC',
                confirmButtonText: 'Aceptar',
              });
            }
          );

        }else {
          Swal.fire({
            icon: 'warning',
            title: '¡Ten encuenta!',
            text: 'Debe estar en un rango de 7 días',
            confirmButtonText: 'Entendido',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#2CABBC',
          });
        }
      break;
      case "carteras":
        if (!this.validateRangeDates()){
          this.deleteColumnAnalytics();
          let phoneNumber = this.formFilters.get('phone_number').value !== null ? this.formFilters.get('phone_number').value : ""
          
          const filters = {
            campaign_id: this.formFilters.get('campaign').value,
            type_call: this.formFilters.get('type_call').value,
            user_group: this.formFilters.get('usergroup').value,
            skill_id: this.formFilters.get('skill_id').value,
            start_time: this.formFilters.get('start_date').value,
            endtime: this.formFilters.get('end_date').value,
            status: this.formFilters.get('status').value,
            user: this.formFilters.get('user').value,
            phone_number: this.formFilters.get('phone_number').value,
            duration: this.formFilters.get('duration').value,
          };

          this.viewservice.searchAllData(this.configPaginator, filters).subscribe(
            (restResponse)=>{

              //calcular tiempo
              restResponse.data.data.forEach((registro: any) => {
                const startTime = new Date(registro.start_time);
                const endTime = new Date(registro.end_time);
                registro.time_difference = this.convertTimeToHHMMSS(startTime, endTime);
              });

              this.dataSource.data = restResponse.data.data;
              this.selection.clear();
              this.selection.deselect()
              this.selection = new SelectionModel<any>(true, []);
              this.dataSource.sort = this.sort;
              this.configPaginator.length = restResponse.data.total;
            },
            (error) => {
              console.error('Error en la solicitud:', error.error.error.error);
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.error.error.error,
                showConfirmButton: true,
                confirmButtonColor: '#2CABBC',
                confirmButtonText: 'Aceptar',
              });
            }
          );
        }else {
          Swal.fire('¡Ten encuenta!', 'Debe estar en un rango de 7 días', 'warning');
        }
      break;
    
      default:
        break;
    }

  }


  /**
 * Método para convertir la diferencia de tiempo entre dos fechas en formato HH:mm:ss.
  * @param startTime La fecha de inicio.
  * @param endTime La fecha de fin.
  * @returns La diferencia de tiempo formateada en HH:mm:ss.
  * @author Yeison Sepulveda
  * @createdate 2024-04-23
  */
  
  convertTimeToHHMMSS(startTime: Date, endTime: Date): string {

    const diferenciaMs = Math.abs(endTime.getTime() - startTime.getTime());
    const tiempoDiferencia = new Date(diferenciaMs);

    //extraer
    const horas = tiempoDiferencia.getUTCHours().toString().padStart(2, '0');
    const minutos = tiempoDiferencia.getUTCMinutes().toString().padStart(2, '0');
    const segundos = tiempoDiferencia.getUTCSeconds().toString().padStart(2, '0');

    return `${horas}:${minutos}:${segundos}`;
  }

  /**
   * Descargar reporte de busqueda excel
   * @author Yeison Sepulveda
   * @createdate 2024-04-04
   */
  downloadReport(type: 'analysis' | 'strategy') {
    let filters: any = {};
  
    if (type === 'analysis') {
      filters = {
        recording_ids: this.dataSourceRecordings.data.filter(record => record?.analisis === 'COMPLETADO').map(record => record['recording_id']),
        type: 1
      };
    } else if (type === 'strategy') {
      filters = {
        recording_ids: this.selectionStrategy.selected.map(recording => recording['recording_id']),
        type: 2
      };
    }
  
    this.viewservice.downloadReport(filters).subscribe(
      (response: any) => {
        this.fileTransition(response, 'reporte.xlsx');
      },
      (error) => {
        console.error('Error en la solicitud:', error.error.error.error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.error.error,
          showConfirmButton: true,
          confirmButtonColor: '#2CABBC',
          confirmButtonText: 'Aceptar',
        });
      }
    );
  }


  	/**
	 * Método para tratar los datos que retorna el servicio y descargar el archivo.
	 * @param data data respondida por el servicio
	 * @param fileName nombre con el que se va a descargar el archivo.
	 * @param file_type Tipo del archivo 
	 * @author Yeison Sepulveda
	 * @createdate 2021-07-27
	 * @updatedate 2022-12-13  
	 */
	fileTransition(data: any, fileName: string, file_type:string = ""): void {
		let blob = new Blob([data], { type: file_type});
		let downloadURL = (file_type) ? window.URL.createObjectURL(blob) : window.URL.createObjectURL(data);
		let link = document.createElement('a');
		link.href = downloadURL;
		link.download = fileName;
		link.click();
	}


/**
 * Metodo para reiniciar tabla eliminando colimna de analisis
 * @author Yeison Sepulveda
 * @createdate 2024-02-04
 */

  deleteColumnAnalytics(): void {
    const indexConfigAudio = this.displayedColumnsRecordings.findIndex(column => column === 'analisis');
    if (indexConfigAudio !== -1) {
      this.displayedColumnsRecordings.splice(indexConfigAudio, 1);
    }
  }

/**
 * Metodo para reiniciar tabla eliminando columna adherencia
 * @author Yeison Sepulveda
 * @createdate 2024-02-04
 */

  deleteColumnAdherencia(): void {
    const indexConfigAudio = this.displayedColumnsStrategy.findIndex(column => column === 'adherencia');
    if (indexConfigAudio !== -1) {
      this.displayedColumnsStrategy.splice(indexConfigAudio, 1);
    }
  }

  /**
  * @author Yeison Sepulveda
  * @createdate 2024-02-04
  * Método descargar audios seleccionados
  */
  descargarAudios(stepperReference:any): void {
    if (this.getTotalItemSelected <= 0) {
        Swal.fire({
            icon: 'warning',
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para descargar.'
        });
        return;
    }
    this.buscarConDescarga(true);
    stepperReference.next()
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-04
    * Método consumo consulta y descarga de los audios
  */
  buscarConDescarga(descargarAudios: boolean): void {
    let campaign_id = this.formFilters.get("campaign").value; 
    this.viewservice.searchFill(this.configPaginatorRecording, this.selection.selected.map( record => record['recording_id'] )  , descargarAudios, campaign_id).subscribe(
        (response) => {

          //calcular tiempo
          response.data.data.forEach((registro: any) => {
            const startTime = new Date(registro.start_time);
            const endTime = new Date(registro.end_time);
            registro.time_difference = this.convertTimeToHHMMSS(startTime, endTime);
          });

          this.selectionRecordings.clear();
          this.dataSourceRecordings.data = response.data.data;
          this.listrecordingsAnalising = this.dataSourceRecordings.data
          this.dataSourceRecordings.sort = this.sort;
          this.configPaginatorRecording.length = response.data.total;
        },
        (error) => {
            if (error.error && error.error.error === "No se realizó ninguna descarga ni registro debido a la existencia previa de registros.") {
                console.warn("Error específico omitido:", error);
                
            } else {
              this.dataSourceRecordings.data = [];
              this.configPaginatorRecording.length = 0
              console.error('Error en la solicitud:', error.error.error.error);
            }
        },
    );
  }


  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Metodo para analizar
  */
  analytics(): void {
    if (this.getTotalItemSelectedRecordings === 0) {
      Swal.fire({
            icon: 'warning',
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para analizar.'
        });
        return;
    }
    Swal.fire({
      title: '¿Estás seguro?',
      text: `Se iniciará el análisis de ${this.getTotalItemSelectedRecordings} grabaciones.`,
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.requestService.newRequest(this.selectionRecordings.selected).subscribe(response => {
          
          this.dataSourceRecordings.data =  this.selectionRecordings.selected;
          this.dataSourceRecordings.sort =  this.sort;
          this.configPaginatorRecording.length = this.dataSourceRecordings.data.length;
          if(this.displayedColumnsRecordings.find( ffnn => ffnn == 'analisis' ) == undefined ){
            this.displayedColumnsRecordings.push('analisis')
          }
          this.obtenerEstadosRegistros();
        }, error => {
          console.error('Error al enviar la solicitud:', error.error);
        });
      }
    });
  }

  /**
   * Metodo que limita las acciones de botones por defecto
   */
  evenPrevenDefault($event){
    $event.preventDefault();
  }


  /**
  * Metodo que valida el rango de fechas seleccionadas del formulario.
  * @returns Booleano con la validacion de las fechas.
  */
  validateRangeDates(): boolean {
    const { start_date, end_date } = this.formFilters.value;
    const momentStart = moment(start_date);
    const momentEnd = moment(end_date);
    const numberDays = momentEnd.diff(momentStart, 'days');
    return numberDays >= 7 ? true : false;
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Método que pagina la informacion de la tabla.
    * @param $event Evento emitido por el paginador.
  */
  changePage($event: PageEvent, tablePaginate:string): void {
    if( tablePaginate == 'recordings' ){
      this.configPaginator.pageSize  = $event.pageSize;
      this.configPaginator.pageIndex  = $event.pageIndex + 1;
      this.search();
    }else if (tablePaginate == 'analisis' && this.displayedColumnsRecordings.find(ffnn => ffnn == 'analisis') == undefined){
      this.configPaginatorRecording.pageSize  = $event.pageSize;
      this.configPaginatorRecording.pageIndex  = $event.pageIndex + 1;
      this.buscarConDescarga(false)
    }else if (tablePaginate == 'analisis' && this.displayedColumnsRecordings.find(ffnn => ffnn == 'analisis') !== undefined){
      this.configPaginatorRecording.pageSize  = $event.pageSize;
      this.configPaginatorRecording.pageIndex  = $event.pageIndex + 1;
      this.dataSourceRecordings.data = this.paginateStaticTable(this.listrecordingsAnalising, this.configPaginatorRecording )
    }else if (tablePaginate == 'strategy' && this.displayedColumnsRecordings.find(ffnn => ffnn == 'analisis') !== undefined){
      this.configPaginatorStrategy.pageSize  = $event.pageSize;
      this.configPaginatorStrategy.pageIndex  = $event.pageIndex + 1;
      this.dataSourceStrategy.data = this.paginateStaticTable(this.listRecordingsStrategys, this.configPaginatorStrategy )

    }
  }

  /**
   * Metodo que se encarg de realziar paginacion estatica para tablas 
   * @author Juan David Guerrero Vargas
   * @param dataTable:any[] {any[]} Listado original de datos a paginar
   * @param configPaginator:any {any} Configuracion de paginacion a aplicar de manera estatica
   * @returns {any[]} any[]
   */
  paginateStaticTable(dataTable:any[], configPaginator:any):any[]{
    let  startIndex = (configPaginator.pageIndex - 1 )  * configPaginator.pageSize
    return dataTable.slice( startIndex, startIndex +  configPaginator.pageSize  )
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Metodo para abrir el modal de analisis
  */
  openAnalyticsModal(recording_id: any , origen: any): void {
    const campaign = this.formFilters.get('campaign').value;
    const dialogRef = this.dialog.open(SpeechToTextComponent, {
      width: '800px',
      data: { recordingId: recording_id, campaignVicidial_id: campaign } 
    });
  }

  
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Metodo para abrir el modal reproductor audio
  */
  openAudioModal(recording_id: any): void {
    const dialogRef = this.dialog.open(AudioPlayerComponent, {
      width: '800px',
      data: { recordingId: recording_id } 
    });
  }

  /**
    * @author Yeison Sepulveda
    * @update Juan DAvid Guerrero  Vargas
    * @createdate 2024-03-07
    * Validar el estado de los análisi
  */
  async obtenerEstadosRegistros() {
    let registrosPendientes: any[] = this.dataSourceRecordings.data.filter(ffll => ffll?.analisis !== 'COMPLETADO' && ffll?.analisis !== 'ERROR');
    const selectedRecords = this.selectionRecordings.selected;
    this.selectionRecordings.clear();
  
    while (registrosPendientes.length > 0) {
        const response: any = await this.viewservice.estado(selectedRecords).toPromise();
  
        if (response.data) {
            response.data.forEach((item: any) => {
                const registros = this.dataSourceRecordings.data.filter(record => record.recording_id === item.recording_id);
                registros.forEach(registro => {
                    registro.analisis = item.status.toUpperCase(); 
                });
            });
        }
        this.isAllComplete = false;
  
        this.dataSourceRecordings.data = this.dataSourceRecordings.data.map(record => {
            const registroModificado = registrosPendientes.find(registro => registro.recording_id === record.recording_id);
            return registroModificado ? registroModificado : record;
        });
  
        registrosPendientes = this.dataSourceRecordings.data.filter(ffll => ffll?.analisis !== 'COMPLETADO' && ffll?.analisis !== 'ERROR');
  
        await new Promise(resolve => setTimeout(resolve, 30000)); // Esperar 30 segundos antes de la siguiente iteración
    }
    this.isAllComplete = true;
  }

  /**
	 * Método enviar mensaje swal de error de la grabación
	 * @author Yeison Sepulveda
	 * @createdate 2024-04-23
	 */

  errorAnalyticModal() {
    Swal.fire({
      icon: 'warning',
      title: '¡Aviso!',
      text: 'Si la transcripción no está disponible, por favor verifica el audio, podría carecer de voz o ser solo tonos. ¡Gracias por tu atención! ',
      confirmButtonText: 'Entendido',
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
    });
  }

  /**
   * Metodo que se encarga de enviar la informacion apra aplciar la estrategia seleccionada
   * @author Juan David Guerrero Vargas
   * @Update Yeison Sepulveda
   */

  applyStrategySelected(): void {
    this.deleteColumnAdherencia();
    if (this.getTotalRecordingsForStrategy <= 0) {
        Swal.fire({
            icon: 'warning',
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para aplicar la estrategia.'
        });
        return;
    }

    let strategyForms = {
      id_recordings: this.selectionStrategy.selected.map(recording => recording['recording_id']),
      id_strategy: this.strategyControl.value
    }
  
    this.strategyService.assignStrategys(strategyForms).subscribe(
      (assignResponse: any) => {
          console.log("Respuesta de asignación de estrategia:", assignResponse);
  
          this.strategyService.applyAdherencia(strategyForms.id_recordings).subscribe(
            (adherenciaResponse: any[]) => {
  
                if (this.displayedColumnsStrategy.indexOf('adherencia') === -1) {
                    this.displayedColumnsStrategy.push('adherencia');
                }
  
  
                adherenciaResponse.forEach((item) => {
                  const adherencia = `${item.adherence_percentage} / ${item.not_adherence_percentage}`;
                  const item_recording_id= Number(item.recording_id)
                  const matchingRecords = this.dataSourceStrategy.data.filter((record) => record.recording_id === item_recording_id);
                  
                  // Si hay registros coincidentes, asigna la adherencia a dataSourceStrategy.data
                  if (matchingRecords.length > 0) {
                    this.dataSourceStrategy.data.forEach((record) => {
                      if (record.recording_id === item_recording_id) {
                        record.adherencia = adherencia;
                      }
                    });
                  }
                });
  
                this.dataSourceStrategy.data = [...this.dataSourceStrategy.data];
                this.configPaginatorStrategy.length = this.dataSourceStrategy.data.length;
                this.startegyComplete = true;
  
              },
              (adherenciaError: any) => {
                  console.error("Error al calcular la adherencia:", adherenciaError.error);
              }
          );
      },
      (assignError: any) => {
          console.error("Error al asignar la estrategia:", assignError);
      }
  );
        
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-30
    * Método para abrir el modal de resultados de adherencia
  */
  AdherenciaModal(recording_id: any): void {
    const dialogRef = this.dialog.open(AdherenciaModalComponent, {
      width: '800px',
      data: { recordingId: recording_id } 
    });
  }

  /**
   * Método que formatea la entrada en un campo de entrada para que tenga el formato "HH:MM".
   * @author Yeison Sepulveda
   * @createdate 2024-05-23
   * @param event El evento que desencadena la función, generalmente un evento de cambio en un campo de entrada.
   * @returns No devuelve ningún valor.
  */

  onInputChange(event: any) {
    const input = event.target;
    let inputValue = input.value.replace(/\D/g, ''); 

    if (inputValue.length > 4) {
      inputValue = inputValue.slice(0, 4);
    }

    if (inputValue.length >= 2) {
      inputValue = inputValue.slice(0, 2) + ':' + inputValue.slice(2);
    }

    input.value = inputValue;
  }

  /**
   * Método que formatea la entrada de tiempo en un campo de entrada.
   * @author Yeison Sepulveda
   * @createdate 2024-05-23
   * @param event El evento que desencadena la función, generalmente un evento de cambio en un campo de entrada.
   * @returns No devuelve ningún valor.
   */
  
  formatTime(event: any) {
    const input = event.target;
    let inputValue = input.value.replace(/[^\d+]/g, '');

    inputValue = inputValue.padStart(4, '0');

    let hours = inputValue.slice(0, 2);
    let minutes = inputValue.slice(2, 4);

    hours = this.padZero(Math.min(Math.max(parseInt(hours, 10), 0), 23));

    minutes = this.padZero(Math.min(Math.max(parseInt(minutes, 10), 0), 59));

    inputValue = `${hours}:${minutes}`;

    if (input.value === '' || input.value === '00:00') {
      inputValue = "00:01";
    }

    this.formFilters.get('duration')?.setValue(inputValue);
}

  /**
   * Método que agrega un cero inicial a un número si es menor que 10.
   * @author Yeison Sepulveda
   * @createdate 2024-05-23
   * @param num El número al que se le agregará el cero inicial si es necesario.
   * @returns El número formateado como una cadena de texto.
  */

  padZero(num: any): string {
      return num < 10 ? '0' + num : num.toString();
  }


}
