import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSelectChange } from '@angular/material/select';
import { CampaignService } from '../../../services/campaign.service';

@Component({
  selector: 'app-assign-campaign',
  templateUrl: './assign-campaign.component.html',
  styleUrls: ['./assign-campaign.component.sass']
})
export class AssignCampaignComponent implements OnInit {
  userSelected: number[] = [];
  usersToRolSpeech: any[] = [];
  baseFirstList: any[] = [];
  baseSecondList: any[] = [];
  firstList: any[] = [];
  secondList: any[] = [];
  showExchangeLists: boolean = false;

  constructor(
    private alertService: AlertsService,
    private campaignService: CampaignService
  ) { }

  ngOnInit(): void {
    this.getUsersToRolSpeech();
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-04-23
    * Metodo que filtra las listas a traves de una cadena de texto.
    * @param $event Evento emitido por el componente hijo.  
  */
  runFilterList($event: any): void {
    if ($event.name === 'FIRST') {
      if ($event.value !== '') this.firstList = this.baseFirstList.filter(item => item.name.toLowerCase().includes($event.value.toLowerCase()));
      else this.firstList = this.baseFirstList;
    } else {
      if ($event.value !== '') this.secondList = this.baseSecondList.filter(item => item.name.toLowerCase().includes($event.value.toLowerCase()));
      else this.secondList = this.baseSecondList;
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-04-23
    * Metodo que trae las configuraciones de las listas del componente hijo.
    * @param $event Evento emitido por el componente hijo.  
  */
  saveConfig($event: any): void {
    const dataAssignTrays = {
      id_rrhh: this.userSelected,
      data: $event.secondList.map((item: any) => item.id),
    };
    this.alertService.alertWarning('¿Está seguro?', '¿De asociar las campañas al usuario seleccionado?').then(isConfirm => {
      if (isConfirm.isConfirmed) {
        this.campaignService.associatedCampaigns(dataAssignTrays.id_rrhh, dataAssignTrays.data).subscribe(res => {
          this.alertService.alertSuccess('¡Excelente!', res.data.message);
          this.cancelProcess();
        });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-04-23
    * Metodo que emite la cancelacion del proceso. 
  */
  cancelProcess(): void {
    this.userSelected = [];
    this.showExchangeLists= false;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-04-23
    * Metodo que setea los items entre las listas. 
    * @param $event Evento emitido por el componente hijo.   
  */
  updateList($event: any): void {
    if ($event.name === 'FIRST') {
      $event.list.forEach((itemList: any) => {
        this.secondList.push(itemList);
        this.firstList = this.firstList.filter(item => itemList.id !== item.id);
        this.baseFirstList = this.firstList;
      });
    } else {
      $event.list.forEach((itemList: any) => {
        this.firstList.push(itemList);
        this.secondList = this.secondList.filter(item => itemList.id !== item.id);
        this.baseSecondList = this.secondList;
      });
    }
  }

  /**
   * Treae la informacion de los usuarios selccionados
   * @author Yeison Sepulveda 
   * @createdate 2024-05-22
  */

  getInfoUserSelection(): void {
    this.showExchangeLists= true;
    this.campaignService.getUserAssociatedCampaigns(this.userSelected).subscribe(resByUser => {
      this.baseSecondList = resByUser.data;
      this.secondList = this.baseSecondList;
      this.campaignService.getAvailableCampaigns(this.userSelected).subscribe(resAllCampaigns => {
        this.baseFirstList = resAllCampaigns.data;
        this.baseSecondList.forEach(itemSecondList => {
          this.baseFirstList = this.baseFirstList.filter(itemFirstList => itemSecondList.id !== itemFirstList.id);
        });
        this.firstList = this.baseFirstList;
      });
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-04-23
    * Metodo que lista los usuarios que tienen el rol de speech.
  */
  getUsersToRolSpeech(): void {
    this.campaignService.getUsersToRolSpeech().subscribe(res => {
      this.usersToRolSpeech = res.data;
    });
  }
}