import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailAdminService } from 'src/app/modules/email-manager/services/email-admin.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AdminServersService } from 'src/app/modules/speech-analytics/services/admin-servers.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.sass']
})
export class ConnectionsComponent implements OnInit {

  addConnectionForm: FormGroup;
  dataSource: any; 
  hide: boolean = true;
  type_call: any[] = [ {type_call:'Inbound'}, {type_call:'Outbound'} ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<ConnectionsComponent>,
    private form: FormBuilder,
    private alertService: AlertsService,
    private adminServersService: AdminServersService
  ) { }

      /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Formulario
  */

  formControl(): void {
    this.addConnectionForm = this.form.group({
      name: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]),
      server_host: new FormControl('', [Validators.required,Validators.minLength(5), Validators.maxLength(16)]),
      port: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]+$/),Validators.minLength(1), Validators.maxLength(5)]),
      type_connection: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]),
      database_name: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
      view: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
      user: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
      password: new FormControl('', [Validators.required,Validators.minLength(5), Validators.maxLength(50)]),
      web_server: new FormControl('', [Validators.required,Validators.minLength(1)]),
      type_call: new FormControl('', [Validators.required,Validators.minLength(1)]),
    });
  }

  ngOnInit(): void {
    this.formControl();
  }


        /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que evalua en tiempo real los caracteres ingresados en el input, no deja ingresar espacios
   * @param event evento del keypress del input usuario
   */
        omitSpecialChar(event): any {
          let k;
          k = event.charCode;
          return ((k > 32 && k < 126));
        }


    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Metodo que guarda o actualiza un servidor. 
  */
    saveServe() {
      if (this.addConnectionForm.valid) {
        Swal.fire({
          title: '¿Está seguro?',
          text: '¿De continuar con la gestión?',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: '#2CABBC',
          cancelButtonColor: '#FFFFFF',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Aceptar',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.adminServersService.CreaServer(this.addConnectionForm.value).subscribe(
              res => {
                Swal.fire({
                  title: '¡Exelente!',
                  text: 'Se ha creado una nueva conexión',
                  icon: 'success',
                  confirmButtonColor: '#2CABBC',
                  confirmButtonText: 'Continuar'
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.dialogRef.close();
                  }
                });
              },
              error => {
                Swal.fire('Error', 'Ha ocurrido un error al crear el servidor.', 'error');
              }
            );
          }
        });
      } else {
        this.addConnectionForm.markAllAsTouched();
      }
    }
      
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Metodo que reinicia los valores del modal. 
  */
  cancelSave(): void {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¿En verdad deseas cancelar la gestión?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.addConnectionForm.reset();
        this.dialogRef.close();
      }
    });
  }
}
