import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { CampaignService } from '../../../../services/campaign.service';
import { UsersService } from '../../../../services/users.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { AdminServersService } from 'src/app/modules/speech-analytics/services/admin-servers.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ViewService } from 'src/app/modules/speech-analytics/services/view.service';

@Component({
  selector: 'app-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.sass']
})
export class EditCampaignComponent implements OnInit {


  EditCampaignForm: FormGroup;
  filteredOptionsCampaign: Observable<any[]>;
  campaigns: any;
  dataSource: any;
  dataConnection: any;
  connections: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditCampaignComponent>,
    public userService: UsersService,
    private form: FormBuilder,
    private adminServersService: AdminServersService,
    private campaignService: CampaignService,
    private viewservice: ViewService,   
  ) { }

  ngOnInit(): void {
    this.formControl();
    this.getCampaign();
    this.viewServer(this.data);
    this.getConnections();
  }

  /**
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * Metodo donde se establecen las validaciones del formulario
   */
  
  formControl(): void {
    this.EditCampaignForm = this.form.group({
      id: ['', Validators.required],
      campaign_name: new FormControl ('', Validators.required),
      campaign: new FormControl ('', Validators.required),
      dead_time: new FormControl ('', [Validators.required, Validators.pattern('^[0-9]+$')]),
      campaign_bilingue: new FormControl ('', Validators.required),
      campaign_conexion: ['', Validators.required]
    });
  }

      /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * cargar la data del elemento correspondiente
  */
  viewServer(server: any): void {
    this.campaignService.showCampaign(server.id).subscribe(res => {
      this.dataSource = res.data;
      this.EditCampaignForm.patchValue({
        id: res.data.id,
        campaign_name: res.data.name,
        campaign: res.data.campaign_id,
        dead_time: res.data.dead_time,
        campaign_bilingue: res.data.bilingue,
        campaign_conexion:res.data.connection_id
      });
    });
  }


  /**
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * Metodo para traer todas las campañas
   */

  getCampaign(): void {
    this.viewservice.getCampaigns().subscribe((resp) => {
      this.campaigns = resp.data;
      this.filteredOptionsCampaign = this.EditCampaignForm.get('campaign').valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.campaign_name),
        map(campaigns => campaigns ? this._filterCampaign(campaigns) : this.campaigns.slice())
      );
    })
  }

  /**
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * Metodo para traer todos las conexiones activas
   */

  getConnections(): void {
    this.adminServersService.filterServe().subscribe(
      result => {
        this.connections = result.data;
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: error.error.error,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.dialogRef.close();
          }
        });
      }
    );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFnCampaign(campaign_id: number): string {
    if (!campaign_id) { return ''; }
    let index = this.campaigns.findIndex(resp => resp.campaign_id === campaign_id);
    return this.campaigns[index].campaign_name;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre de rol, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filterCampaign(value: string): any[] {
    const filterValueCampaign = value.toLowerCase();
    return this.campaigns.filter(option => option.campaign_name.toLowerCase().indexOf(filterValueCampaign) === 0);
  }

  /**
      * @author Yeison Sepulveda
      * @createdate 2024-02-15
      * Metodo que guarda o actualiza un servidor. 
    */
  sendDataCampaign() {
    if (this.EditCampaignForm.valid) {
      Swal.fire({
        title: '¿Está seguro?',
        text: '¿De continuar con la gestión?',
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          const dataToSend = {
            id: this.EditCampaignForm.value.id,
            name: this.EditCampaignForm.value.campaign_name,
            campaign_id: this.EditCampaignForm.value.campaign,
            dead_time: this.EditCampaignForm.value.dead_time,
            bilingue: this.EditCampaignForm.value.campaign_bilingue,
            connection_id: this.EditCampaignForm.value.campaign_conexion
          };
          this.campaignService.UpdateCampaign(dataToSend).subscribe(
            res => {
              Swal.fire({
                title: '¡Excelente!',
                text: 'Se ha registrado una nueva campaña',
                icon: 'success',
                confirmButtonText: 'Continuar',
                confirmButtonColor: '#2CABBC'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.dialogRef.close();
                }
              });
            },
            error => {
              Swal.fire('Error', 'Ha ocurrido un error al crear la campaña.', 'error');
            }
          );
        }
      });
    } else {
      this.EditCampaignForm.markAllAsTouched();
    }
  }
  
  /**
  * @author Yeison Sepulveda
  * @createdate 2024-02-15
  * Metodo que reinicia los valores del modal. 
  */
  cancelSave(): void {
  Swal.fire({
    title: '¿Estás seguro?',
    text: '¿En verdad deseas cancelar la gestión?',
    icon: 'warning',
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonColor: '#2CABBC',
    cancelButtonColor: '#FFFFFF',
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Aceptar',
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      this.EditCampaignForm.reset();
      this.dialogRef.close();
    }
  });
  }


}
