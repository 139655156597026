import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { StrategyServersService } from '../../../services/strategy.service';

@Component({
  selector: 'app-strategy-modal',
  templateUrl: './strategy-modal.component.html',
  styleUrls: ['./strategy-modal.component.sass']
})
export class StrategyModalComponent implements OnInit {

  strategyForm: FormGroup;
  mostrarContenido: boolean = false;
  items: string[] = [];
  modo: 'crear' | 'editar' | 'visualizar';
  chips: any[] = [];
  selectedChips: any[] = [];


  constructor( 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StrategyModalComponent>,
    private strategyService: StrategyServersService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.formControl();
    this.agregarSeccion();

    this.modo = this.data.modo;
    const strategyId = this.data.id; 

    if (this.modo === 'visualizar') {
      this.strategyForm.disable();
      this.loadStrategy(strategyId);
    } else if (this.modo === 'editar') {
      this.loadStrategy(strategyId);
    }
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
   * Método donde se establecen las validaciones del formulario.
   */
  formControl(): void {
    this.strategyForm = this.formBuilder.group({
      name: ['', Validators.required],
      sections: this.formBuilder.array([]),
    });
  } 
    

  /**
    * Convertir el texto a minúsculas, eliminar caracteres de puntuación y luego dividir el texto en palabras para convertirlos en chips
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
  */
  
  createChips(value: string, indexSection: number) {
    const trimmedValue = value.trim();

    const numberPattern = /(\d+(?:[.,]\d+)?)/g;
    let numbers: string[] = [];
    let protectedValue = trimmedValue.replace(numberPattern, (match) => {
        numbers.push(match);
        return `NUMBER${numbers.length - 1}`;
    });

    protectedValue = protectedValue.toLowerCase().replace(/[.,\/#!^&*;:{}=\-_`~()¡¿!?'"|]/g, "");

    protectedValue = protectedValue.replace(/number(\d+)/g, (match, index) => numbers[index]);

    const words = protectedValue.split(/\s+|\n/);
    //palabras a excluir
    const excludedWords = new Set([

      "el", "la", "los", "las", "un", "una", "unos", "unas",
      "este", "ese", "aquel", "esta", "esa", "es", "si",
      "estos", "esos", "estas", "esas", "con", "que", "por",
      "el", "ella", "ellos", "ellas", "nos", "os",

      "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",
      "n", "ñ", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",

      "ab", "ac", "ad", "ae", "af", "ag", "ah", "ai", "aj", "ak", "al", "am",
      "an", "ao", "ap", "aq", "ar", "as", "at", "au", "av", "aw", "ax", "ay",
      "az", "ba", "be", "bi", "bo", "bu", "ca", "ce", "ci", "co", "cu", "da",
      "de", "di", "do", "du", "ea", "ee", "ei", "eo", "eu", "fa", "fe", "fi",
      "fo", "fu", "ga", "ge", "gi", "go", "gu", "ha", "he", "hi", "ho", "hu",
      "ia", "ie", "ii", "io", "iu", "ja", "je", "ji", "jo", "ju", "ka", "ke",
      "ki", "ko", "ku", "la", "le", "li", "lo", "lu", "ma", "me", "mi", "mo",
      "mu", "na", "ne", "ni", "no", "nu", "ña", "ñe", "ñi", "ño", "ñu", "oa",
      "oe", "oi", "oo", "ou", "pa", "pe", "pi", "po", "pu", "qa", "qe", "qi",
      "qo", "qu", "ra", "re", "ri", "ro", "ru", "sa", "se", "si", "so", "su",
      "ta", "te", "ti", "to", "tu", "ua", "ue", "ui", "uo", "uu", "va", "ve",
      "vi", "vo", "vu", "wa", "we", "wi", "wo", "wu", "xa", "xe", "xi", "xo",
      "xu", "ya", "ye", "yi", "yo", "yu", "za", "ze", "zi", "zo", "zu"
    ]);

    const validWords = words.filter(word => word.trim() !== "" && word.length > 1 && !excludedWords.has(word));
    const existingChips = this.chips[indexSection].filter(chip => validWords.includes(chip.text));

    const newChips = validWords
        .filter(word => !this.chips[indexSection].some(chip => chip.text === word))
        .map(word => ({ text: word, selected: false }));

    this.chips[indexSection] = [...existingChips, ...newChips];
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
   * Obtener las palabras de los chip seleccionados
   */

  chipSelection(chip: any, indexSection: number) {
    // Comprobar si el chip ya está seleccionado en otra sección
    const alreadySelected = this.selectedChips.some((section, index) => index !== indexSection && section.some(selectedChip => selectedChip.text === chip.text));

    if (!alreadySelected) {
        chip.selected = !chip.selected;
        if (chip.selected) {
            this.selectedChips[indexSection].push(chip);
        } else {
            this.selectedChips[indexSection] = this.selectedChips[indexSection].filter(c => c !== chip);
        }

        // Validar si hay chips seleccionados o no
        this.chips[indexSection].forEach((chip: any) => {
            const etiquetaSeleccionada = this.selectedChips[indexSection].find((selectedChip: any) => selectedChip.text === chip.text);
            if (etiquetaSeleccionada) {
                chip.selected = true;
            }
        });
    } else {
        // Mostrar una alerta con SweetAlert
        Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Esta etiqueta ya está seleccionada en otra sección.',
            confirmButtonText: 'Entendido',
            confirmButtonColor: '#2CABBC',
        });
    }
  }
  

  /**
   * Metodo que se encarga de eliminar los chips seleccionados
   * @param chipDeleted:any {text:"", selected:true|false}
   * @param indexSection:number indice de la seccion
   */
  removeWordSelected(chipDeleted:any, indexSection:number){
    if(chipDeleted.selected == true){
      this.chips[indexSection] = this.chips[indexSection].map( mmpp => {
        if(mmpp.text == chipDeleted.text){
          mmpp.selected = false
        }
        return mmpp;
      });
      this.selectedChips[indexSection] = this.selectedChips[indexSection].filter(ffll => ffll.text !== chipDeleted.text)
    }
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
   * Obtener las secciones
   */
  get sections() {
    return this.strategyForm.get('sections') as FormArray;
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
   * Crear una nueva seccion
   */
  createSection(index: number): FormGroup {
    this.chips[index] = [];
    this.selectedChips[index] = [];
    return this.formBuilder.group({
      section_name: ['', Validators.required],
      guion: ['', Validators.required],
      etiquetas: ['', Validators.required]
    });
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
   * Agragar una seccion
   */
  agregarSeccion(): void {
    const index = this.sections.length;
    this.sections.push(this.createSection(index));
  }

  /**
   * Eliminar una seccion
   * @createdate 2024-04-08
   * @author Yeison Sepulveda
  */
  eliminarSeccion(index: number): void {
    console.log('este es el index',index)
    if(this.sections.controls.length > 1) {
      this.sections.removeAt(index);
      this.chips.splice(index, 1);
      this.selectedChips.splice(index, 1);
    }
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
    * Guardar estrategia
  */

  saveStrategy() {
    Swal.fire({
      text: '¿Está seguro de guardar el contenido?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        let formData = { 
          name: this.strategyForm.get('name').value, 
          sections: [] 
        };
        this.sections.controls.forEach((section: any) => {
          const section_name = section.get('section_name').value;
          const guion = section.get('guion').value;
          const selectedChipsForSection = this.selectedChips[this.sections.controls.indexOf(section)];
          const etiquetas = selectedChipsForSection.map((chip: any) => chip.text);
          formData.sections.push({ section_name, guion, etiquetas });
        });
        this.strategyService.createStrategy(formData).subscribe(res => {
          Swal.fire({
            title: '¡Excelente!',
            text: 'Se ha registrado una nueva estrategia',
            icon: 'success',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#2CABBC',
          }).then((result) => {
            if (result.isConfirmed) {
              this.dialogRef.close();
            }
          });
          
        }); 
      }
    });
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
    * Actualizar estrategia
  */

  updateStrategy() {
    Swal.fire({
      text: '¿Está seguro de modificar el contenido?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        let formData = { 
          name: this.strategyForm.get('name').value, 
          sections: [],
          id: this.data.id };
          this.sections.controls.forEach((section: any) => {
            const section_name = section.get('section_name').value;
            const guion = section.get('guion').value;
            const selectedChipsForSection = this.selectedChips[this.sections.controls.indexOf(section)];
            const etiquetas = selectedChipsForSection.map((chip: any) => chip.text);
            formData.sections.push({ section_name, guion, etiquetas });
          });
        this.strategyService.updateStrategy(formData).subscribe(res => {
          Swal.fire({
            title: '¡Excelente!',
            text: 'Se ha actualizado la estrategia',
            icon: 'success',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#2CABBC',
          }).then((result) => {
            if (result.isConfirmed) {
              this.dialogRef.close();
            }
          });
        }); 
      }
    });
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
   * cargar estrategia
   */
  loadStrategy(strategyId: number): void {
    this.strategyService.getStrategyById(strategyId).subscribe(
      (resp) => {
        this.strategyForm.patchValue({
          name: resp.data.strategy_name,
        });
  
        // Reiniciar los arrays chips y selectedChips
        this.chips = [];
        this.selectedChips = [];
  
        // Eliminar todas las secciones existentes
        while (this.sections.length !== 0) {
          this.sections.removeAt(0);
        }
  
        // Parsear las secciones y crearlas nuevamente
        const sectionsArray = JSON.parse(resp.data.sections); 
        sectionsArray.forEach((section: any, index: number) => {
          // Crear una nueva sección y asociar los índices de chips y selectedChips
          const newSection = this.createSection(index);
          const selectedChipsForSection: any[] = [];
  
          section.etiquetas.forEach((etiqueta: string) => {
            selectedChipsForSection.push({ text: etiqueta, selected: true });
          });
  
          // Asignar los chips y selectedChips a los índices correctos
          this.selectedChips[index] = selectedChipsForSection;
  
          // Poblar los valores de la nueva sección
          newSection.patchValue({
            section_name: section.section_name,
            guion: section.guion
          });
  
          // Agregar la nueva sección al formulario
          this.sections.push(newSection);
  
          // Crear los chips para la nueva sección
          this.createChips(section.guion, index);
        });
      },
      (error) => {
        console.error('Error al cargar:', error);
      }
    );
  }

}

