<div class="container-fluid p-5">
  <div class="row">
    <div class="col-md-12">
      <span style="font-size: 32px; font-weight: 700;">Gestión de estrategias</span>
    </div>
    <div class="col-md-12 mt-4">
      <p class="lh-base" style="color: #353535">
        Este es el módulo de gestión de estrategias, aquí podrás crear, editar y eliminar las estrategias  
        <br/> y sus respectivas etiquetas. No olvides, “El respeto y empatía son clave”.
      </p>
    </div>
  </div>

  <div class="row d-flex justify-content-end mt-5 w-100 ">
    <div class="col-3">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Buscar</mat-label>
        <input #inputField matInput [placeholder]="textInputFilter">
        <button mat-button matSuffix (click)="onSearchClick(inputField.value)">
          <span class="fi-rr-search fs-5"></span>
        </button>
      </mat-form-field>
    </div>
    <div class="col-6"></div>
    <div class="col-md-3">
      <button style="height: 48px;"
          class="w-100" mat-raised-button color="primary" style="height: 48px;" (click)="createdStrategy()">
          Crear estrategia
      </button>
  </div>
  </div>

  <div class="row justify-content-between">
    <div class=" mt-4">
      <div class="table-container">
          <div class="mat-elevation-z1">
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container *ngFor="let column of configColumnsTable">
                <ng-container [matColumnDef]="column.key" *ngIf="column.key !== 'actions'">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ column.name }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element[column.key] }} </td>
                </ng-container>
                <ng-container [matColumnDef]="column.key" *ngIf="column.key === 'actions'">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ column.name }} </th>
                  <td mat-cell class="text-center ps-0" *matCellDef="let element">
                    <button  mat-icon-button style="width: 25px !important;" (click)="viewModal(element.id)">
                      <span class="fi-rr-eye"></span>
                    </button>
                    <button  mat-icon-button style="width: 25px !important;" (click)="editStrategy(element.id)">
                      <span class="fi-rr-edit"></span>
                    </button>
                    <button  mat-icon-button style="width: 25px !important;" (click)="deleteStrategy(element.id)">
                      <span class="fi-rr-trash"></span>
                    </button>
                  </td>
                </ng-container>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="keysColumnsTable"></tr>
              <tr style="background: white" mat-row *matRowDef="let row; columns: keysColumnsTable;"></tr>
              <tr class="mat-row" style="background: white" *matNoDataRow>
                <td class="mat-cell" colspan="5">No existe información relacionada</td>
              </tr>
            </table>
    
            <mat-paginator (page)="changePage($event)" [length]="configPaginator.length"
              [pageSize]="configPaginator.pageSize"
              [pageSizeOptions]="pagesizeOptions">
            </mat-paginator>
        </div>
      
      </div>
    </div>
  </div>
</div>



