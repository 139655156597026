import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class StrategyServersService {
  constructor(private httpClient: HttpClient) { }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
    * Traer las strategias por paginación  
  */

  paginateStrategies(filter: any, paginator: any): Observable<any> {
    const body = { filter, per_page: paginator.pageSize, page: paginator.pageIndex };
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/paginate`, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
    * Obtener la estrategia por id
  */

  getStrategyById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}strategy/show/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
    * Listar las estrategias
  */
  
  listStrategies(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}strategy/listStrategy`).pipe(
      catchError(this.handleError)
    );
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
    * Crear una nueva estrategia 
  */
  
  createStrategy(strategyData: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/create`, strategyData).pipe(
      catchError(this.handleError)
    );
  }
  
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
    * Update de las estrategias
  */

  updateStrategy(strategyData: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/update`, strategyData).pipe(
      catchError(this.handleError)
    );
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
    * Desactivar/inhabilitar las estrategias
  */

  desactiveStrategy(strategyData: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/inactivateStrategy`, strategyData).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que se encarga de asignar las estrategias a las grabaciones indicadas
   * @param 
   * @returns {Observable<any>}  Observable<any>
   */
  assignStrategys(strateyForm:any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/assignStrategy`, strateyForm).pipe(
      catchError(this.handleError)
    );
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-30
    * Metodo genera resultado de adherencia
    * @param recordings array con id de las grabaciones para descargar. 
  */

    applyAdherencia(recordings: any[]): Observable<any> {
      const body = { id_recordings: recordings };
      return this.httpClient.post<any>(`${SPEECH_URL}strategy/percentageAdherencia`,body).pipe(
        catchError(this.handleError)
      );
    }
}