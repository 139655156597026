import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  // servicios a ignorar
  skipApiCall = [
    'mixins/dashboard/orphanChat',
    'auth/refresh',
    'config/extend/check',
    'manual/dial/call/look',
    'incomming',
    'agent-conversation/list_conversation',
    'statistics/list_conversation_by_campaign',
    'statistics/conversation_times',
    'statistics/conversation_times_prom',
    'conversation',
    'Dashboard',
    'PQRS/statusEscalamientos',
    'GetRoomFiles',
    'clients/showPanel/',
    'clients/tabCarteraWom',
    'clients/tabHistoricoContacto',
    'managementClient/',
    'message/get-history',
    'message/send',
    'message/markAllMessagesAsRead',
    'users/chats'
  ];
  private count = 0;

  constructor(private spinner: NgxSpinnerService) {}

  /**
   * Interceptor para un spinner global
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // tslint:disable-next-line: whitespace
    if(this.isValidRequestForInterceptor(request.url)) {
      if (this.count === 0) { this.spinner.show();}
      this.count++;
      return next.handle(request).pipe(
        finalize(() => {
          this.count--;
          if (this.count === 0) {
            this.spinner.hide();
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(position + positionIndicator.length);
      for (let address of this.skipApiCall) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
    }
    return true;
  }
}
