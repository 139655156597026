import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-print-form-survey',
  templateUrl: './print-form-survey.component.html',
  styleUrls: ['./print-form-survey.component.css']
})

export class PrintFormSurveyComponent implements OnInit, OnChanges {
  @Input() questions: any[] = [];
  @Input() textButtonSubmit: string = 'Guardar';
  @Input() ifFormFilter: boolean = false;
  @Output() sendQuestions: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeValueSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendIndexField: EventEmitter<number> = new EventEmitter<number>();
  formSurvey: FormGroup = null;

  constructor(private alertService: AlertsService) { }

  ngOnInit(): void {
    const objectForm = {};
    this.questions.forEach((item: any) => {
      if (item.typeInput === 'date-range') {
        objectForm[item.range[0].key] = new FormControl(item.range[0].value, this.generateValidators(item.range[0].rules));
        objectForm[item.range[1].key] = new FormControl(item.range[1].value, this.generateValidators(item.range[1].rules));
      } else {
        const validators = this.generateValidators(item.rules);
        objectForm[item.key] = new FormControl(item.value, validators);
      }
    });
    this.formSurvey = new FormGroup(objectForm);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.questions.forEach((item: any) => {
      const validators = this.generateValidators(item.rules);
      this.formSurvey?.get(item.key)?.setValidators(validators);
      this.formSurvey?.get(item.key)?.updateValueAndValidity();
    });
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-12
    * Metodo que añade reglas de validacion al formulario.
    * @param rules Json con las configuraciones de las validaciones. 
  */
  generateValidators(rules: any): any[] {
    if (!rules) return [];
    const validators = [];
    for (const key in rules) {
      if (key === 'required') validators.push(Validators.required);
      if (key === 'minLength') validators.push(Validators.minLength(rules[key]));
      if (key === 'maxLength') validators.push(Validators.maxLength(rules[key]));
      if (key === 'email') validators.push(Validators.email);
    }
    return validators;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-11
    * Metodo que envia las respuestas del formulario al componente padre.  
  */
  submitForm(): void {
    if (this.formSurvey.valid) {
      if (!this.ifFormFilter) {
        this.alertService.alertWarning('¿Está seguro?', '¿De enviar las respuestas registradas?').then(isConfirm => {
          if (isConfirm.isConfirmed) this.sendQuestions.emit(this.formSurvey.value);
        });
      } else {
        this.sendQuestions.emit(this.formSurvey.value);
      }
    } else this.formSurvey.markAllAsTouched();

  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-12
    * Metodo que emite la informacion del select, al seleccionar una opcion.
    * @param $event Evento emitido por el campo del formulario. 
    * @param key Id unico del campo.   
  */
  onChangeValueSelect($event: MatSelectChange, key: string): void {
    this.changeValueSelect.emit({ value: $event.value, key });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-22
    * Metodo que captura el indice de la lista de campos del formulario. 
    * @param index Indice de la lista
  */
  onClickShowOrHidePassword(index: number): void {
    this.sendIndexField.emit(index);
  }
}