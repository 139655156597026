<div class="d-flex justify-content-end">
    <div style="color: #353535;">
      <button mat-dialog-close mat-icon-button type="button">
        <span class="mat-button-wrapper"><i class="fi-rr-cross"></i></span>
        <span matripple class="mat-ripple mat-button-ripple-round"></span>
        <span class="mat-button-focus-overlay"></span>
      </button>
    </div>
  </div>

<form [formGroup]="EditCampaignForm">
  <div class="col-md-12 p-5">
      <div class="mb-4" style="font-weight: 700; font-size: 28px;">Editar campaña</div>
      <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal"></mat-divider>
      
      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Nombre de la campaña</mat-label>
          <input matInput placeholder="Nombre de la campaña" formControlName="campaign_name" maxlength="50" required>
          <mat-error *ngIf="EditCampaignForm.controls['campaign_name'].hasError('required')">Nombre de la campaña es requerido</mat-error>
        </mat-form-field>
      </div>
      
      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
					<mat-label>Campaña</mat-label>
					<input type="text" matInput formControlName="campaign" [matAutocomplete]="auto" required />
					<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnCampaign.bind(this)" required>
						<mat-option *ngFor="let option of filteredOptionsCampaign | async" [value]="option.campaign_id">
							{{ option.campaign_name }}
						</mat-option>
					</mat-autocomplete>
          <mat-error *ngIf="EditCampaignForm.controls['campaign'].hasError('required')">Seleccione una campaña</mat-error>
				</mat-form-field>
      </div>

      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Tiempo muerto (segundos)</mat-label>
            <input type="number" matInput placeholder="Tiempo muerto" formControlName="dead_time" maxlength="50" required>
          <mat-error *ngIf="EditCampaignForm.controls['dead_time'].hasError('required')">Seleccione una opción</mat-error>
        </mat-form-field>
      </div>
      
      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Bilingue</mat-label>
          <mat-select placeholder="Bilingue" formControlName="campaign_bilingue" required>
            <mat-option value="Si">Si</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error *ngIf="EditCampaignForm.controls['campaign_conexion'].hasError('required')">Seleccione una opción</mat-error>
        </mat-form-field>
      </div>
      
      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Conexión</mat-label>
          <mat-select placeholder="Conexión" formControlName="campaign_conexion" required>
            <mat-option *ngFor="let item of connections" [value]="item.id">{{ item.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="EditCampaignForm.controls['campaign_conexion'].hasError('required')">Seleccione una conexión</mat-error>
        </mat-form-field>
      </div>

    </div>
  
</form>

<div class="col-md-12 p-5 pt-0">
  <div class="row py-3 d-flex justify-content-end">
    <div class="col-md-3">
      <button (click)="cancelSave()" type="button" mat-raised-button class="w-100" style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
    </div>
    <div class="col-md-3">
      <button (click)="sendDataCampaign()" type="button" mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600;" [disabled]="!EditCampaignForm.valid">Guardar</button>
    </div>
  </div>
</div>


  
