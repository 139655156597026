<div class="row d-flex justify-content-end mt-5 w-100 ">
  <div class="col-3">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Buscar</mat-label>
      <input #inputField matInput [placeholder]="textInputFilter">
      <button mat-button matSuffix (click)="onSearchClick(inputField.value)">
        <span class="fi-rr-search fs-5"></span>
      </button>
    </mat-form-field>
  </div>
  <div class="col-6"></div>
  <div class="col-3">
      <button class="w-100 heaight-buttons" style="height: 48px;" (click)="createCampaing()" mat-flat-button color="primary">
          Crear Campaña
      </button>
  </div>
</div>

<div class=" mt-4">
  <div class="table-container">
      <div class="mat-elevation-z1">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container *ngFor="let column of configColumnsTable">
            <ng-container [matColumnDef]="column.key" *ngIf="column.key !== 'actions'">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ column.name }} </th>
              <td mat-cell *matCellDef="let element"> {{ element[column.key] }} </td>
            </ng-container>
            <ng-container [matColumnDef]="column.key" *ngIf="column.key === 'actions'">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ column.name }} </th>
              <td mat-cell class="text-center ps-0" *matCellDef="let element">
                <mat-slide-toggle [(ngModel)]="element.active" (change)="toggleStateChanged(element)"></mat-slide-toggle>
                <button  mat-icon-button style="width: 25px !important;" (click)="editCampaign(element.id)">
                  <span class="fi-rr-edit"></span>
                </button>
              </td>
            </ng-container>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="keysColumnsTable"></tr>
          <tr style="background: white" mat-row *matRowDef="let row; columns: keysColumnsTable;"></tr>
          <tr class="mat-row" style="background: white" *matNoDataRow>
            <td class="mat-cell" colspan="5">No existe información relacionada</td>
          </tr>
        </table>

        <mat-paginator (page)="changePage($event)" [length]="configPaginator.length"
        [pageIndex]="configPaginator.pageIndex" [pageSize]="configPaginator.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100]">
      </mat-paginator>
    </div>
  
  </div>
</div>
