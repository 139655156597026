import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class AdminServersService {
  constructor(private httpClient: HttpClient) { }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que retorna los servidores registrados en el sistema.  
  */
    getServers(paginator: any, filter: string): Observable<any> {
      return this.httpClient.get<any>(`${SPEECH_URL}connection?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&filter=${filter}`).pipe(
        catchError(this.handleError)
      );
    }
  
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que retorna el servidor seleccionado por ID.  
  */
  showServer(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}connection/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que crea o actualiza el servidor seleccionado. 
    * @param connection Informacion proveniente del servidor. 
  */
  UpdateServer(connection: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}connection/${connection.id}`, connection).pipe(
      catchError(this.handleError)
    );
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que crea o actualiza el servidor seleccionado. 
    * @param connection Informacion proveniente del servidor. 
  */
    CreaServer(connection: any): Observable<any> {
      return this.httpClient.post<any>(`${SPEECH_URL}connection/`, connection).pipe(
        catchError(this.handleError)
      );
    }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo trae los servicios que estan en estado de activo
    * @param id Id del servidor seleccionado. 
  */
  filterServe(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}getConnection`).pipe(
      catchError(this.handleError)
    );
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo cambia el estado activo de la campaña si y no tiene asociado una campaña
    * @param id Id del servidor seleccionado. 
  */
  StateChange(connection_id: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}connectionState`, connection_id).pipe(
      catchError(this.handleError)
    );
  }
}