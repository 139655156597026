<form [formGroup]="formSurvey">
  <div class="row row-gap-3">
    <ng-container *ngFor="let question of questions; let i = index">
      <div *ngIf="question.type !== 'hidden' || question.show" [ngClass]="!ifFormFilter ? 'col-md-12' : question.class">
        <mat-form-field *ngIf="question.typeInput === 'input' && question.type !== 'hidden'" appearance="fill"
          class="w-100">
          <mat-label>{{ question.label }}</mat-label>
          <input [type]="question.type" matInput [placeholder]="question.label" [formControlName]="question.key">
          <a (click)="onClickShowOrHidePassword(i)" mat-icon-button matSuffix *ngIf="question.hasOwnProperty('hide')"
            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{ question.hide ? 'visibility' : 'visibility_off' }}</mat-icon>
          </a>
          <mat-error
            *ngIf="formSurvey.controls[question.key].invalid && (formSurvey.controls[question.key].dirty || formSurvey.controls[question.key].touched)">
            <span *ngIf="formSurvey.controls[question.key].errors.required">
              El campo es requerido
            </span>
            <span *ngIf="formSurvey.controls[question.key].errors.minlength">
              Mínimo de caracteres invalido
            </span>
            <span *ngIf="formSurvey.controls[question.key].errors.maxlength">
              Maximo de caracteres invalido
            </span>
            <span *ngIf="formSurvey.controls[question.key].errors.email">
              Email invalido
            </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="question.typeInput === 'select'" appearance="fill" class="w-100">
          <mat-label>{{ question.label }}</mat-label>
          <mat-select [formControlName]="question.key" (selectionChange)="onChangeValueSelect($event, question.key)">
            <mat-option *ngFor="let option of question.options" [value]="option.value">{{ option.text }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="formSurvey.controls[question.key].invalid && (formSurvey.controls[question.key].dirty || formSurvey.controls[question.key].touched)">
            <span *ngIf="formSurvey.controls[question.key].errors.required">
              El campo es requerido
            </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="question.typeInput === 'date' && question.show" appearance="fill" class="w-100">
          <mat-label>Fecha</mat-label>
          <input [formControlName]="question.key" matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error
            *ngIf="formSurvey.controls[question.key].invalid && (formSurvey.controls[question.key].dirty || formSurvey.controls[question.key].touched)">
            <span *ngIf="formSurvey.controls[question.key].errors.required">
              El campo es requerido
            </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="question.typeInput === 'date-range' && question.show" appearance="fill" class="w-100">
          <mat-label>Rango de fechas</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [formControlName]="question.range[0].key" placeholder="Fecha inicial">
            <input matEndDate [formControlName]="question.range[1].key" placeholder="Fecha final">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error
            *ngIf="formSurvey.controls[question.range[0].key].invalid && (formSurvey.controls[question.range[0].key].dirty || formSurvey.controls[question.range[0].key].touched)">
            <span *ngIf="formSurvey.controls[question.range[0].key].errors.required">
              Fecha inicial requerida
            </span>
          </mat-error>
          <mat-error
            *ngIf="formSurvey.controls[question.range[1].key].invalid && (formSurvey.controls[question.range[1].key].dirty || formSurvey.controls[question.range[1].key].touched)">
            <span *ngIf="formSurvey.controls[question.range[1].key].errors.required">
              Fecha final requerida
            </span>
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>
    <div *ngIf="ifFormFilter" class="col-md-3">
      <button (click)="submitForm()" type="button" mat-raised-button color="primary" class="w-100"
        style="height: 48px; font-weight: 600;">{{ textButtonSubmit }}</button>
    </div>
  </div>
  <div *ngIf="!ifFormFilter" class="row py-3 d-flex justify-content-end">
    <div class="col-md-3">
      <button mat-dialog-close type="button" mat-raised-button class="w-100"
        style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
    </div>
    <div class="col-md-3">
      <button (click)="submitForm()" type="button" mat-raised-button color="primary" class="w-100"
        style="height: 48px; font-weight: 600;">{{ textButtonSubmit }}</button>
    </div>
  </div>
</form>