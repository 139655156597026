<div class="container-fluid">
  <div class="row my-5 justify-content-start">
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Usuarios</mat-label>
        <mat-select [(ngModel)]="userSelected" multiple>
          <mat-option *ngFor="let item of usersToRolSpeech" [value]="item.rrhh_id">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-2">
      <button class="heaight-buttons w-100"  matTooltip='Buscar' matTooltipPosition="above" mat-flat-button color="primary"  (click)="getInfoUserSelection()">
        Buscar &nbsp; <i class="fi-rr-search"></i>
    </button>
    </div>
  </div>
  
  <app-exchange-lists
    *ngIf="showExchangeLists"
    [taskDescription]="'Nota: Recuerda que para asignar campañas a los usuarios, deberás seleccionarlas con los checks del lado izquierdo (campañas disponibles) y luego dar clic en el botón de la flecha para pasarlas al lado derecho (campañas asignadas).'"
    [titleFirstList]="'Campañas disponibles'" [titleSecondList]="'Campañas asignadas'"
    [textInputFirstList]="'Buscar campaña disponible'" [textInputSecondList]="'Buscar campañas asignadas'"
    [firstList]="firstList" [secondList]="secondList" (runFilter)="runFilterList($event)"
    (updateList)="updateList($event)" (saveConfig)="saveConfig($event)" (cancelProcess)="cancelProcess()">
  </app-exchange-lists>
</div>