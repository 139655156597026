import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

const CIU_URL = environment.CIU_URL;
const RRHH_URL = environment.RRHH_URL;

@Injectable({
  providedIn: 'root'
})

export class UsersService {

  constructor(private http: HttpClient, private authService: AuthService) {

  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Servicio que trae los datos del usuario paginados para mostrarlos en una tabla
   * @param size cuantos datos traera
   * @param page pagina que traera
   */
  getUsers(size: number, page: number): Observable<any> {
    return this.http.get<any>(`${CIU_URL}users?rrhh=1&n=` + size + `&page=` + page)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * @author Carlos Nieto
  * @createdate 2021-02-16
  * Servicio que trae los datos del usuario paginados para mostrarlos en una tabla
  * (version implementada con filtro)
  * @param params listado string de parametros como longitud y filtrados
  */
  getUsersFilter(params: string) {
    return this.http.get<any>(`${CIU_URL}users?rrhh=1&${params}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Servicio que se encarga de la busqueda de un usuario
   * @param size cuantos datos traera
   * @param value valor a buscar
   */
  searchUser(size: number, value: any): Observable<any> {
    return this.http.get<any>(`${CIU_URL}users/search?q=` + size + `&q=` + value)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Servicio que trae los tipos de id existentes
   */
  getIdTypes(): Observable<any> {
    return this.http.get<any>(`${CIU_URL}users/getIdTypes`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Servicio que trae los roles por aplicacion
   * @param id id del aplicativo
   */
  getAppxRol(id: any): Observable<any> {
    return this.http.get<any>(`${CIU_URL}users/` + id + `/roles`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Servicio que busca los username y verifica si ya existe
   * @param userName user a buscar
   */
  searchNameUser(userName: FormData): Observable<any> {
    return this.http.post<any>(`${CIU_URL}users/validateUsername`, userName)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Servicio que busca la identificacion y devuelve sus datos si ya existe
   * @param dataId identificacion a buscar
   */
  searchId(dataId: FormData): Observable<any> {
    return this.http.post<any>(`${CIU_URL}users/validateDocumentId`, dataId)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Servicio que se encarga de guardar un nuevo usuario
   * @param user datos del usuario a guardar
   */
  saveUser(user: any): Observable<any> {
    return this.http.put<any>(`${CIU_URL}users/`, user)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Servicio que se encarga de editar la informacion de un usuario
   * @param id id del usuario
   * @param user datos del usuario a editar
   */
  editUser(id: any, user: any): Observable<any> {
    return this.http.put<any>(`${CIU_URL}users/` + id, user)
      .pipe(
        catchError(this.handleError)
      );
  }


  /**
   * @author Carlos Nieto
   * @createdate 2021-12-15
   * Servicio que busca la identificacion y devuelve sus datos si ya existe
   * @param data identificacion a buscar
   */
  massiveRolesImport(data: FormData): Observable<any> {
    return this.http.post<any>(`${CIU_URL}users/massiveRolesImport`, data)
      .pipe(
        catchError(this.handleError)
      );
  }



  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Servicio que se encar de borrar un usuario
   * @param idUser id del usuario a borrar
   */
  deleteUser(idUser: any): Observable<any> {
    return this.http.delete<any>(`${CIU_URL}users/` + idUser)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserByCampaign(idCampaign: number): Observable<any> {

    return this.http.get<any>(`${RRHH_URL}users/campaign/${idCampaign}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveUserWithGroup(users: any, idgroup: number): Observable<any> {

    return this.http.post<any>(`${CIU_URL}usersWithGroup/${idgroup}`, users)
      .pipe(
        catchError(this.handleError)
      );
  }
  saveUserWithoutGroup(users: any,): Observable<any> {

    return this.http.post<any>(`${CIU_URL}usersWithoutGroup`, users)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserByGroup(body: any): Observable<any> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let params = new URLSearchParams();
    params.append("group_id", body.group_id);
    body.campaignIds.forEach(element => {
      params.append("campaignIds[]", element)
    });

    return this.http.get<any>(`${CIU_URL}usersByGroup?${params}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Sebastián Daza
   * @createdate 2021-02-16
   * Genera loggueo en el sistema de odoo por medio de ciu
   * @param userId id del usuario a loguear
   */
  odooLoggin(userId: number): Observable<any> {
    let data: any = {
      id_user: userId
    };
    return this.http.post(`${CIU_URL}odoo/loggin`, data);

  }


  /**
 * @author Sebastián Daza
 * @createdate 2021-02-16
 * Genera loggueo en el sistema de odoo por medio de ciu
 * @param userId id del usuario a loguear
 */
  odooIsLoggin(session_id: string): Observable<any> {
    let data: any = {
      session_id: session_id
    };
    return this.http.post(`${CIU_URL}odoo/isLoggin`, data);

  }

    /**
 * @author Sebastián Daza
 * @createdate 2021-02-16
 * Genera loggueo en el sistema de odoo por medio de ciu
 * @param document documento del usuario
 * @param documentType tipo de documento 
 */
  getUserByDocument(document: any, documentType: any): Observable<any>{
    return this.http.get(`${CIU_URL}getUserByDocument/${document}/${documentType}`)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @author Javier Saldana
   * @createdate 2022-05-05
   * Servicio que se encar de borrar un usuario
   * @param idUser id del usuario a borrar
   */
  blockUser(idUser: any, body: any = {}): Observable<any> {
    return this.http.post<any>(`${CIU_URL}users/delete-user/` + idUser, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Saldana
   * @createdate 2022-05-05
   * Servicio que se encar de borrar un usuario
   * @param idUser id del usuario a borrar
   */
   restoreUser(idUser: any, body: any = {}): Observable<any> {
    return this.http.post<any>(`${CIU_URL}user/restore/` + idUser, body)
      .pipe(
        catchError(this.handleError)
      );
  }

}
