import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SpeechAnalyticsComponent } from './speech-analytics.component';
import { SpeechToTextComponent } from './modules/speech-to-text/speech-to-text.component';
import { StrategyMenuComponent } from './modules/strategy/strategy-menu/strategy-menu.component';
import { AdherenciaComponent } from './modules/strategy/adherencia/adherencia.component';
import { AdminComponent } from './modules/configuration-administrator/admin/admin.component';
import { NodirigidoComponent } from './modules/nodirigido/nodirigido.component';
import { DirigidoComponent } from './modules/search-speech/directed-search/dirigido/dirigido.component';
import { SpeechmenuComponent } from './modules/search-speech/speech-menu/speech-menu.component';


const routes: Routes = [
  { path: '', component:  SpeechAnalyticsComponent },
  { path: 'speech-to-text', component: SpeechToTextComponent},
  { path: 'strategy', component: StrategyMenuComponent},
  { path: 'admin', component: AdminComponent},
  { path: 'search', component: SpeechmenuComponent},
  { path: 'no-dir', component: NodirigidoComponent},





]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SpeechAnalyticsRoutingModule { }
