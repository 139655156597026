import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { StrategyServersService } from '../../services/strategy.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as html2pdf from 'html2pdf.js';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-adherencia-modal',
  templateUrl: './adherencia-modal.component.html',
  styleUrls: ['./adherencia-modal.component.sass']
})
export class AdherenciaModalComponent implements OnInit {

  recordingID: any;
  adherenciaIA: any;
  adherenciaResponse: { adherencePercentage: number, notAdherencePercentage: number };
  transcripcion: any[] = [];
  keys: any[] = [];
  adherencePercentage: number;
  notAdherencePercentage: number;
  transcripcionFormat: any[] = [];
  @ViewChild('containerViewReport') containerViewReport: ElementRef;
  @ViewChild('transcripcionPanel') transcripcionPanel: MatExpansionPanel;
  @ViewChild('adherencia') adherencia: MatExpansionPanel;
  keysArray: any[];

  constructor(
    private strategyService: StrategyServersService,
    private sanitazer:DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any 
  ) {
    this.recordingID = [data.recordingId];
  }

  ngOnInit(): void {
    this.applyStrategySelected(this.recordingID);
  }

  /**
   * Método consuta servicio de la estrategia aplicada
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   */

  applyStrategySelected(strategy_ids: string[]): void {
    this.strategyService.applyAdherencia(strategy_ids).subscribe(
      (adherenciaResponses) => {
        adherenciaResponses.forEach((response) => {

          const transcripcion = [];
          const diarization = JSON.parse(response.diarization);
  
          if (diarization.interaccion && typeof diarization.interaccion === 'string') {
            const interaccionArray = JSON.parse(diarization.interaccion);
            interaccionArray.forEach((item: any) => {
              transcripcion.push({
                  speaker: item.speaker,
                  text: this.sanitazer.bypassSecurityTrustHtml(item.text)
              });
          });

          }
  
          this.transcripcion = transcripcion;
          this.transcripcionFormat = Array.isArray(this.transcripcion) ? this.transcripcion : [this.transcripcion];
          this.adherenciaIA = response.strategy_result;
  
          //Array dherencia y No adherencia en una variable
          this.adherenciaResponse = adherenciaResponses;
          this.adherencePercentage = response.adherence_percentage;
          this.notAdherencePercentage = response.not_adherence_percentage;
  
          //los valores de Adherencia y No adherencia en una variable
          this.adherenciaResponse = {
            adherencePercentage: this.adherencePercentage,
            notAdherencePercentage: this.notAdherencePercentage
          };

        });
      },
      (adherenciaError: any) => {
        console.error("Error al calcular la adherencia:", adherenciaError);
      }
    );
  }

  /**
   * Metodo que imprime el correo seleccionado.
    * @author Fabian Duran
    * @createdate 2024-01-03
    * @update Yeison Sepulveda
    * @updatedate 29-05-2024
  */
  async onClickPrintToEmailPdf() {
    this.transcripcionPanel.open();
    this.adherencia.open();
    const content = this.containerViewReport.nativeElement;
    
    const margin = 13; //milimtros
    const options = {
        margin: margin, 
        filename: 'Reporte_adherencia.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
            scale: 2
          },
        jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait'
        },
        pagebreak: {
            mode: ['avoid-all', 'css', 'legacy']
        }
    };

    html2pdf().from(content).set(options).toContainer().toCanvas().toImg().toPdf().save();
  }

}
