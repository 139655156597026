import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { SpeechToTextComponent } from '../speech-to-text/speech-to-text.component';
import { PageEvent } from '@angular/material/paginator';
import { AudioPlayerComponent } from '../audio-player/audio-player.component';
import { NoDirigidoService } from '../../services/nodirigido.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { MatSort } from '@angular/material/sort';
import { RequestService } from '../../services/request.service';
import { ViewService } from '../../services/view.service';
import { StrategyModalComponent } from '../strategy/strategy-modal/strategy-modal.component';
import { FormControl } from '@angular/forms';
import { StrategyServersService } from '../../services/strategy.service';
import { AdherenciaModalComponent } from '../adherencia-modal/adherencia-modal.component';
import { DatePipe } from '@angular/common';

interface FileItem {
  file: File; 
  name: string; 
  size: number;
}
@Component({
  selector: 'app-nodirigido',
  templateUrl: './nodirigido.component.html',
  styleUrls: ['./nodirigido.component.sass']
})
export class NodirigidoComponent implements OnInit {

  /**
   * Variables de archivos subidos
  */

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: FileItem[] = [];


  /**
   * Variables de tabla 
   */
  dataSourceRecordings = new MatTableDataSource<any>();
  dataSourceStrategy = new MatTableDataSource<any>();  
  @ViewChild(MatSort) sort: MatSort;

  /**
   * Variables de seleccion
   */
  selectionRecordings = new SelectionModel<any>(true, []);
  selectionStrategy = new SelectionModel<any>(true, []);

  /**
   * Columnas tablas
   */
  displayedColumnsRecordings: string[] = ['checbox', 'created_at', 'user_soul','name_file','audio'];
  displayedColumnsStrategy: string[] = ['checbox', 'created_at', 'user_soul','name_file','audio','analisis'];
  
  /**
   * Configuracion tabla
   */
  
  configPaginatorRecording:any={ pageSize : 20, length:0,  pageIndex:1 }
  configPaginatorStrategy:any={ pageSize : 20, length:0,  pageIndex:1 }
  
  pageSizeOptions:number[]= [5, 10, 20]

  /**
   * Cabeceras de tablas
   */
  
  tHeadersRecordings:any={
    checbox: "", created_at:'Fecha', user_soul: "Usuario", 
    name_file: "Nombre audio", audio:'Audio', 
    analisis:'Análisis', adherencia:'Adherencia'
  }

  user: any; // data del usuario segun sesion

  strategyControl:FormControl = new FormControl(null) //formcontrol para selector

  listStrategys:any[] = [] //selector estrategias

  //Variables de control con Array original de datos para tablas que requiren Paginacion estatica
  listrecordingsAnalising:any[] = []
  listRecordingsStrategys:any[] = []
  totalFilesUploaded: number = 0;
  respload: any[] = [];

  constructor(
    private dialog: MatDialog,
    private viewservice: ViewService,
    private requestService: RequestService,
    private strategyService: StrategyServersService,
    private noDirigidoService: NoDirigidoService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.getStrategys();
  }

  /**
    * Método que maneja el evento de soltar archivos.
    * @author Yeison Sepulveda
    * @param $event El evento de soltar archivos.
  */

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * Método que maneja la selección de archivos a través de un input de tipo archivo.
   * @author Yeison Sepulveda
   * @createdate 2024-05-08
   * @param files La lista de archivos seleccionados.
  */

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Elimina todos los archivos
   * @author Yeison Sepulveda
   * @createdate 2024-05-08
   * 
  */
  deleteAllFiles() {
    this.files = [];
    this.totalFilesUploaded = 0;
  }

  /**
   * Elimina un archivo de la lista de archivos.
   * @param index El índice del archivo a eliminar.
   * @autor Yeison Sepulveda
  */

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.totalFilesUploaded--;
  }

  /**
   * Prepara una lista de archivos para su procesamiento.
   * @param files La lista de archivos a preparar.
   * @autor Yeison Sepulveda
  */
  prepareFilesList(files: FileList) {
    if (!files) return;
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      const extension = file.name.split('.').pop().toLowerCase();
      if (extension === 'mp3' || extension === 'wav') {
        this.files.push({ file: file, name: file.name, size: file.size });
        this.totalFilesUploaded++;
      } else {
        Swal.fire({
          icon: 'warning',
          title: '¡Aviso!',
          text: 'El o los audios deben estar en formato MP3 o WAV.',
          confirmButtonText: 'Entendido',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: '#2CABBC',
        });
      }
    }
    this.fileDropEl.nativeElement.value = "";
  }
  

  /**
   * Formatea el tamaño de los bytes en una cadena legible.
   * @param bytes El tamaño en bytes a formatear.
   * @param decimals El número de decimales a mostrar (por defecto 2).
   * @returns Una cadena que representa el tamaño formateado.
   * @autor Yeison Sepulveda
   */

  formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  /**
  * @author Yeison Sepulveda
  * @createdate 2024-02-15
  * Metodo para acargar los audios
  */

  loadAudios(stepperReference?: any): void {
    this.deleteColumnAnalytics();
    if (this.totalFilesUploaded === 0) {
      Swal.fire({
        title: 'Alerta',
        text: 'No hay audios para subir.',
        icon: 'warning',
        confirmButtonColor: '#2CABBC',
        confirmButtonText: 'Aceptar'
      });
    } else if (this.totalFilesUploaded > 20) {
      Swal.fire({
        title: 'Alerta',
        text: 'No se permite cargar más de 20 audios.',
        icon: 'warning',
        confirmButtonColor: '#2CABBC',
        confirmButtonText: 'Entendido'
      });
    }else {
      Swal.fire({
        title: `Se consultará ${this.totalFilesUploaded} audios`,
        text: '¿Está seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2CABBC',
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          const filesArray: File[] = this.files.map((fileItem) => fileItem.file);
          this.noDirigidoService.uploadAudios(filesArray, this.user.rrhh_id).subscribe(
            (respUpload) => {
              stepperReference.next();
              this.respload = respUpload;
              this.dataSourceStrategy = new MatTableDataSource<any>();  
              this.processSearchResponse(this.respload);
              this.selectionRecordings.clear();
            },
            (error) => {
              console.error('Error en la solicitud:', error);
              Swal.fire({
                icon: 'error',
                title: 'Error en la solicitud:',
                text: error.error.error.error
              });
            }
          );
        }
      });
    }
  }

  /**
  * Metodo obtener los audios cargados
  * @author Yeison Sepulveda
  * @createdate 2024-05-08
  */

  processSearchResponse(respSearch): void {
    this.noDirigidoService.getAudios(this.configPaginatorRecording, respSearch).subscribe(
      (respSearch) => {
  
        respSearch.data.data = respSearch.data.data.map((recording) => {
          const otherInfo = JSON.parse(recording.other_information);
          if (otherInfo && otherInfo.user_soul) {
            return { ...recording, user_soul: otherInfo.user_soul };
          }
          return recording;
        });
  
        this.dataSourceRecordings.data = respSearch.data.data.map((recording) => {
          const fecha = new Date(recording.created_at);
          const year = fecha.getFullYear();
          const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
          const day = fecha.getDate().toString().padStart(2, '0');
          return { ...recording, created_at: `${year}-${month}-${day}` };
        });
  
        this.listrecordingsAnalising = this.dataSourceRecordings.data;
        this.dataSourceRecordings.sort = this.sort;
        this.configPaginatorRecording.length = respSearch.data.total;
      },
      (error) => {
        console.error('Error en la solicitud:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error en la solicitud',
          text: error.error.error.error
        });
      }
    );
  }


  /**
   * 
   * Método para seleccionar todos los elementos en la tabla de analsis
    * @author Juan David Guerrero Vargas
    * @update Yeison Sepulveda
  */
  selectAllRecordings(): void {
    this.isAllSelectedRecordings() ?
      this.selectionRecordings.clear() :
      this.dataSourceRecordings.data.forEach(row => {
        if (row.analisis !== 'ERROR' && row.analisis !== 'PENDIENTE') {
          this.selectionRecordings.select(row);
        }
      });
  }

  /**
   * Método para seleccionar todos los elementos en la página actual
   * @author Juan David Guerrero Vargas
   */
  selectAllStrategy(): void {
    this.isAllSelectedStrategy() ?
          this.selectionStrategy.clear() :
          this.dataSourceStrategy.data.forEach(row => this.selectionStrategy.select(row));
  }

  /**
   * Metodo para reiniciar tabla eliminando colimna de analisis
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
  */

  deleteColumnAnalytics(): void {
    const indexConfigAudio = this.displayedColumnsRecordings.findIndex(column => column === 'analisis');
    if (indexConfigAudio !== -1) {
      this.displayedColumnsRecordings.splice(indexConfigAudio, 1);
    }
  }

  /**
   * Metodo para reiniciar tabla eliminando columna adherencia
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
  */

  deleteColumnAdherencia(): void {
    const indexConfigAudio = this.displayedColumnsStrategy.findIndex(column => column === 'adherencia');
    if (indexConfigAudio !== -1) {
      this.displayedColumnsStrategy.splice(indexConfigAudio, 1);
    }
  }

  /**
   * Método que determinao si los checbox estan seleccionados o no en la tabal de analisis
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
  */
  isAllSelectedRecordings(){
    const numSelected = this.selectionRecordings.selected.length;
    const numRows = this.dataSourceRecordings.data.length;
    return numSelected === numRows;
  }

  /**
   * Método que determinao si los checbox estan seleccionados o no en la tabla estrategia
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
  */


  isAllSelectedStrategy(){
    const numSelected = this.selectionStrategy.selected.length;
    const numRows = this.dataSourceStrategy.data.length;
    return numSelected === numRows;
  }

  /**
   * Metodo que se encarg de realziar paginacion estatica apra tablas 
   * @author Juan David Guerrero Vargas
   * @returns {any[]} any[]
   */
  paginateStaticTable(dataTable:any[], configPaginator:any):any[]{
    let  startIndex = (configPaginator.pageIndex - 1 )  * configPaginator.pageSize
    return dataTable.slice( startIndex, startIndex +  configPaginator.pageSize  )
  }

  /**
    * Método que pagina la informacion de la tabla.x
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * @param $event Evento emitido por el paginador.
  */
  changePage($event: PageEvent, tablePaginate:string): void {
    if (tablePaginate == 'analisis' && this.displayedColumnsRecordings.find(ffnn => ffnn == 'analisis') == undefined){
        this.configPaginatorRecording.pageSize  = $event.pageSize;
        this.configPaginatorRecording.pageIndex  = $event.pageIndex + 1;
        this.processSearchResponse(this.respload)
      }else if (tablePaginate == 'strategy' && this.displayedColumnsRecordings.find(ffnn => ffnn == 'analisis') !== undefined){
        this.configPaginatorStrategy.pageSize  = $event.pageSize;
        this.configPaginatorStrategy.pageIndex  = $event.pageIndex + 1;
        this.dataSourceStrategy.data = this.paginateStaticTable(this.listRecordingsStrategys, this.configPaginatorStrategy )
      }
  }

  /**
   * Método para abrir el modal de analisis
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  openAnalyticsModal(recording_id: any): void {
    const dialogRef = this.dialog.open(SpeechToTextComponent, {
      width: '800px',
      data: { recordingId: recording_id } 
    });
  }

  /**
    * Metodo para abrir el modal reproductor audio
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
  */
  openAudioModal(recording_id: any): void {
    const dialogRef = this.dialog.open(AudioPlayerComponent, {
      width: '700px',
      data: { recordingId: recording_id } 
    });
  }



  /**
   * Método que se encarga de retonar la cantidad de items seleccioandos en la tabal de Analsis
   * @author Juan David Guerrero Vargas
   * @returns {number} Cantidad de items seleccionados
   */
  get getTotalItemSelectedRecordings():number{
    if(this.selectionRecordings && this.selectionRecordings?.selected){
      return this.selectionRecordings?.selected.length
    }
    return  0
  }

  /**
   * Método para analizar
    * @author Yeison Sepulveda
    * @update Juan David Guerrero Vargas
    * @createdate 2024-02-15
  */
  analytics(): void {
    if (this.getTotalItemSelectedRecordings === 0) {
      Swal.fire({
            icon: 'warning',
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para analizar.'
        });
        return;
    }
    Swal.fire({
      title: '¿Estás seguro?',
      text: `Se iniciará el análisis de ${this.getTotalItemSelectedRecordings} grabaciones.`,
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.requestService.newRequest(this.selectionRecordings.selected).subscribe(response => {
          this.dataSourceRecordings.data =  this.selectionRecordings.selected;
          this.configPaginatorRecording.length = this.dataSourceRecordings.data.length 
          if(this.displayedColumnsRecordings.find( ffnn => ffnn == 'analisis' ) == undefined ){
            this.displayedColumnsRecordings.push('analisis');
          }
          this.obtenerEstadosRegistros();
        }, error => {
          console.error('Error al enviar la solicitud:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error en la solicitud',
            text: error.error.error.error
          });
        });
      }
    });
  }

  /**
   * Metodo que se encarga de obtener la cantidad de items que ya fueron analizados
   * @author Juan David Guerrero Vargas
   * @returns {number} number cantidad de items que ya estan analizados
   */
  get getTotalItemsAnalized():number{
    let totalRecordingsAnalized = this.selectionRecordings?.selected.filter( ffll =>  ffll?.analisis !== undefined &&  (ffll?.analisis == 'COMPLETADO' ||  ffll?.analisis == 'ERROR') ).length
    if( totalRecordingsAnalized > 0 ){
      return totalRecordingsAnalized;
    }
    return 0
  }

  /**
   * Metodo que se encarga de obtener el total de recordings que se seleccionaron pra aplciar la estrategia
   * @returns {number} number
   */
  get getTotalRecordingsForStrategy():number{
    if(this.selectionStrategy && this.selectionStrategy.selected.length > 0 ){
      return this.selectionStrategy.selected.length;
    }
    return 0
  }
  

  /**
   * Metodo que se encarga de ir validando si ya se encuentran descargados los analisis de las grabaciones
    * @author Yeison Sepulveda
    * @createdate 2024-03-07
    * Validar el estado de los análisis
  */
  async obtenerEstadosRegistros() {
    let registrosPendientes: any[] = this.dataSourceRecordings.data.filter(ffll => ffll?.analisis !== 'COMPLETADO' && ffll?.analisis !== 'ERROR');
    const selectedRecords = this.selectionRecordings.selected;
    this.selectionRecordings.clear();
  
    while (registrosPendientes.length > 0) {
        const response: any = await this.viewservice.estado(selectedRecords).toPromise();
  
        if (response.data) {
            response.data.forEach((item: any) => {
                const registros = this.dataSourceRecordings.data.filter(record => record.recording_id === item.recording_id);
                registros.forEach(registro => {
                    registro.analisis = item.status.toUpperCase(); 
                });
            });
        }
  
        this.dataSourceRecordings.data = this.dataSourceRecordings.data.map(record => {
            const registroModificado = registrosPendientes.find(registro => registro.recording_id === record.recording_id);
            return registroModificado ? registroModificado : record;
        });
  
        registrosPendientes = this.dataSourceRecordings.data.filter(ffll => ffll?.analisis !== 'COMPLETADO' && ffll?.analisis !== 'ERROR');
  
        await new Promise(resolve => setTimeout(resolve, 30000)); // Esperar 30 segundos antes de la siguiente iteración
    }
  }

  /**
   * Metodo que se encarga de redirigir al metodo de aplicar estrategias
   * @author Yeison Sepulveda
   * @createdate 2024-05-08
   * @param stepperReference:any {any} Referencia del steper para realizar la accion de continuar el paso siguiente
   */
  applyStrategy(stepperReference?:any):void{
    this.dataSourceStrategy.data = this.selectionRecordings.selected;
    this.configPaginatorStrategy.length = this.selectionRecordings.selected.length;
    this.listRecordingsStrategys = this.selectionRecordings.selected;
    this.deleteColumnAdherencia();
    if(stepperReference !== undefined){
      stepperReference.next();
      this.selectionStrategy.clear();
    }
  }


  /**
   * Metodo que se encarga de abirir el modal apra poder abrir el modal de creacion de estrategias
   * @author Juan David Guerrero Vargas
   */
  openModalCreateStrategy():void{
    const dialogRef = this.dialog.open(StrategyModalComponent, {
      width: '50%',
      maxHeight: '95vh',
      data: { modo: 'crear' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.getStrategys();
    });
  }

  /**
   * Metodo que se encarga de enviar la informacion apra aplciar la estrategia seleccionada
   * @author Yesion Sepulveda
   * @Update 15-05-2024
  */

  applyStrategySelected(): void {
    this.deleteColumnAdherencia();
    if (this.getTotalRecordingsForStrategy <= 0) {
        Swal.fire({
            icon: 'warning',
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para aplicar la estrategia.'
        });
        return;
    }

    let strategyForms = {
      id_recordings: this.selectionStrategy.selected.map(recording => recording['recording_id']),
      id_strategy: this.strategyControl.value
    }
  
    this.strategyService.assignStrategys(strategyForms).subscribe(
      (assignResponse: any) => {
          console.log("Respuesta de asignación de estrategia:", assignResponse);
  
          this.strategyService.applyAdherencia(strategyForms.id_recordings).subscribe(
            (adherenciaResponse: any[]) => {
  
                if (this.displayedColumnsStrategy.indexOf('adherencia') === -1) {
                    this.displayedColumnsStrategy.push('adherencia');
                }
  
                adherenciaResponse.forEach((item) => {
                  const adherencia = `${item.adherence_percentage} / ${item.not_adherence_percentage}`;
                  const item_recording_id= item.recording_id
                  const matchingRecords = this.dataSourceStrategy.data.filter((record) => record.recording_id === item_recording_id);
                  
                  // Si hay registros coincidentes, asigna la adherencia a dataSourceStrategy.data
                  if (matchingRecords.length > 0) {
                    this.dataSourceStrategy.data.forEach((record) => {
                      if (record.recording_id === item_recording_id) {
                        record.adherencia = adherencia;
                      }
                    });
                  }
                });

                this.dataSourceStrategy.data = [...this.dataSourceStrategy.data];
                this.configPaginatorStrategy.length = this.dataSourceStrategy.data.length;
  
                console.log("Respuesta de asignación de estrategia:", assignResponse);
              },
              (adherenciaError: any) => {
                  console.error("Error al calcular la adherencia:", adherenciaError.error);
              }
          );
      },
      (assignError: any) => {
          console.error("Error al asignar la estrategia:", assignError);
      }
  );
        
  }



  /**
   * Metodo que se encarga de obtenre el listado de estrategias
   * @author Yeison Sepulveda
	 * @createdate 2024-04-23
  */
  getStrategys():void{
    this.strategyService.listStrategies().subscribe(res => {
        this.listStrategys = res.data;
    }); 
  }

  /**
	 * Método enviar mensaje swal de error de la grabación
	 * @author Yeison Sepulveda
	 * @createdate 2024-04-23
	 */

  errorAnalyticModal() {
    Swal.fire({
      icon: 'warning',
      title: '¡Aviso!',
      text: 'Si la transcripción no está disponible, por favor verifica el audio, podría carecer de voz o ser solo tonos. ¡Gracias por tu atención!',
      confirmButtonText: 'Entendido',
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
    });
  }

  /**
    * Método para abrir el modal de resultados de adherencia
    * @author Yeison Sepulveda
    * @createdate 2024-04-30
  */
  AdherenciaModal(recording_id: any): void {
    const dialogRef = this.dialog.open(AdherenciaModalComponent, {
      width: '800px',
      data: { recordingId: recording_id } 
    });
  }
}