import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adherencia',
  templateUrl: './adherencia.component.html',
  styleUrls: ['./adherencia.component.sass']
})
export class AdherenciaComponent implements OnInit {

  opciones: string[] = ['Claro', 'Ventas', 'Operación'];
  seleccion: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  analyzeCases() {
    Swal.fire({
      title: 'Se aplicará la estrategia',
      text: '¿Está seguro?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }

  



}
