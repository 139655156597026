<div ></div>
<header class="header-landing" >
    <div class="container h-100 d-flex align-items-end justify-content-center ">
        <div class="row  " >
            <div class="col-2 d-flex justify-content-center" >
                <img class="logo" src="../../../assets/images/Icon-Soul.svg">
            </div>
            <div class="col-8 menu d-flex justify-content-center align-items-end" >
                <div >
                    <ul >
                        <li> <a href="" > Inicio </a> </li>
                        <li> <a href="" >Análisis de llamadas</a> </li>
                        <li><a href="" >Gestión WhatsApp</a></li>
                    </ul>
                </div>

               
            </div>
            <div class="col-2 d-flex justify-content-center menu align-items-end">
                <div >
                    <ul>
                        <li><a  href="" >Ingresar &nbsp; <i class="fi fi-rr-sign-in"></i> </a></li>
                    </ul>
                </div>
               
                
            </div>
        </div>
    </div>
    
</header>
<section id="inicio" ></section>
<section id="analisis"></section>
<section id="gestion whatsapp" ></section>
<section id="reportin" ></section>