import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../core/services/rest/auth.service';
import { StorageService } from '../../core/services/storage/storage.service';
import { DestroyComponentService } from '../../core/services/utils/destroy-component.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ChangePasswordComponent } from 'src/app/auth/change-password/change-password.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { DobbleAuthComponent } from '../dobble-auth/dobble-auth.component';
import { MatDialog } from '@angular/material/dialog';
import { CoachingService } from 'src/app/modules/coaching/services/coaching.service';
import { ForgotUserComponent } from '../forgot-user/forgot-user.component';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { SurveyService } from 'src/app/modules/encuestas/services/suervey.service';
import { ModalSurveysUserComponent } from 'src/app/mios/modal-surveys-user/modal-surveys-user.component';
import { DialogStateService } from 'src/app/core/services/utils/DialogSurveysState.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit, OnDestroy {
	loginForm: FormGroup;
	hide: boolean = true;
	auth: boolean;
	anio: number;
	publicIP: any;
	fingerPrintEnvio: any;
	private readonly urlNextToLogin:string = '/mios/ciu'

	constructor(
		private authService: AuthService,
		private storageService: StorageService,
		private destroyService: DestroyComponentService,
		private router: Router,
		public dialog: MatDialog,
		private coachService: CoachingService,
		private alertsService: AlertsService,
		private surveyService: SurveyService,

	) { }

	ngOnInit(): void {
		const alert = localStorage.getItem('alert');
		if (alert === 'true') {
			this.alertsService.alertSuccessWithoutButton('¡Excelente!', 'Se ha cerrado tu sesión de manera satisfactoria, recuerda que \n puedes ingresar nuevamente al aplicativo en cualquier momento');
			localStorage.clear();
		}

		const endTurn = localStorage.getItem('endturn');
		if (endTurn === 'true') {
			this.alertsService.alertSuccessWithoutButton('¡Excelente!', 'Ha finalizado el turno de manera satisfactoria, ten en cuenta que \n no podrás acceder al aplicativo nuevamente por el día de hoy. \n \n ' + '<b>¡Que tengas un excelente resto de día! </b>');
			localStorage.clear();
		}


		this.anio = new Date().getFullYear();
		this.formControl();
		this.fingerPrint();
	}

	fingerPrint(): any {
		let sessionStorage = !!window.sessionStorage;
		let localStorage = !!window.localStorage;

		var fingerprint = (function (window, screen, navigator) {
			function checksum(str) {
				var hash = 5381,
					i = str.length;
				while (i--) hash = (hash * 33) ^ str.charCodeAt(i);
				return hash >>> 0;
			}

			function map(arr, fn) {
				var i = 0,
					len = arr.length,
					ret = [];
				while (i < len) {
					ret[i] = fn(arr[i++]);
				}
				return ret;
			}

			return checksum(
				[
					navigator.userAgent,
					navigator.geolocation.getCurrentPosition,
					[screen.height, screen.width, screen.colorDepth].join('x'),
					new Date().getTimezoneOffset(),
					sessionStorage,
					localStorage,
					map(navigator.plugins, function (plugin) {
						return [
							plugin.name,
							plugin.description,
							map(plugin, function (mime) {
								return [mime.type, mime.suffixes].join('~');
							}).join(',')
						].join('::');
					}).join(';')
				].join('###')
			);
		})(this, screen, navigator);

		this.fingerPrintEnvio = fingerprint;
	}

	/**
	 * @author Daniel Martinez
	 * @createdate 2021-01-27
	 * Metodo donde se establecen las validaciones del formulario del login
	 */
	formControl(): void {
		this.loginForm = new FormGroup({
			user: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(3)]),
			password: new FormControl('', [Validators.required, Validators.maxLength(15), Validators.minLength(5)])
		});
	}

	get error(): any {
		return this.loginForm.controls;
	}

	/**
	 * @author Daniel Martinez
	 * @createdate 2021-01-27
	 * Metodo que loguea al usuario y guarda sus datos en el local storage
	 * @param loginForm formulario del login
	 */
	async loginAuth(loginForm) {
		const user = new FormData();
		user.append('username', loginForm.value.user);
		user.append('password', loginForm.value.password);
		user.append('fingerprint', this.fingerPrintEnvio);
		localStorage.clear();
		this.authService.validateExternalUser(loginForm.value.user).subscribe(res =>{
			if (res.data.auth) {
				localStorage.setItem('view_module', res.data.data.view_module);
				this.authService.Authentification(user).subscribe(async (res) => {
					/** Metodo asyncrono que hace la doble validacion del usuario*/
					if (res.data.dob_auth === 1) {
						this.dobbleAuthModal(res.data.id, res.data.email, res.data.phone_number).then(
							async dialogDobbleAuth => {
								await dialogDobbleAuth.afterClosed().subscribe(async valid => {
									if (valid === true) {
										this.dobbleAuthLogin(res, loginForm);
									} else {
										dialogDobbleAuth.close();
										Swal.fire({
											html: `Error al autenticar, vuelve a intentarlo`,
											icon: 'error',
											confirmButtonText: 'Aceptar'
										})
									}
								});
							}
						);
					} else {
						/** Metodo syncrono que hace la validacion del usuario*/
						if (res.data.actualizar === true || res.data.reason == 'pass-update') {
							Swal.fire({
								html: `Por seguridad debes cambiar tu contraseña`,
								icon: 'warning',
								confirmButtonText: 'Aceptar'
							}).then((result) => {
								if (result.isConfirmed === true) {
									const dialogPassword = this.dialog.open(ChangePasswordComponent, {
										width: '100%',
										height: '80%',
										disableClose: true,
										data: {
											type: 'cambiar',
											title: 'Cambiar contraseña',
											userName: loginForm.value.user
										},
										panelClass: 'custom-dialog-container'
									});
		
					  				dialogPassword.afterClosed().subscribe(() => {});
								}
							});
					} else {
						this.storageService.setStorage('user', res.data);
						await this.getSurverysByUser();
						this.sendCoachNoti();
						
						this.router.navigate([this.urlNextToLogin]);
					}
				}
				});
			}else {
				Swal.fire({ title: '¡Error!', text: 'El usuario no tiene permiso para una autenticación externa', icon: 'warning' });
			}
		})

	}

	/**
	   * @author Carlos Nieto
	   * @createdate 2022-02-02
	   * Metodo para la doble autenticacion por correo o por email
	   * @param rrhh_id id de recursos humanos del usuario
	 * @param email email de recursos humanos del usuario
	 * @param phoneNumber phoneNumber de recursos humanos del usuario
	   */
	async dobbleAuthModal(rrhh_id, email, phoneNumber) {
		const dialogDobbleAuth = this.dialog.open(DobbleAuthComponent, {
			width: '72vh',
			height: '80vh',
			disableClose: true,
			data: {
				type: 'Validar',
				title: 'Validar Acceso',
				user_id: rrhh_id,
				email: email,
				phoneNumber: phoneNumber
			},
			panelClass: 'custom-dialog-container'
		});

		return dialogDobbleAuth;
	}

	/**
	   * @author Carlos Nieto
	   * @createdate 2022-02-02
	   * Metodo para la doble autenticacion por correo o por email
	   * @param res respuesta del auth del usuario
	 * @param loginForm formulario
	   */
	async dobbleAuthLogin(res, loginForm) {
		if (res.data.actualizar === true || res.data.reason == 'pass-update') {
			Swal.fire({
				html: `Por seguridad debes cambiar tu contraseña`,
				icon: 'warning',
				confirmButtonText: 'Aceptar'
			}).then((result) => {
				if (result.isConfirmed === true) {
					const dialogPassword = this.dialog.open(ChangePasswordComponent, {
						width: '100%',
						height: '80%',
						disableClose: true,
						data: {
							type: 'cambiar',
							title: 'Cambiar contraseña',
							userName: loginForm.value.user
						},
						panelClass: 'custom-dialog-container'
					});

          dialogPassword.afterClosed().subscribe(() => {});
        }
      });
    } else {
      this.storageService.setStorage('user', res.data);
	  await this.getSurverysByUser();
      this.sendCoachNoti();
      this.router.navigate([this.urlNextToLogin]);
    }
  }


	/**
	 * @author Daniel Martinez
	 * @createdate 2021-01-27
	 * Metodo que evalua en tiempo real los caracteres ingresados en el input, no deja ingresar numeros ni espacios
	 * @param event evento del keypress del input usuario
	 */
	omitSpecialChar(event): any {
		let k;
		k = event.charCode;
		return k > 32 && k < 126;
	}

	/**
	 * @author Cristian Gallo
	 * @createdate 2022-01-26
	 * Metodo que dispara el envio de notificaciones para coaching.
	 */

	sendCoachNoti() {
		let roles = this.authService.getUser().roles;
		roles.forEach(rol => {
			if (rol == 'coaching::supervisor' || (rol == 'coaching::jefe' || rol == 'coaching::gerente')) {
				this.coachService.senNotificationSupervisor().subscribe(() => { });
			}
		});
	}

	/**
	* @author Carlos Nieto
	* @createdate 2021-12-07
	* Metodo que abre el modal del componente de forgot-password
	*/
	forgotPassword(): any {
		const forgotPassword = this.dialog.open(ForgotPasswordComponent, {
			width: '40%',
			height: '91%',
			data: {
				type: 'cambiar',
				title: '¿Tienes problemas para iniciar sesión?',
			},
			panelClass: 'custom-dialog-container'
		});

		forgotPassword.afterClosed().subscribe(() => {
			forgotPassword.close();
		});

	}

	/**
	 * @author Carlos Nieto
	 * @createdate 2022-02-14
	 * Metodo que abre el modal del componente de forgot-user
	 */
	forgotUser(): any {
		const forgotPassword = this.dialog.open(ForgotUserComponent, {
			width: '40%',
			height: '85%',
			data: {
				type: 'cambiar',
				title: '¿Tienes problemas para iniciar sesión?',
			},
			panelClass: 'custom-dialog-container'
		});

		forgotPassword.afterClosed().subscribe(() => {
			forgotPassword.close();
		});
	}

	ngOnDestroy(): void {
		this.destroyService.destroyComponent();
	}

	async getSurverysByUser(){
		await this.surveyService.getSurveysUser().toPromise().then(
			(surveyResponse:any)=>{
				if (surveyResponse?.surveys !== undefined && Array.isArray(surveyResponse.surveys) == true && surveyResponse.surveys.length > 0){
					let surveyList = surveyResponse.surveys.map( (survey)=>{
						survey['is_skiped'] = false
						return survey
					});
					this.storageService.setStorage('surveys', surveyList);
				}
		  }).catch(
			(error:any)=>{
				this.storageService.setStorage('surveysError',error);
			}
		  );
	}
}
