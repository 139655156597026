import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StrategyModalComponent } from '../strategy-modal/strategy-modal.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StrategyServersService } from '../../../services/strategy.service';

@Component({
  selector: 'app-strategy-menu',
  templateUrl: './strategy-menu.component.html',
  styleUrls: ['./strategy-menu.component.sass']
})
export class StrategyMenuComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  configPaginator: any = { length: 0, pageSize: 5, pageIndex: 1 };
  pagesizeOptions: number[] = [5, 10, 20, 50, 100]

  keysColumnsTable: string[] = [];
  displayedColumns: string[] = ['actions', 'id', 'date', 'user_id', 'strategy_name' ];


  dataSource = new MatTableDataSource<any>(); 
  @ViewChild(MatSort) sort: MatSort;

  //firltro estrategia
  textInputFilter: string = '';
  filter: string = ''; 

  ngOnInit(): void {
    this.getStrategy();
    this.keysColumnsTable = this.configColumnsTable.map((item: any) => item.key);
  }


  constructor(
    private dialog:MatDialog,
    private strategyService: StrategyServersService
  ) { }


  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Método que pagina la informacion de la tabla.
    * @param $event Evento emitido por el paginador.
  */
  changePage($event: PageEvent): void {
    this.configPaginator.pageSize  = $event.pageSize;
    this.configPaginator.pageIndex  = $event.pageIndex + 1;
    this.getStrategy();
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Configuración para visualizar la informacion en la tabla
  */

  configColumnsTable: any[] = [
    { key: 'actions', name: 'Acciones' },
    { key: 'id', name: 'Id' },
    { key: 'date', name: 'Fecha creación' },
    { key: 'user_name', name: 'Usuario SOUL' },
    { key: 'strategy_name', name: 'Nombre estrategia' },
  ]; 

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Método lista las estartegias creadas
  */

  getStrategy(): void {
    this.strategyService.paginateStrategies(this.filter, this.configPaginator).subscribe(res => {
      this.dataSource.data = res.data.data;
          this.dataSource.sort = this.sort;
          this.configPaginator.length = res.data.total;
      }); 
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Método para modal editar
  */

  editStrategy(id: number): void {
    const dialogRef = this.dialog.open(StrategyModalComponent, {
      width: '50%',
      maxHeight: '95vh',
      data: { id: id, modo: 'editar' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.getStrategy();
    });
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
    * Abrir modal crear estrategia
  */

  createdStrategy(): void{
    const dialogRef = this.dialog.open(StrategyModalComponent, {
      width: '50%',
      maxHeight: '95vh',
      data: { modo: 'crear' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.getStrategy();
    });
  }
  
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
    * Abrir modal de estrategia
  */
  
  viewModal(id: number): void {
    const dialogRef = this.dialog.open(StrategyModalComponent, {
      width: '50%',
      maxHeight: '95vh',
      data: { id: id, modo: 'visualizar' }
    });
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Método eliminar registro
  */

  deleteStrategy(id: number): void {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Estás seguro de eliminar la estrategia seleccionada?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.strategyService.desactiveStrategy({ id: id }).subscribe(
          response => {
            this.getStrategy();
            Swal.fire({
              title: '¡Excelente!',
              text: 'Se ha eliminado la estrategia',
              icon: 'success',
              timer: 3000, 
              showConfirmButton: false 
            });
          },
          error => {
            console.error('Error al desactivar la estrategia', error);
          }
        );
      }
    });
  }

  /**
   * Metodo que filtra los datos de la tabla.
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @param $event Evento emitido por el input.
  */

  onSearchClick(value: string): void {
    this.filter = value;
    this.configPaginator.pageIndex= 1;
    this.paginator.firstPage();
    this.getStrategy();
  }
  
}
