import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminServersService } from 'src/app/modules/speech-analytics/services/admin-servers.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-connection',
  templateUrl: './edit-connection.component.html',
  styleUrls: ['./edit-connection.component.sass']
})
export class EditConnectionComponent implements OnInit {

  editConnectionForm: FormGroup;
  dataSource: any;
  hide: boolean = true;
  type_call: any[] = [ {type_call:'Inbound'}, {type_call:'Outbound'} ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<EditConnectionComponent>,
    private form: FormBuilder,
    private adminServersService: AdminServersService,
    private alertService: AlertsService,
  ) {}

  ngOnInit(): void {
    this.formControl();
    this.viewServer(this.data);
  }

      /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Fomulario
  */

  formControl(): void {
    this.editConnectionForm = this.form.group({
      id: ['', Validators.required],
      name: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]),
      server_host: new FormControl('', [Validators.required,Validators.minLength(5), Validators.maxLength(16)]),
      port: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]+$/),Validators.minLength(1), Validators.maxLength(5)]),
      type_connection: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]),
      database_name: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
      view: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
      user: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
      password: new FormControl('', [Validators.required,Validators.minLength(5), Validators.maxLength(50)]),
      web_server: new FormControl('', [Validators.required,Validators.minLength(1)]),
      type_call: new FormControl('', [Validators.required,Validators.minLength(1)]),
    });
  }


    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * cargar la data del elemento correspondiente
  */
  viewServer(server: any): void {
    this.adminServersService.showServer(server.id).subscribe(res => {
      this.dataSource = res.data;
      console.log('Datos del servidor:', this.dataSource);
      this.editConnectionForm.patchValue({
        id: res.data.id,
        name: res.data.name,
        server_host: res.data.server_host,
        port: res.data.port,
        type_connection: res.data.type_connection,
        database_name: res.data.database_name,
        view: res.data.view,
        user: res.data.user,
        password: res.data.password,
        web_server: res.data.web_server,
        type_call: res.data.type_call
      });
    });
  }

    /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que se encarga de omitir algunos caracteres en el campo de contraseña
   * @param event valor a evaluar
   * @returns boolean
   */
  omitSpecialChar(event): any {
    let k;
    k = event.charCode;
    return ((k > 32 && k < 126));
  }


      /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Metodo que guarda o actualiza un servidor. 
  */
      saveServe() {
        if (this.editConnectionForm.valid) {
          Swal.fire({
            title: '¿Está seguro?',
            text: '¿En verdad deseas continuar con la gestión?',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: '#2CABBC',
            cancelButtonColor: '#FFFFFF',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              this.adminServersService.UpdateServer(this.editConnectionForm.value).subscribe(
                res => {
                  Swal.fire({
                    title: 'Se ha actualizado el registro',
                    text: res.data,
                    icon: 'success',
                    confirmButtonColor: '#2CABBC',
                    confirmButtonText: 'Cerrar' 
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.dialogRef.close();
                    }
                  });
                },
                error => {
                  Swal.fire('Error', 'Ha ocurrido un error al crear el servidor.', 'error');
                }
              );
            }
          });
        } else {
          this.editConnectionForm.markAllAsTouched();
        }
      }


    /**
      * @author Yeison Sepulveda
      * @createdate 2024-02-15
      * Metodo alerta cancelar gestion
    */
    cancelSave(): void {
      Swal.fire({
        title: '¿Estás seguro?',
        text: '¿En verdad deseas cancelar la gestión?',
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.editConnectionForm.reset();
          this.dialogRef.close();
        }
      });
    }
}