import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  constructor(private httpClient: HttpClient) { }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo que retorna los servidores registrados en el sistema.  
  */
    getResults(paginator: any, filter: string): Observable<any> {
      return this.httpClient.get<any>(`${SPEECH_URL}result?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&filter=${filter}`).pipe(
        catchError(this.handleError)
      );
    }
  
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo que retorna el servidor seleccionado por ID.  
  */
  showResults(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}result/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo que crea o actualiza el servidor seleccionado. 
    * @param result Informacion proveniente del servidor. 
  */
  UpdateResults(result: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}result/${result.id}`, result).pipe(
      catchError(this.handleError)
    );
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo que crea o actualiza el servidor seleccionado. 
    * @param result Informacion proveniente del servidor. 
  */
    CreaResults(result: any): Observable<any> {
      return this.httpClient.post<any>(`${SPEECH_URL}result/`, result).pipe(
        catchError(this.handleError)
      );
    }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-19
    * Metodo que elimina un servidor del sistema. 
    * @param id Id del servidor seleccionado. 
  */
  deleteResults(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${SPEECH_URL}result/${id}`).pipe(
      catchError(this.handleError)
    );
  }

}