import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestService } from '../../services/request.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.sass']
})
export class AudioPlayerComponent implements OnInit {

  audioUrl
  recordingID

  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private requestService: RequestService) {
    this.recordingID=data.recordingId;
}

  ngOnInit(): void {
    this.audioUrl = this.requestService.getAudioUrl(this.recordingID);
  }

  @ViewChild('audioPlayer') audioPlayer: ElementRef<HTMLAudioElement>;

  start: number=0;
  end: number=0;
  audioEventListener: any;


  /**
  * @author Yeison Sepulveda
  * @createdate 2024-03-07
  * @description Método para registrar y establecer los tiempos de inicio y fin de la reproducción de audio.
  * @param start Tiempo de inicio de la reproducción.
  * @param end Tiempo de fin de la reproducción.
  */

  logStartAndEnd(start: number, end: number): void {
    console.log('Start:', start);
    console.log('End:', end);
  
    this.start = start;
    this.end = end;
  
    if (this.audioPlayer) {
      this.audioPlayer.nativeElement.currentTime = this.start;
      this.audioPlayer.nativeElement.play();
  
      const timeUpdateListener = () => {
        if (this.audioPlayer.nativeElement.currentTime >= this.end) {
          this.audioPlayer.nativeElement.pause();

          //Despues de pausar crea un nuevo objeto resetea el tiempo limite al total del audio
          const audio = new Audio(this.audioUrl);
          audio.addEventListener('loadedmetadata', () => {
            const durationInSeconds = audio.duration;
            this.end=durationInSeconds;
})
        }
      };
  
      this.audioPlayer.nativeElement.addEventListener('timeupdate', timeUpdateListener);
    } else {
      console.error('audioPlayer is undefined');
    }
  }

  /**
  * @author Yeison Sepulveda
  * @createdate 2024-03-07
  * @description Método para retroceder 10 segundos en la reproducción de audio.
  */
  skipBackward(): void {
    if (this.audioPlayer) {
      this.audioPlayer.nativeElement.currentTime -= 10;
    }
  }

  /**
  * @author Yeison Sepulveda
  * @createdate 2024-03-07
  * @description Método para pausar o reproducir el audio.
  */
  togglePlay(): void {
    if (this.audioPlayer) {
      if (this.audioPlayer.nativeElement.paused) {
        this.audioPlayer.nativeElement.play();
      } else {
        this.audioPlayer.nativeElement.pause();
      }
    }
  }

  /**
  * @author Yeison Sepulveda
  * @createdate 2024-03-07
  * @description Método para avanzar 10 segundos en la reproducción de audio.
 */
  skipForward(): void {
    if (this.audioPlayer) {
      this.audioPlayer.nativeElement.currentTime += 10;
    }
  }

}
