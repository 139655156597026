import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeechAnalyticsRoutingModule } from './speech-analytics-routing.module';
import { SpeechAnalyticsComponent } from './speech-analytics.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { SpeechToTextComponent } from './modules/speech-to-text/speech-to-text.component';
import { SharedModule } from "../../shared/shared.module";
import { ConnectionsComponent } from './modules/configuration-administrator/connection/connections/connections.component';
import { StrategyMenuComponent } from './modules/strategy/strategy-menu/strategy-menu.component';
import { MatSortModule } from '@angular/material/sort';
import { AdherenciaModalComponent } from './modules/adherencia-modal/adherencia-modal.component';
import { AudioPlayerComponent } from './modules/audio-player/audio-player.component';
import { AdherenciaComponent } from './modules/strategy/adherencia/adherencia.component';
import { AdminComponent } from './modules/configuration-administrator/admin/admin.component';
import { ConnectsComponent } from './modules/configuration-administrator/connection/connects/connects.component';
import { ConfigCampainComponent } from './modules/configuration-administrator/campaign/config-campain/config-campain.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { StrategyModalComponent } from './modules/strategy/strategy-modal/strategy-modal.component';
import { EditConnectionComponent } from './modules/configuration-administrator/connection/edit-connection/edit-connection.component';
import { EditCampaignComponent } from './modules/configuration-administrator/campaign/edit-campaign/edit-campaign.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NodirigidoComponent } from './modules/nodirigido/nodirigido.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DirigidoComponent } from './modules/search-speech/directed-search/dirigido/dirigido.component';
import { ModuleComponent } from './modules/search-speech/module-search/module/module.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SpeechmenuComponent } from './modules/search-speech/speech-menu/speech-menu.component';
import { CampaignModalComponent } from './modules/configuration-administrator/campaign/campaign-modal/campaign-modal.component';
import { AssignCampaignComponent } from './modules/configuration-administrator/assign-campaign/assign-campaign.component';
import { DndDirective } from './modules/nodirigido/direcitves/dnd.directive';


@NgModule({
    declarations: [
        SpeechAnalyticsComponent,
        SpeechToTextComponent,
        ConnectionsComponent,
        CampaignModalComponent,
        StrategyMenuComponent,
        AdherenciaModalComponent,
        StrategyModalComponent,
        AudioPlayerComponent,
        AdherenciaComponent,
        AdminComponent,
        ConnectsComponent,
        ConfigCampainComponent,
        EditConnectionComponent,
        EditCampaignComponent,
        NodirigidoComponent,
        DirigidoComponent,
        ModuleComponent,
        SpeechmenuComponent,
        AssignCampaignComponent,
        DndDirective
    ],
    imports: [
        CommonModule,
        SpeechAnalyticsRoutingModule,
        MaterialModule,
        MatDialogModule,
        SharedModule,
        MatSortModule,
        MatExpansionModule,
        FormsModule,
        NgxChartsModule,
        MatTabsModule,
        ReactiveFormsModule 
    ],
    exports: [
        MatDialogModule
    ]
})
export class SpeechAnalyticsModule { }
