
// This file can be replaced during build by using the  array.
//  replaces  with .
// The list of file replacements can be found in .
export const environment = {
    production: true,
    CIU_URL: 'https://apigateway.outsourcingcos.com/v1/ms/ciu/api/',
    BPMS_URL: '',
     RRHH_URL: '',
     CRM_URL: '',
     PQRS_URL: '',
     CARTERA_URL: '',
     NOMINA_URL: '',
     REPORTS_URL: '',
     REPORTSS_URL: '',
     MAIL_URL: '',
     PROVEEDOR_URL: '',
     COMPRAS_URL: '',
     CALIDAD_URL: '',
     CRM2_REPORTS_QUEUE:'',
     CHAT_URL: '',
     CRM_NSDP:'',
     CARTERA_ENTREAMIGOS_URL:'',
     CARTERA_PROPIA_TUYA_JUDICIALIZADA_2021:'',
     CARTERA_PROPIA_BANCO_POPULAR_2020:'',
     CARTERA_PROPIA_BANCO_POPULAR_2021:'',
     CARTERA_PROPIA_TUYA_JUDICIALIZADA_2022:'',
     CARTERA_PROPIA_TUYA_COMERCIAL_2021:'',
     CARTERA_SERFINANZA_2023:'',
     CARTERA_SERFINANZA_BOGOTA:'',
     CARTERA_PROPIA_TUYA_COMERCIAL_JUNIO_2022:'',
     CARTERA_PROPIA_BANCO_FALABELLA_2023:'',
     CARTERA_DIRECTV_URL:'',
     CARTERA_MOVISTAR_PREMIUM:'',
     CARTERA_PROPIA_BANCO_FALABELLA_2024:'',
     CARTERA_MOVISTAR:'',
     SPEECH_URL:'',
     GESTIONADOR_CORREOS_PYTHON:'',
     NOTIFICATION_URL: '',
     AGENDAMIENTOS_URL: '',
     JURIDICA_URL: '',
     VICIDIAL_URL: '',
     SOCKET_LIVECHAT: '',
     LIVECHAT_URL: '',
      pusher_app: '6ece382117e7b0852298',
      COACHING_URL: '',
      MODULAR_URL: '',
      ENCUESTAS_INTERNAS:'',
      PQRS_SOUL:'',
      CARTERA_POPULAR_2023_URL:'',
      //'',
      // VOICEBOT_URL: '',
      pusher_cluster: 'us2',
      public_back: '',
      pusher_app_global: '72d7cf4e2090bde0d114',
      HEALTH_URL: '',
       CRM_AXA_COLPATRIA_URL: '',
       PUSHER_TRAFFIC_APP: '',
       PUSHER_CHAT_APP_KEY: '',
       MEETINGS_URL: '',
       OJT_URL: '',
       AUDITORIA_URL: '',
       CARTERA_WOM_URL: '',
       CRM_CIAL: '',
        COSCOIN_URL: '',
        ID_FORM_PQRS: '359',
        STANDARD_URL: '',
        CARTERA_AIRE_URL: '',
        ID_FORM_SERVICEQUESTION: '398',
        CARTERA_AAA_URL:'',
        CARGUES_MASIVOS_CRM:'',
         // VOICEBOT_URL: 'http://172.10.7.20:8001/',
         VOICEBOT_URL: '',
         GESTION_CORREOS:'',
         SABLE_URL: '',
         ENCUESTAS: '',
         CRM2_URL:'',
         RPA_INBOUND:'',
         RPA_OUTBOUND:'',
         GESTIONADOR_CORREOS:'',
         CARTERA_PROPIA_TUYA_COMERCIAL_AGOSTO_2022:'',
         CARTERA_NATURA_URL:'',
         CARTERA_FALABELLA_2020:'',
         CARTERA_FALABELLA_2021:'',
         CARTERA_WOM_EN_URL:'',
         RPA_AIRE_INBOUND:'',
         RPA_AIRE_OUTBOUND:'',
         MQTT_CONFIG:{
            SERVER:"127.0.0.1",
            PORT:9001,
            USERNAME:"admin",
            PASSWORD:"123"
          },
          CHAT_SOUL:'',
          JUPYTER_URL:'',
          ETB_GOOPASS:'https://demoetbia.mysoul.software/api/',
          ETB_VOICEBOT: 'https://demoetbvoicebot.mysoul.software/'
     };
     /* * For easier debugging in development mode,
         you can import the following file * to ignore zone related error stack frames such as ,
         . * * This import should be commented out in production mode because it will have a negative impact
         * on performance if an error is thrown.
         */ // import 'zone.js/dist/zone-error'; // Included with Angular CLI
