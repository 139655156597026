import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CampaignModalComponent } from '../campaign-modal/campaign-modal.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AdminServersService } from 'src/app/modules/speech-analytics/services/admin-servers.service';
import { CampaignService } from 'src/app/modules/speech-analytics/services/campaign.service';
import Swal from 'sweetalert2';
import { EditCampaignComponent } from '../edit-campaign/edit-campaign.component';

@Component({
  selector: 'app-config-campain',
  templateUrl: './config-campain.component.html',
  styleUrls: ['./config-campain.component.sass']
})
export class ConfigCampainComponent implements OnInit {
  
  filter: string = '';
  formFilters:FormGroup; 
  textInputFilter: string = '';
  configPaginator: any = { length: 0, pageSize: 5, pageIndex: 0, previousPageIndex: 0 };
  pageSizeOptions = [5, 10, 25, 50, 100];

  @ViewChild(MatPaginator) paginator: MatPaginator;


  //configuracion informacion a mostar
  configColumnsTable: any[] = [
    { key: 'actions', name: 'Acciones' },
    { key: 'name', name: 'Nombre' },
    { key: 'campaign_id', name: 'Campaña vicidial' },
    { key: 'bilingue', name: 'Bilingue' },
    { key: 'dead_time', name: 'Tiempo muerto' },
  ]; 

  //configuracion columnas tabla
  keysColumnsTable: string[] = [];
  displayedColumns: string[] = ['actions', 'name', 'campaign_id', 'bilingue', 'dead_time'];

//datasoure para manejo informacion en la tabla
  dataSource = new MatTableDataSource<any>();
  
//marsort para ordenar los datos cargados por la tabla
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.getCampaigns();
    this.keysColumnsTable = this.configColumnsTable.map((item: any) => item.key);
  }

  constructor(
    private dialog:MatDialog,
    private adminServersService: AdminServersService,
    private campaignService: CampaignService,
    private formBuilder: FormBuilder,
  ) { }


  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Modal para crear nueva campaña
  */
  createCampaing(): void {
    const dialogRef= this.dialog.open(CampaignModalComponent, {
        width: '50%',
        maxWidth: '80vw',
        maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-category',
      data: {
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCampaigns();  
    });
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Metodo para modal editar
  */

  editCampaign(id: number): void {
    const dialogRef = this.dialog.open(EditCampaignComponent, {
      width: '50%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-category',
      data: { id: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getCampaigns();  
    });
  }

  /**
   * Metodo que pagina la informacion de la tabla.
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @param $event Evento emitido por el paginador.
  */
    changePage($event: PageEvent): void {
      this.configPaginator = $event;
      this.getCampaigns();
    }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Metodo que filtra los datos de la tabla.
    * @param $event Evento emitido por el input.
  */

  onSearchClick(value: string): void {
    this.configPaginator.pageIndex= 1;
    this.paginator.firstPage();
    this.filter = value;
    this.getCampaigns(); 
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Metodo que trae todos los servidores registrados. 
  */
  getCampaigns(): void {
    this.campaignService.getCampaign(this.configPaginator, this.filter).subscribe(res => {
      this.dataSource.data = res.data.data;
      this.dataSource.sort = this.sort;
      this.configPaginator.length = res.data.total;
    });
  }

  /**
   * Metodo atualizar estado del togle
   * @author Yeison Sepulevda
   * @createdate 2024-02-15
  */

  toggleStateChanged(element: any): void {
    let confirmationMessage = '';
    if (!element.active) {
      confirmationMessage = '¿Estás seguro de deshabilitar la campaña?';
    } else {
      confirmationMessage = '¿Estás seguro de habilitar la campaña?';
    }
  
    Swal.fire({
      title: '¿Estás seguro?',
      text: confirmationMessage,
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.changeState(element);
      } else {
        element.active = !element.active;
      }
    });
  }
  
  /**
   * Metodo actualizar el estado de la campaña
   * @author Yeison Sepulevda
   * @createdate 2024-02-15
   * 
  */
  changeState(element: any): void {   
    this.campaignService.stateCampaign({ id: element.id })
      .subscribe(
        () => {
          let confirmationMessage = '';
          if (!element.active) {
            confirmationMessage = 'Se ha deshabilitado la campaña con éxito.';
          } else {
            confirmationMessage = 'Se ha habilitado la campaña con éxito.';
          }
          
          Swal.fire({
            title: '¡Excelente!',
            text: confirmationMessage,
            icon: 'success',
            timer: 3000, 
            showConfirmButton: false 
          });
        },
        (error) => {
            Swal.fire({
              title: 'No se puede realizar la petición',
              text: error.error.error,
              icon: 'error',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#2CABBC',
            }).then(() => {
              this.getCampaigns();
            });
        }
      );
  }



}
