import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class ViewService {
  constructor(private httpClient: HttpClient) { }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }


  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que retorna campañas.
    * @param id Id del servidor seleccionado.   
  */
  getCampaigns(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}get_campaign/`).pipe(
      catchError(this.handleError)
    );
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que retorna campañas.
    * @param id Id del servidor seleccionado.   
  */
  getGroups(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}get_group/`).pipe(
      catchError(this.handleError)
    );
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo para obtener registro por número de teléfono.
    * @param id Id del servidor seleccionado. 
    * @param filters numero de documento o nombre del asesor   
  */

  searchByUser(id: number, user: any): Observable<any> {

    const headers = new HttpHeaders().set('user', user);

    const options = {
      headers: headers
    };

    // Realizar la solicitud GET con los headers
    return this.httpClient.get<any>(`${SPEECH_URL}get_user`, options).pipe(
      catchError(this.handleError)
    );
  }
    
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo para obtener tipificaciones.
    * @param id Id del servidor seleccionado.
    * @param phoneNumber numero de telefono a buscar  
  */

  getTipifications(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}get_tipification/`).pipe(
      catchError(this.handleError)
    );
  }
  
  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo para obtener tipificaciones.
    * @param id Id del servidor seleccionado.
    * @param phoneNumber numero de telefono a buscar  
  */

  getNumberInfo(phoneNumber: string): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}/get_number/${phoneNumber}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo para obtener las skills.
    * @param id Id del servidor seleccionado.   
  */

  getSkills(id_typeCall): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}get_skills/${id_typeCall}`).pipe(
      catchError(this.handleError)
    );
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo tipo de llamada
    * @param id Id del servidor seleccionado.   
  */

  getTypeCall(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}get_typeCall`).pipe(
      catchError(this.handleError)
    );
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo para obtener los audios a reproducir
    * @param id Id del servidor seleccionado.   
  */

  reprodAudios(recording_id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}audio/${recording_id}`).pipe(
      catchError(this.handleError)
    );
  }


  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que crea o actualiza el servidor seleccionado. 
    * @param number id conexion
    * @param filters json data para filtros
  */

  searchAllData( paginator: any, filters: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}buscar?page=${paginator.pageIndex}&perPage=${paginator.pageSize}`, filters).pipe(
    catchError(this.handleError)
  );
}

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que crea o actualiza el servidor seleccionado. 
    * @param number id conexion
    * @param filters json data para filtros
  */

  searchFill(paginator: any, recordings: any, descargarAudios: boolean, campaign_id: any): Observable<any> {
    const body = { recording_ids: recordings, descargarAudios: descargarAudios, campaign_id: campaign_id  };
    return this.httpClient.post<any>(`${SPEECH_URL}buscarRecor?page=${paginator.pageIndex}&perPage=${paginator.pageSize}`, body).pipe(
    catchError(this.handleError)
  );
}

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo que genera la descarga de los audios. 
    * @param idServer id servidor servidor. 
    * @param recordings array con id de las grabaciones para descargar. 
  */

  downloadGrabaciones(recordings: any[]): Observable<any> {
    const body = { recording_ids: recordings };
    return this.httpClient.post<any>(`${SPEECH_URL}descargar-audios`,body).pipe(
      catchError(this.handleError)
    );
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Metodo para validar los estados de los analisis de la peticion inicial
    * @param idrecordin array con id de las grabaciones para descargar. 
  */
  
  estado(recordings: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}state`,recordings).pipe(
      catchError(this.handleError)
    );
  }


  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Asociar las grabaciones con los analisis en paginado. 
    * @param recordings id servidor grabacions. 

  */

  resultAnalytics(paginator: any,recordings: any[]): Observable<any> {
    const body = { recording_ids: recordings };
    return this.httpClient.post<any>(`${SPEECH_URL}requestPeticion?page=${paginator.pageIndex}&perPage=${paginator.pageSize}`,body).pipe(
      catchError(this.handleError)
    );
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Obtener los filtros y grabaciones e integracion de CRM2 y Vicidial
    * @param filters json de la informacion
  */
  getFilter(paginator: any, filters){
    return this.httpClient.post<any>(`${SPEECH_URL}searchRecordingsCrm?page=${paginator.pageIndex }&perPage=${paginator.pageSize}`,filters).pipe(
      catchError(this.handleError)
    );
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-14
    * Obtener los filtros y grabaciones e integracion de CRM2 y Vicidial
    * @param filters json de la informacion
  */
    getFilter2(paginator: any, filters){
      return this.httpClient.post<any>(`${SPEECH_URL}searchRecordingsCrm2?page=${paginator.pageIndex}&perPage=${paginator.pageSize}`,filters).pipe(
        catchError(this.handleError)
      );
    }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-04
    * Descargar reporte de CRM1 y CRM2 
    * @param filters json de la informacion
  */
  downloadReport(filters){
    return this.httpClient.post<any>(`${SPEECH_URL}downloadReport`,filters, {responseType: 'blob' as 'json'}).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que se encarga de obtener las campañas asociadas a carteras
   * @author Juan David Guerrer Vargas
   * @returns {Obseravble<any>} Observable<any>
   */
  getCampaignsCarteras():Observable<any>{
    return this.httpClient.get<any>(`${SPEECH_URL}speech/carteras/showcampaign`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que se encarga de obtener lis listados de carteras segun su campaña
   * @author Juan David Guerrer Vargas
   * @returns {Obseravble<any>} Observable<any>
   */
  getListCarteras(id_campaign):Observable<any>{
    return this.httpClient.get<any>(`${SPEECH_URL}speech/carteras/showlists/${id_campaign}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo   que se encarga de obtener el listado de grabaciones para carteras
   * @param filterSearch:any {any} filtrado { list_ids:[], phone:"", start_date:"",end_date:"", per_page:"", from_page:"" }
   * @author Juan David Guerrer Vargas
   * @returns {Obseravble<any>} Observable<any>
   */
  getRecordingsCarteras(filterSearch:any):Observable<any>{
    return this.httpClient.post<any>(`${SPEECH_URL}speech/carteras/paginateRecordingsCartera`, filterSearch).pipe(
      catchError(this.handleError)
    );
  }


}