import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {  FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { PageEvent } from '@angular/material/paginator';
import { RequestService } from 'src/app/modules/speech-analytics/services/request.service';
import { ViewService } from 'src/app/modules/speech-analytics/services/view.service';
import * as moment from 'moment';
import { SpeechToTextComponent } from '../../../speech-to-text/speech-to-text.component';
import { SelectionModel } from '@angular/cdk/collections';
import { AudioPlayerComponent } from '../../../audio-player/audio-player.component';
import { StrategyModalComponent } from '../../../strategy/strategy-modal/strategy-modal.component';
import { StrategyServersService } from 'src/app/modules/speech-analytics/services/strategy.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { CampaignService } from 'src/app/modules/speech-analytics/services/campaign.service';
import { AdherenciaModalComponent } from '../../../adherencia-modal/adherencia-modal.component';

@Component({
  selector: 'app-dirigido',
  templateUrl: './dirigido.component.html',
  styleUrls: ['./dirigido.component.sass']
})
export class DirigidoComponent implements OnInit {
  /**
   * Variables de paginacion
   */
  pageSizeOptions:number[]= [5, 10, 20, 50, 100]
  configPaginatorRecording:any={ pageSize : 5, length:0,  pageIndex:1 }
  configPaginatorStrategy:any={ pageSize : 5, length:0,  pageIndex:1 }
  configPaginator: any = { length: 0, pageSize: 5, pageIndex:1 };

  /**
   * Variable de filtros
   */
  formFilters:FormGroup;
  isFiltersAdvancedActive:boolean = false;

  /**
   * Selectores
   */
  campaigns_user: any[] = [];
  groups: any[] = [];
  skills: any[] = [];
  tipifications: any[] = [];
  type_call: any[] = [ {type_call:'Inbound'}, {type_call:'Outbound'} ];
  listStrategys:any[] = []

  /**
   * Columnas de tablas
   */  
  displayedColumns: string[] = ['checbox','recording_id', 'start_time', 'user', 'full_name', 'phone_number','user_group','time_difference'];
  displayedColumnsRecordings: string[] = ['checbox','recording_id', 'start_time', 'user', 'full_name','phone_number','user_group','time_difference','audio'];
  displayedColumnsStrategy: string[] = ['checbox','recording_id', 'start_time', 'user', 'full_name','phone_number','user_group','time_difference','audio','analisis'];

  /**
   * Cabeceras de tablas
   */
  
  tHeaders:any={
    checbox: "", recording_id:"ID Caller",
    start_time:'Fecha grabación', user: "No. documento", 
    full_name: 'Nombre agente', phone_number:'Teléfono', 
    user_group: 'Skill', time_difference: "Tiempo"
  }

  tHeadersRecordings:any={
    checbox: "", recording_id:"ID Caller",
    start_time:'Fecha grabación', user: "No. documento", 
    full_name: 'Nombre agente', phone_number:'Teléfono', 
    user_group: 'Skill', time_difference: "Tiempo",
    audio:'Audio', analisis:'Análisis', adherencia:'Adherencia'
  }


  /**
   * Variables de seleccion
   */
  selection = new SelectionModel<any>(true, []);
  selectionRecordings = new SelectionModel<any>(true, []);
  selectionStrategy = new SelectionModel<any>(true, []);


  /**
   * Variables de tabla 
   */
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<any>(); 
  dataSourceRecordings = new MatTableDataSource<any>(); 
  dataSourceStrategy = new MatTableDataSource<any>(); 

  //Variables de control con Array original de datos para tablas que requiren Paginacion estatica
  listrecordingsAnalising:any[] = []
  listRecordingsStrategys:any[] = []

  strategyControl:FormControl = new FormControl(null)

  rrhh_id_user: any;

  ngOnInit(): void {
    this.filterUserCampaign();
    this.initFormFilters();
  }

  constructor(
    private dialog:MatDialog,
    private requestService: RequestService,
    private viewservice: ViewService,
    private strategyService: StrategyServersService,
    private authService: AuthService,
    private campaignService: CampaignService
  ) { }


  /**
   * Método que se encarga de inicializar los controles apra los filtros
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
   */
  initFormFilters(): void {
    this.formFilters = new FormGroup({
      campaign_id: new FormControl(null),
      usergroup: new FormControl(null),
      type_call: new FormControl(null, Validators.required),
      skill_id: new FormControl(null),
      dateStart: new FormControl(null, Validators.required),
      dateEnd: new FormControl(null, Validators.required),
      status: new FormControl(null), 
      user: new FormControl(null), 
      phone_number: new FormControl(null),
    });
  }

  /**
   * Método que determinao si los checbox estan seleccionados o no en la tabal de grabaciones
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

    /**
   * Método que determinao si los checbox estan seleccionados o no en la tabal de analisis
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
   */
  isAllSelectedRecordings(){
    const numSelected = this.selectionRecordings.selected.length;
    const numRows = this.dataSourceRecordings.data.length;
    return numSelected === numRows;
    
  }

  /**
   * Método que determinao si los checbox estan seleccionados o no en la tabla de strategia
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
   */

  isAllSelectedStrategy(){
    const numSelected = this.selectionStrategy.selected.length;
    const numRows = this.dataSourceStrategy.data.length;
    return numSelected === numRows;
  }

  /**
   * MEtodo que se encarga de resetear los controles indicados
   * @param controls:string[] array de name_controls a resetear e inhbilitar o habilitar
   */
  resetFilters(controls:string[]):void{
    controls.forEach( (control_name:string) => {
        this.formFilters.get(control_name).reset();
    });
  }


  /**
   * Método para seleccionar todos los elementos en la tabla de grabaciones
   * @author Juan David Guerrero Vargas
   */
  selectAll(): void {
    this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * Método para seleccionar todos los elementos en la tabla de analsis
   * @author Juan David Guerrero Vargas
   */
  selectAllRecordings(): void {
    this.isAllSelectedRecordings()?
      this.selectionRecordings.clear() :
      this.dataSourceRecordings.data.forEach(row => {
        if (row.analisis!== 'ERROR') {
          this.selectionRecordings.select(row);
        }
      });
  }

  /**
   * Método para seleccionar todos los elementos en la página actual
   * @author Juan David Guerrero Vargas
   */
  selectAllStrategy(): void {
    this.isAllSelectedStrategy() ?
          this.selectionStrategy.clear() :
          this.dataSourceStrategy.data.forEach(row => this.selectionStrategy.select(row));
  }

  

  /**
   * Método que se encarga de retonar la cantidad de items seleccioandos en la tabald e grbaicones
   * @author Juan David Guerrero Vargas
   * @returns {number} Cantidad de items seleccionados
   */
  get getTotalItemSelected():number{
    if(this.selection && this.selection?.selected){
      return this.selection?.selected.length
    }
    return  0
  }

  /**
   * Método que se encarga de retonar la cantidad de items seleccioandos en la tabal de Analsis
   * @author Juan David Guerrero Vargas
   * @returns {number} Cantidad de items seleccionados
   */
  get getTotalItemSelectedRecordings():number{
    if(this.selectionRecordings && this.selectionRecordings?.selected){
      return this.selectionRecordings?.selected.length
    }
    return  0
  }

  /**
   * Metodo que se encarga de obtener la cantidad de items que ya fueron analizados
   * @author Juan David Guerrero Vargas
   * @returns {number} number cantidad de items que ya estan analizados
   */
  get getTotalItemsAnalized():number{
    let totalRecordingsAnalized = this.selectionRecordings?.selected.filter( ffll =>  ffll?.analisis !== undefined &&  (ffll?.analisis == 'COMPLETADO' ||  ffll?.analisis == 'ERROR') ).length
    if( totalRecordingsAnalized > 0 ){
      return totalRecordingsAnalized;
    }
    return 0
  }

  /**
   * Método que se encarga de retonar la cantidad de items seleccioandos
   * @author Juan David Guerrero Vargas
   * @returns {number} Cantidad de items seleccionados
   */
  get getTotalItemSelectedAnalytics():number{
    if(this.selectionStrategy && this.selectionStrategy?.selected){
      return this.selectionStrategy?.selected.length
    }
    return  0
  }

  /**
   * Metodo que se encarga de obtener el total de recordings que se seleccionaron pra aplciar la estrategia
   * @returns {number} number
   */
  get getTotalRecordingsForStrategy():number{
    if(this.selectionStrategy && this.selectionStrategy.selected.length > 0 ){
      return this.selectionStrategy.selected.length;
    }
    return 0
  }

  /**
   * Metdodo que se encarga de obtener la cantidad de registros en la tabla 
   * @author Juan David Guerrero Vargas
   */
  get getTotalDataSource():number{
    return this.dataSource.data.length
  }

  /**
   * Metodo que se encarga de determinar la cantidad de itms que ya han sido analisados
   */
  get getTotalcompletedAnalisis():number{
    return this.dataSourceRecordings.data.filter( ffll =>  ffll?.analisis !== undefined &&  ffll?.analisis == 'COMPLETADO' ).length
  }

  /**
   * Metodo que se encarga de identificar sis e peude o no relziar la consutla
   */
  get isAviableSearch():boolean{
    let type_call = this.formFilters.get('type_call').value
    let endDate = this.formFilters.get('dateEnd').value
    if( ( type_call !== null && type_call !== "" ) && ( endDate !== null && endDate !== "" ) ){
      return true
    }
    return false;
  }

  /**
   * Metodo que se encarga de valdiar si se debe mostrar o no el selctor de skills
   */
  get isAviableSkills():boolean{
    let type_call = this.formFilters.get("type_call").value
    if( (type_call !== null && type_call !== "" && type_call == "Inbound") ){
      return true
    }
    return false
  }

  /**
   * Metodo trae las campañas asociadas al usuario
   * @author Yeison sepulveda
   * @createdate    06/05/2023
   * @returns void {void} 
   */

  filterUserCampaign():void{
    const user = this.authService.getUser();
    this.rrhh_id_user = user.rrhh_id;

    this.campaignService.getUserAssociatedCampaigns(this.rrhh_id_user).subscribe(res => {
      this.campaigns_user = res.data;
    },
    (error)=>{
      this.campaigns_user = []
    });
  }

  /**
   * Metodo que se encarga de redirigir al metodo de aplicar estrategias
   * @param stepperReference:any {any} Referencia del steper para realizar la accion de continuar el paso siguiente
   */
  applyStrategy(stepperReference?:any):void{
    this.dataSourceStrategy.data = this.selectionRecordings.selected
    this.configPaginatorStrategy.length = this.selectionRecordings.selected.length
    this.listRecordingsStrategys = this.selectionRecordings.selected
    if(stepperReference !== undefined){
      stepperReference.next();
    }
  }

  /**
   * Metodo que se encarga de abirir el modal apra poder abrir el modal de creacion de estrategias
   * @author Juan David Guerrero Vargas
   */
  openModalCreateStrategy():void{
    const dialogRef = this.dialog.open(StrategyModalComponent, {
      width: '50%',
      maxHeight: '95vh',
      data: { modo: 'crear' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.applyStrategy();
    });
  }

  /**
   * Metodo que se encarga de obtenre el listado de estrategias
   */
  getStrategys():void{
    this.strategyService.listStrategies().subscribe(res => {
        this.listStrategys = res.data;
    }); 
  }

  /**
   * 
   */
  typeCallchage():void{
    if(this.formFilters.get("type_call").value !== null && this.formFilters.get("type_call").value !== "" ){
      this.getOtherSelectors();
    }
    this.resetFilters(['usergroup', 'skill_id', 'status']);
  }

  /**
   * Metodo que se encarg de obtener los selectrores por tipo de llamada
   * @author juan David Guerrero Vargas
   */
  getOtherSelectors():void{
    const typeCall = this.formFilters.get("type_call").value
    this.viewservice.getGroups().subscribe(res => {
      this.groups = res.data;
    },
    (error)=>{
      this.groups = []
    });

    this.viewservice.getSkills(typeCall).subscribe(res => {
      this.skills = res.data;
    },
    (error)=>{
      this.skills = []
    });

    this.viewservice.getTipifications().subscribe(res => {
      this.tipifications = res.data;
    },
    (error)=>{
      this.tipifications = []
    });

    this.getStrategys();
  }


  /**
   * Método para el consumo y busqueda por los selectores
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
  */
  search(): void {
    if (!this.validateRangeDates()) {
      this.deleteColumnAnalytics();
  
      const filters = {
        campaign_id: this.formFilters.get('campaign_id').value,
        type_call: this.formFilters.get('type_call').value,
        user_group: this.formFilters.get('usergroup').value,
        skill_id: this.formFilters.get('skill_id').value,
        start_time: this.formFilters.get('dateStart').value,
        endtime: this.formFilters.get('dateEnd').value,
        status: this.formFilters.get('status').value,
        user: this.formFilters.get('user').value,
        phone_number: this.formFilters.get('phone_number').value,
      };
      
      this.viewservice.searchAllData(this.configPaginator, filters).subscribe(
        (response) => {

          response.data.data.forEach((registro: any) => {
            const startTime = new Date(registro.start_time);
            const endTime = new Date(registro.end_time);
            registro.time_difference = this.convertTimeToHHMMSS(startTime, endTime);
          });
  
          this.dataSource.data = response.data.data;
          this.selection.clear();
          this.selection.deselect()
          this.selection = new SelectionModel<any>(true, []);
          this.dataSource.sort = this.sort;
          this.configPaginator.length = response.data.total;
        },
        (error) => {
          console.error('Error en la solicitud:', error);
        }
      );
    } else {
      Swal.fire({
        icon: 'warning',
        title: '¡Ten encuenta!',
        text: 'Debe estar en un rango de 7 días',
        confirmButtonText: 'Entendido',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
      });
    }
  }

  /**
   * Método para convertir la diferencia de tiempo entre dos fechas en formato HH:mm:ss.
   * @param startTime La fecha de inicio.
   * @param endTime La fecha de fin.
   * @returns La diferencia de tiempo formateada en HH:mm:ss.
   * @author Yeison Sepulveda
   * @createdate 2024-04-23
  */
  
  convertTimeToHHMMSS(startTime: Date, endTime: Date): string {

    const diferenciaMs = Math.abs(endTime.getTime() - startTime.getTime());
    const tiempoDiferencia = new Date(diferenciaMs);

    //extraer
    const horas = tiempoDiferencia.getUTCHours().toString().padStart(2, '0');
    const minutos = tiempoDiferencia.getUTCMinutes().toString().padStart(2, '0');
    const segundos = tiempoDiferencia.getUTCSeconds().toString().padStart(2, '0');

    return `${horas}:${minutos}:${segundos}`;
  }


  /**
   * Método para reiniciar tabla eliminando colimna de analisis
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
   */
  deleteColumnAnalytics(): void {
    const indexConfigAudio = this.displayedColumnsRecordings.findIndex(column => column === 'analisis');
    if (indexConfigAudio !== -1) {
      this.displayedColumnsRecordings.splice(indexConfigAudio, 1);
    }
  }

  /**
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
   * Método descargar audios seleccionados
  */
  descargarAudios(stepperReference:any): void {
      if (this.getTotalItemSelected <= 0) {
          Swal.fire({
              icon: 'warning',
              title: 'No hay registros seleccionados',
              text: 'Por favor, seleccione al menos un registro para descargar.',
              confirmButtonText: 'Entendido',
              confirmButtonColor: '#2CABBC',
          });
          return;
      }
    
    this.buscarConDescarga(true);
    stepperReference.next()
  }

  /**
   * @author Yeison Sepulveda
   * @update Juan David Guerrero Vargas
   * @createdate 2024-02-04
   * Método consumo consulta y descarga de los audios
  */
  buscarConDescarga(descargarAudios: boolean): void {   
    let campaign_id = this.formFilters.get("campaign_id").value; 
    this.viewservice.searchFill(this.configPaginatorRecording, this.selection.selected.map( record => record['recording_id'] ), descargarAudios, campaign_id).subscribe(
        (response) => {

          //calcular tiempo
          response.data.data.forEach((registro: any) => {
            const startTime = new Date(registro.start_time);
            const endTime = new Date(registro.end_time);
            registro.time_difference = this.convertTimeToHHMMSS(startTime, endTime);
          });

          this.selectionRecordings.clear();
          // Reemplazar los datos con la nueva data de descarga
          this.dataSourceRecordings.data = response.data.data;
          //Variable para paginacion estatica de grabaciones analizadas
          this.listrecordingsAnalising = this.dataSourceRecordings.data
          this.dataSourceRecordings.sort = this.sort;
          this.configPaginatorRecording.length = response.data.total;
        },
        (error) => {
            if (error.error && error.error.error === "No se realizó ninguna descarga ni registro debido a la existencia previa de registros.") {
                console.warn("Error específico omitido:", error);
            } else {
              this.dataSourceRecordings.data = [];
              this.configPaginatorRecording.length = 0
              console.error('Error en la solicitud:', error);
            }
        },
    );
  }


  /**
    * @author Yeison Sepulveda
    * @update Juan David Guerrero Vargas
    * @createdate 2024-02-15
    * Método para analizar
  */
  analytics(): void {
    if (this.getTotalItemSelectedRecordings === 0) {
      Swal.fire({
            icon: 'warning',
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para analizar.',
            confirmButtonText: 'Entendido',
            confirmButtonColor: '#2CABBC',
        });
        return;
    }
    Swal.fire({
      title: '¿Estás seguro?',
      text: `Se iniciará el análisis de ${this.getTotalItemSelectedRecordings} grabaciones.`,
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.requestService.newRequest(this.selectionRecordings.selected).subscribe(response => {
          
          this.dataSourceRecordings.data =  this.selectionRecordings.selected;
          this.dataSourceRecordings.sort =  this.sort;
          this.configPaginatorRecording.length = this.dataSourceRecordings.data.length 
          if(this.displayedColumnsRecordings.find( ffnn => ffnn == 'analisis' ) == undefined ){
            this.displayedColumnsRecordings.push('analisis')
          }
          this.obtenerEstadosRegistros();
        }, error => {
          console.error('Error al enviar la solicitud:', error);
        });
      }
    });
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-04
    * Método cambiar los estados de los filtros avanzados
  */

  changeStateFiltersAdvanced(event): void {
    this.evenPrevenDefault(event);
    if (this.isFiltersAdvancedActive) {
      let listFiltersClean = ["usergroup", "status", "user" ];
      listFiltersClean.forEach((nameControl: string) => {
        const control = this.formFilters.get(nameControl);
        if (control) {
          control.setValue(null);
        }
      });
      this.isFiltersAdvancedActive = false;
    } else {
      this.isFiltersAdvancedActive = true;
    }
  }

  
  /**
   * Metodo que limita las acciones de botones por defecto
   */
  evenPrevenDefault($event){
    $event.preventDefault();
  }

  
  /**
    * Método que valida el rango de fechas seleccionadas del formulario.
    * @returns Booleano con la validacion de las fechas.
  */
  validateRangeDates(): boolean {
    const { dateStart, dateEnd } = this.formFilters.value;
    const momentStart = moment(dateStart);
    const momentEnd = moment(dateEnd);
    const numberDays = momentEnd.diff(momentStart, 'days');
    return numberDays >= 7 ? true : false;
  }


  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Método que pagina la informacion de la tabla.
    * @param $event Evento emitido por el paginador.
  */
  changePage($event: PageEvent, tablePaginate:string): void {
    if( tablePaginate == 'recordings' ){
      this.configPaginator.pageSize  = $event.pageSize;
      this.configPaginator.pageIndex  = $event.pageIndex + 1;
      this.search();
    }else if (tablePaginate == 'analisis' && this.displayedColumnsRecordings.find(ffnn => ffnn == 'analisis') == undefined){
      this.configPaginatorRecording.pageSize  = $event.pageSize;
      this.configPaginatorRecording.pageIndex  = $event.pageIndex + 1;
      this.buscarConDescarga(false)
    }else if (tablePaginate == 'analisis' && this.displayedColumnsRecordings.find(ffnn => ffnn == 'analisis') !== undefined){
      this.configPaginatorRecording.pageSize  = $event.pageSize;
      this.configPaginatorRecording.pageIndex  = $event.pageIndex + 1;
      this.dataSourceRecordings.data = this.paginateStaticTable(this.listrecordingsAnalising, this.configPaginatorRecording )
    }else if (tablePaginate == 'strategy' && this.displayedColumnsRecordings.find(ffnn => ffnn == 'analisis') !== undefined){
      this.configPaginatorStrategy.pageSize  = $event.pageSize;
      this.configPaginatorStrategy.pageIndex  = $event.pageIndex + 1;
      this.dataSourceStrategy.data = this.paginateStaticTable(this.listRecordingsStrategys, this.configPaginatorStrategy )

    }
  }

  /**
   * Metodo que se encarg de realziar paginacion estatica apra tablas 
   * @author Juan David Guerrero Vargas
   * @returns {any[]} any[]
   */
  paginateStaticTable(dataTable:any[], configPaginator:any):any[]{
    let  startIndex = (configPaginator.pageIndex - 1 )  * configPaginator.pageSize
    return dataTable.slice( startIndex, startIndex +  configPaginator.pageSize  )
  }

  /**
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * Método para abrir el modal de analisis
  */
  openAnalyticsModal(recording_id: any): void {
    const dialogRef = this.dialog.open(SpeechToTextComponent, {
      width: '800px',
      data: { recordingId: recording_id } 
    });
  }

  /**
  * @author Yeison Sepulveda
  * @createdate 2024-02-15
  * Metodo para abrir el modal reproductor audio
  */
  openAudioModal(recording_id: any): void {
    const dialogRef = this.dialog.open(AudioPlayerComponent, {
      width: '700px',
      data: { recordingId: recording_id } 
    });
  }

  /**
   * Metodo que se encarga de ir validando si ya se encuentran descargados los analisis de las grabaciones
    * @author Yeison Sepulveda
    * @update Juan DAvid Guerrero  Vargas
    * @createdate 2024-03-07
    * Validar el estado de los análisi
  */
  async obtenerEstadosRegistros() {
    let registrosPendientes: any[] = this.dataSourceRecordings.data.filter(ffll => ffll?.analisis !== 'COMPLETADO' && ffll?.analisis !== 'ERROR');
    const selectedRecords = this.selectionRecordings.selected;
    this.selectionRecordings.clear();
  
    while (registrosPendientes.length > 0) {
        const response: any = await this.viewservice.estado(selectedRecords).toPromise();
  
        if (response.data) {
            response.data.forEach((item: any) => {
                const registros = this.dataSourceRecordings.data.filter(record => record.recording_id === item.recording_id);
                registros.forEach(registro => {
                    registro.analisis = item.status.toUpperCase(); 
                });
            });
        }
  
        this.dataSourceRecordings.data = this.dataSourceRecordings.data.map(record => {
            const registroModificado = registrosPendientes.find(registro => registro.recording_id === record.recording_id);
            return registroModificado ? registroModificado : record;
        });
  
        registrosPendientes = this.dataSourceRecordings.data.filter(ffll => ffll?.analisis !== 'COMPLETADO' && ffll?.analisis !== 'ERROR');
  
        await new Promise(resolve => setTimeout(resolve, 30000)); // Esperar 30 segundos antes de la siguiente iteración
    }
  }

  /**
   * Descargar reporte de busqueda excel
   * @author Yeison Sepulveda
   * @createdate 2024-04-04
   */

  downloadReport() {
    const filters = {
      recording_ids: this.dataSourceRecordings.data.filter( ffll =>   ffll?.analisis == 'COMPLETADO' ).map( record => record['recording_id'] )
    };
  
    this.viewservice.downloadReport(filters).subscribe(
      (response: any) => {
        this.fileTransition(response, 'reporte.xlsx');
        
      },
      (error) => {
        console.error('Error en la solicitud:', error);
      }
    );
  }

  /**
	 * Método para tratar los datos que retorna el servicio y descargar el archivo.
	 * @param data data respondida por el servicio
	 * @param fileName nombre con el que se va a descargar el archivo.
	 * @param file_type Tipo del archivo 
	 * @author Yeison Sepulveda
	 * @createdate 2021-07-27
	 * @updatedate 2022-12-13  
	*/
	fileTransition(data: any, fileName: string, file_type:string = ""): void {
		let blob = new Blob([data], { type: file_type});
		let downloadURL = (file_type) ? window.URL.createObjectURL(blob) : window.URL.createObjectURL(data);
		let link = document.createElement('a');
		link.href = downloadURL;
		link.download = fileName;
		link.click();
	}

  /**
   * Metodo que se encarga de enviar la informacion apra aplciar la estrategia seleccionada
   * @author Juan David Guerrero Vargas
   * @Update Yeison Sepulveda
   */
  applyStrategySelected(): void {
    if (this.getTotalRecordingsForStrategy <= 0) {
        Swal.fire({
            icon: 'warning',
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para aplicar la estrategia.',
            confirmButtonText: 'Entendido',
            confirmButtonColor: '#2CABBC',
        });
        return;
    }

    let strategyForms = {
        id_recordings: this.selectionStrategy.selected.map(recording => recording['recording_id']),
        id_strategy: this.strategyControl.value
    }

    this.strategyService.assignStrategys(strategyForms).subscribe(
        (assignResponse: any) => {
            console.log("Respuesta de asignación de estrategia:", assignResponse);

            this.strategyService.applyAdherencia(strategyForms.id_recordings).subscribe(
                (adherenciaResponse: any[]) => {
                    console.log("Respuesta de cálculo de adherencia:", adherenciaResponse);
                    const adherencia = adherenciaResponse.map(adherencia => adherencia.adherence_percentage + " / " + adherencia.not_adherence_percentage);

                    let currentData = this.dataSourceStrategy.data.slice();

                    //tualizar la adherencia en los datos actuales
                    
                    currentData.forEach((item, index) => {
                        item.adherencia = adherencia[index]; // Suponiendo que el orden de las adherencias coincide con el orden de los datos
                    });

                    this.dataSourceStrategy.data = currentData;
                    this.dataSourceStrategy.sort = this.sort;
                    this.configPaginatorStrategy.length = this.dataSourceStrategy.data.length

                    if (this.displayedColumnsStrategy.indexOf('adherencia') === -1) {
                        this.displayedColumnsStrategy.push('adherencia');
                    }
                },
                (adherenciaError: any) => {
                    console.error("Error al calcular la adherencia:", adherenciaError);
                }
            );
        },
        (assignError: any) => {
            console.error("Error al asignar la estrategia:", assignError);
        }
    );
  }

    /**
    * @author Yeison Sepulveda
    * @createdate 2024-04-30
    * Método para abrir el modal de resultados de adherencia
  */
  AdherenciaModal(recording_id: any): void {
    const dialogRef = this.dialog.open(AdherenciaModalComponent, {
      width: '800px',
      data: { recordingId: recording_id } 
    });
  }



  /**
	 * Método enviar mensaje swal de error de la grabación
	 * @author Yeison Sepulveda
	 * @createdate 2024-04-23
	 */

  errorAnalyticModal() {
    Swal.fire({
      icon: 'warning',
      title: '¡Aviso!',
      text: 'Si la transcripción no está disponible, por favor verifica el audio, podría carecer de voz o ser solo tonos. ¡Gracias por tu atención!',
      confirmButtonText: 'Entendido',
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
    });
  }
}