  /*
  * directiva Angular se encarga de gestionar el comportamiento de arrastrar y soltar (drag and drop) de archivos.
  * @author Yeison David Sepulveda Ospina
  * @createdate 05-05-2024
  * Método: DndDirective
*/
import {
  Directive,
  Output,
  Input,
  EventEmitter,
  HostBinding,
  HostListener
} from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  /*
  * Método para gestionar el evento 'dragover' cuando se está arrastrando un archivo sobre el elemento.
  * @author Yeison David Sepulveda Ospina
  * @createdate 05-05-2024
  * Método: onDragOver
*/
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  /*
  * Método para gestionar el evento 'dragleave' cuando se está saliendo del área de arrastre.
  * @author Yeison David Sepulveda Ospina
  * @createdate 05-05-2024
  * Método: onDragLeave
*/
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  /*
  * Método para gestionar el evento 'drop' cuando se suelta un archivo arrastrado sobre el elemento.
  * @author Yeison David Sepulveda Ospina
  * @createdate 05-05-2024
  * Método: onDrop
*/
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}