import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login/login.component';
import { MaterialModule } from '../shared/material/material.module';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotChangePasswordComponent } from './forgot-change-password/forgot-change-password.component';
import { DobbleAuthComponent } from './dobble-auth/dobble-auth.component';
import { ForgotUserComponent } from './forgot-user/forgot-user.component';
import { LandingPageComponent } from './landing-page/landing-page.component'

@NgModule({
  declarations: [LoginComponent, BlockCopyPasteDirective, ChangePasswordComponent, ForgotPasswordComponent, ForgotChangePasswordComponent, DobbleAuthComponent, ForgotUserComponent, LandingPageComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    LoginComponent,
    LandingPageComponent
  ]
})
export class AuthModule { }
