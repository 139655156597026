import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.sass']
})
export class AdminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
}


  
    /**
     * Se encarga de cargar la informaicvon en los enpoints de cada tab una vez se selecicones
     * @author Juan David Guerrero Vargas 
     * @createdate 15/08/2023 
     * @param indexTab:number  {number} indice de la tab desde la cual se cargara la informaicon
     * @param filters:filterReturned|filterMisPqrs|filterHistoric {filterReturned|filterMisPqrs|filterHistoric} Objeto con los filtros a implementar
     * @returns void {void} 
     */
    changeTabSelected(indexTab:number):void{
  }

}
